import {
  buildDeviceDetails,
  buildMarker,
  buildShipmentBase,
  buildshipmentDetail,
  buildStateArr,
  calcDeviceCharge,
  createBOLLink,
  getActivation,
  getShpmntDeviceType,
  isBetween,
  round
} from '@/store/helpers.js'
import {
  createTableEntryProvider,
  getMessagesProvider,
  getQueryProvider,
  getTableEntriesProvider,
  runScriptProvider,
  updateTableEntryProvider
  // logoutProvider
} from '../providers'
import Vue from 'vue'
import moment from 'moment-timezone'

/**
 * Get the default state shipment object
 * @returns
 */
const getDefaultState = () => {
  return {
    all: {},
    ids: {},
    whs: {},
    lastUpdate: {},
    lastCreate: {}
  }
}

// initial state
let state = getDefaultState()

let fullState = localStorage.getItem('intelyt')
if (fullState) {
  fullState = JSON.parse(fullState)
  state = fullState.shipments
}

const getters = {
  /**
  * Returns count of the shipments with the provided status
  * adding origin and destination pins.
  * @param {object} store
  * @returns {Number} - Count of shipments with selected status
  * @todo Make 60 day limit is system configuration parameter and use in
  * the shipment activation page
  */
  getCountByStatus: (state) => (status) => {
    if (status !== 'Saved') {
      return Object.values(state.all).filter(s => s.shippingStateStr === status).length
    }
    // returns the active shipment count, when the status === Saved
    return Object.values(state.all).filter(s => s.shippingStateStr === status && moment().diff(moment(s.createDate), 'days') < 60 && s.type !== 'Repo').length
  },
  /**
  * Returns an array of data points filtered by date range, optionally
  * adding origin and destination pins.
  * @param {String} id - id of shipment (guid)
  * @param {Object} range - range of dates to filter by
  * @returns {Object} - Array of time series data && ActiveMarkers object
  */
  getSingleShipmentMarkers: (state, getters, rootState, rootGetters) => (id, range, activeMarkers = {}) => {
    // console.log('--- getSingleShipmentMarkers ---')
    // Show origin and destination if either activeMarkers is not provided (default case)
    // or activeMarkers.origin/destination is true
    /* let showOrigin = activeMarkers.origin || false
    let showDestination = activeMarkers.destination || false */
    const showOrigin = Object.prototype.hasOwnProperty.call(activeMarkers, 'origin') ? activeMarkers.origin.active : false
    const showDestination = Object.prototype.hasOwnProperty.call(activeMarkers, 'destination') ? activeMarkers.destination.active : false
    const usertmzn = rootState.user.timezone ? rootState.user.timezone : moment.tz.guess()

    // let timeSeriesData = state.all[id].locData.map((d, i) => buildMarker(d, i))
    const locData = [...state.all[id].locData]
    const filteredLocations = locData.filter(loc => activeMarkers[loc.locationType] && activeMarkers[loc.locationType].active).filter(isBetween(range))
    let timeSeriesData = filteredLocations.map((d, i) => buildMarker(d, i, usertmzn))
    timeSeriesData = [...timeSeriesData.sort((a, b) => b.timestamp - a.timestamp)]
    // Get the ailable icon list for the shipment's location data
    const iconList = [...new Set(filteredLocations.map(loc => loc.locationType))]
    // Loop through icon list and enable the icon list in legend markers
    iconList.forEach((icn) => {
      // if (activeMarkers.hasOwnProperty(icn)) activeMarkers[icn].hide = false
      if (Object.prototype.hasOwnProperty.call(activeMarkers, icn)) activeMarkers[icn].hide = false
    })
    // if there is location data and the shipment is not decommissioned
    // set last point to current location
    // activeMarkers.currentLocation condition is added to
    // toggle the current location based on the activeMarkers value
    if (timeSeriesData.length >= 1 && !state.all[id].decommDate && activeMarkers.currentLocation && activeMarkers.currentLocation.active) {
      activeMarkers.currentLocation.hide = false
      timeSeriesData[0].icon = 'currentLocationIcon'
    }
    let timeSeriesLength = timeSeriesData.length
    // Add any alerts to the data IF the markers are to be shown
    if (activeMarkers.alerts && activeMarkers.alerts.active) {
      const alerts = rootGetters['alerts/getAlertsByGuid'](id, '2')
      if (alerts.length > 0) activeMarkers.alerts.hide = false
      for (const alert of alerts) {
        timeSeriesLength += 1
        const alertObj = {
          class: 'alert',
          datetime: alert.time,
          latitude: alert.latitude,
          longitude: alert.longitude,
          subject: alert.subject,
          timestamp: moment(alert.alertTime1, 'YYYY-MM-DD HH:mm:ssZ').format('x')
        }
        timeSeriesData.push(buildMarker(alertObj, timeSeriesLength, usertmzn))
      }
    }
    // Add origin if it is not filtered
    timeSeriesLength = timeSeriesLength + 2
    // timeSeriesData = timeSeriesData.filter(isBetween(range))
    if (showOrigin && typeof state.all[id].originCoords !== 'undefined') {
      activeMarkers.origin.hide = false
      const originObj = {
        class: 'origin',
        datetime: state.all[id].createDate,
        latitude: state.all[id].originCoords.lat,
        longitude: state.all[id].originCoords.lng,
        origin: state.all[id].origin,
        timestamp: moment(state.all[id].createDate, 'YYYY-MM-DD HH:mm:ssZ').format('x')
      }
      timeSeriesData.unshift(buildMarker(originObj, 0, usertmzn))
    }
    // Add destination if it is not filtered
    if (showDestination && typeof state.all[id].destinationCoords !== 'undefined') {
      activeMarkers.destination.hide = false
      const destinationObj = {
        class: 'destination',
        datetime: state.all[id].decommDate,
        destination: state.all[id].destination,
        latitude: state.all[id].destinationCoords.lat,
        longitude: state.all[id].destinationCoords.lng,
        timestamp: moment(state.all[id].decommDate, 'YYYY-MM-DD HH:mm:ssZ').format('x')
      }
      timeSeriesData.push(buildMarker(destinationObj, timeSeriesLength, usertmzn))
      timeSeriesLength++
    }
    // Add destination shown as current location if it is included
    if (activeMarkers.destAsCurrent && activeMarkers.destAsCurrent.active) {
      activeMarkers.destAsCurrent.hide = false
      const destinationObj = {
        class: 'currentLocation',
        datetime: state.all[id].lastUpdate,
        destination: state.all[id].destination,
        latitude: state.all[id].destinationCoords.lat,
        longitude: state.all[id].destinationCoords.lng,
        timestamp: moment(state.all[id].lastUpdate, 'YYYY-MM-DD HH:mm:ssZ').format('x')
      }
      timeSeriesData.push(buildMarker(destinationObj, timeSeriesLength, usertmzn))
    }
    return { activeMarkers, timeSeriesData }
  },
  getSingleShipmentPaths: (state) => (id, range, activeMarkers = {}, highlight = 0, showDirection = false) => {
    /**
     * Build the path elememt obj
     * @param {Object} obj - Object with position details
     * @returns {Object} - Object with lat and lng details
     */
    const buildPathElement = (obj) => {
      const path = {
        lat: Number.parseFloat(obj.latitude),
        lng: Number.parseFloat(obj.longitude)
      }
      return path
    }
    /* let showOrigin = activeMarkers.origin || false
    let showDestination = activeMarkers.destination || false */
    const showOrigin = Object.prototype.hasOwnProperty.call(activeMarkers, 'origin') ? activeMarkers.origin.active : false
    const showDestination = Object.prototype.hasOwnProperty.call(activeMarkers, 'destination') ? activeMarkers.destination.active : false
    // console.log('TS INPUT DATA PATH (id, range): ', id, range)
    let outputPath = []
    // Add filter back in to filter path elements....
    // let pathData = state.all[id].locData.filter(isBetween(range)).map((loc) => buildPathElement(loc))
    const locData = [...state.all[id].locData]
      .filter(loc => activeMarkers[loc.locationType] && activeMarkers[loc.locationType].active)
      .filter(isBetween(range))
    const pathData = locData.sort((a, b) => { return a.timestamp - b.timestamp }).map((loc) => buildPathElement(loc))
    // let pathData = state.all[id].locData.map((loc) => buildPathElement(loc))
    // console.log('locData', locData)
    const lineSymbol = {
      path: 'M 0,-1 0,1',
      strokeColor: '#FF0000',
      strokeOpacity: 1,
      scale: 2
    }
    const optionSolid = {
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 0.5,
      strokeWeight: 2,
      icons: []
    }
    const optionBold = {
      geodesic: true,
      strokeColor: 'green',
      strokeOpacity: 0.7,
      strokeWeight: 6,
      icons: []
    }
    const optionDashed = {
      geodesic: true,
      strokeOpacity: 0,
      icons: [{
        icon: lineSymbol,
        offset: '0',
        repeat: '15px'
      }]
    }
    // If direction is enabled in the configuration
    // Then add the direction to the path
    if (showDirection) {
      // Fix for the maps undefined console error in shipment detail page
      const path = window.google ? window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW : 1
      const directionIcon = { icon: {path}, offset: '100%', repeat: '200px' }
      optionSolid.icons.push(directionIcon)
      optionBold.icons.push(directionIcon)
      optionDashed.icons.push(directionIcon)
    }
    if (pathData.length > 0) {
      // At least on point, add in Origin
      if (showOrigin) {
        pathData.unshift(state.all[id].originCoords)
      }
      // pathData.unshift(state.all[id].originCoords)
      const shipState = Number(state.all[id].shippingState)
      if (shipState > 4) {
        // shipment has arrived at final location, show path to destination
        // pathData.push(state.all[id].destinationCoords)
        outputPath = [{path: pathData, options: optionSolid}]
      } else if (shipState === 4) {
        // shipment still in transit - on way back from round trip
        if (showOrigin) {
          const remainingPath = [
            pathData.slice(-1)[0],
            state.all[id].originCoords
          ]
          outputPath = [{path: pathData, options: optionSolid}, {path: remainingPath, options: optionDashed}]
        }
      } else {
        // shipment still in transit - on way out or one-way shipment
        if (showDestination) {
          const remainingPath = [
            pathData.slice(-1)[0],
            state.all[id].destinationCoords
          ]
          outputPath = [{path: pathData, options: optionSolid}, {path: remainingPath, options: optionDashed}]
        } else {
          outputPath = [{path: pathData, options: optionSolid}]
        }
      }
    } else {
      // No points yet - show path from origin to destination - but...
      // display only if the origin is shown.....
      if (showOrigin) {
        const originDestinationPath = []
        if (typeof state.all[id].originCoords !== 'undefined') {
          originDestinationPath.push(state.all[id].originCoords)
        }
        if (typeof state.all[id].destinationCoords !== 'undefined') {
          originDestinationPath.push(state.all[id].destinationCoords)
        }
        outputPath = [{path: originDestinationPath, options: optionDashed}]
      }
    }
    // Logic to highligh route if highlight value is !== 0
    // and in between two values of a segment
    if (highlight > 0) {
      const timestampInRange = isBetween(range)({timestamp: highlight})
      if (timestampInRange) {
        const pathArr = state.all[id].locData.filter(isBetween(range))
        let crossIndex = 0
        // let currTimestamp = pathArr[0].timestamp
        while (pathArr[crossIndex].timestamp < highlight) {
          crossIndex += 1
        }
        if (pathArr[crossIndex + 1]) {
          const highlightPath = [buildPathElement(pathArr[crossIndex]), buildPathElement(pathArr[crossIndex + 1])]
          outputPath.push({path: highlightPath, options: optionBold})
        } else {
          const highlightPath = [buildPathElement(pathArr[crossIndex - 1]), buildPathElement(pathArr[crossIndex])]
          outputPath.push({path: highlightPath, options: optionBold})
        }
      }
    }
    return outputPath
  },
  /**
  * Retrieves time series data filtered by date range
  * @returns {Array} - Array of time series data
  */
  getShipmentTimeSeries: (state) => (id, range, field) => {
    if (!state.all[id] || !state.all[id].tsData) return []
    const timeSeriesData = state.all[id].tsData.map(d => [d.timestamp, d[field]])
    return timeSeriesData.filter(isBetween(range)).sort((a, b) => { return a[0] - b[0] })
  }
}

// actions
const actions = {
  toggleChimeVisibility ({ commit }, shipment) {
    console.debug('Toggle: ', shipment)
    commit('TOGGLE_CHIME_VISIBILITY', shipment)
  },
  /**
   * Assigned/Updated the particular shipment data
   * @param {object} store - Vuex store object (commit, state, rootState)
   * @param {object} payloadObj - payload Object for shipment params which needs to update store value
   */
  assignShipmentData ({commit}, payloadObj) {
    commit('UPDATE_SHIPMENT', {
      id: payloadObj.guid,
      data: payloadObj.data
    })
  },
  /**
    * Create a single shipment
    *  - get commands using commandSet from shipment and getCommands function<br>
    *  - build objects for shipment creation<br>
    *  - create shipment using runScriptProvider<br>
    *  - add shipment to state after successful creation<br>
    * @param {object} shipment - Shipment defnition payload
    */
  createShipment ({ dispatch, rootState }, shipment) {
    // console.debug('Shipment', shipment)
    if (typeof shipment.props === 'object') shipment.properties = JSON.stringify(shipment.props)
    return new Promise((resolve, reject) => {
      const scriptName = shipment.configScript
      const receiver = shipment.device
      const crossRefInfo = shipment.shipmentId
      const paramObjList = {
        commands: [],
        cust_data2: shipment.customer,
        cust_data3: shipment.notes,
        cust_text: shipment.properties,
        notes: shipment.notes,
        // overdue_arrive: shipment.overdue_arrive,
        // overdue_depart: shipment.overdue_depart
        package_info: shipment.packageInfo,
        sublist: shipment.sublist,
        sublist_package_info: shipment.sublistPackageInfo
      }
      if (Object.prototype.hasOwnProperty.call(shipment, 'destination')) {
        paramObjList.destination = shipment.destination.name
        paramObjList.destination_lat = shipment.destination.latitude
        paramObjList.destination_lon = shipment.destination.longitude
      }
      if (Object.prototype.hasOwnProperty.call(shipment, 'origin')) {
        paramObjList.origin = shipment.origin.name
        paramObjList.origin_lat = shipment.origin.latitude
        paramObjList.origin_lon = shipment.origin.longitude
      }
      // console.log('Shipment xxx->: ', paramObjList)
      runScriptProvider(rootState, scriptName, receiver, crossRefInfo, paramObjList).then(response => {
        const guid = response.data.guid.toUpperCase()
        dispatch('updateSingleShipment', guid)
        // If the payload object contains the device and device type
        // Then remove the device from the available list for the device type
        if (shipment.device && shipment.deviceType) dispatch('devices/updateAvailableList', {deviceId: shipment.device, deviceType: shipment.deviceType, action: 'remove'})
        resolve({message: `Succesfully created new record: ${shipment.shipmentId}`, guid})
      }).catch(e => {
        console.error('RUN SCRIPT ERROR ', e)
        reject(e)
      })
    })
  },

  /**
  * Edit a single shipment
  * Performs update using {@link updateTableEntryProvider} provider.
  * to {@link APPLY_SHIPMENT_EDITS} for processing and addition to the state <br>
  * @param {object} store
  * @param {object} store.commit  - Vuex store commit object
  * @param {object} store.state  - Vuex store state object
  * @param {object} store.state  - Vuex store state object
  * @todo Decide the proper list of fields to add.
  * @todo Handle errors/ success better (message portal?)
  */
  editShipment ({ commit, state, rootState }, updatePayload) {
    const entryId = Number(updatePayload.entryId)
    const entryIdBOL = Number(updatePayload.entryIdBOL)
    const ackTime = moment.utc().format('YYYY-MM-DD HH:mm:ss')
    // build data payload object for table update
    const splFields = {
      customData: 'string',
      customData2: 'string',
      customData3: 'string',
      customText: 'string',
      stateInt1: 'integer'
      // lastUpdate: 'date_time',
      // aclScanned: 'integer'
    }
    /* build payload object with included splFields from updatePayload
     * parameter format:
     * <field>: {type: <string/integer/date_time/double>, value: <value>}
     */
    const getPayload = function (fields) {
      const payload = {lastUpdate: {type: 'date_time', value: ackTime}}
      for (const [key, val] of Object.entries(updatePayload)) {
        if (fields[key]) {
          payload[key] = {type: fields[key], value: val}
        }
      }
      return payload
    }

    const shipmentData = {
      id: entryId,
      data: getPayload(splFields)
    }

    const spiFields = {
      itemType: 'integer',
      itemId: 'string',
      itemText: 'string',
      guid: 'string'
    }

    const spiPayload = getPayload(spiFields)
    const spiData = {
      id: entryIdBOL,
      data: spiPayload
    }
    /* Build promise list
     *  - always add shippingPickList update
     *  - If there are pickItem entries, add pickItem to promise list
     */
    const editPickItem = updatePayload.itemType
    const promiseList = [updateTableEntryProvider(rootState, 'shippingPickList', shipmentData)]
    if (editPickItem) {
      const pickItemEntry = entryIdBOL > 0 ? updateTableEntryProvider(rootState, 'shippingPickItem', spiData) : createTableEntryProvider(rootState, 'shippingPickItem', spiPayload)
      promiseList.push(pickItemEntry)
    }

    return new Promise((resolve, reject) => {
      Promise.all(promiseList).then(responses => {
        // console.log('Edit shipment success', responses)
        if (editPickItem) {
          updatePayload.entryIdBOL = entryIdBOL > 0 ? entryIdBOL : responses[1].data.entryId
          updatePayload.itemLink = createBOLLink(updatePayload.itemId, updatePayload.itemText)
        }
        if (updatePayload.props) updatePayload = Object.assign(updatePayload, updatePayload.props)
        commit('APPLY_SHIPMENT_EDITS', updatePayload)
        const message = `Successfully updated:  ${state.all[updatePayload.guid].clientShipmentId}`
        resolve({message})
      }).catch(e => {
        reject(e)
      })
    })
  },

  /**
   * Retrieves and stores chime voltage over time.<br>
   * - If successful, updates state using {@link TBD}<br>
   * - If error, log to console & return w/o state update
   * @param {String} macId - MAC address of device to query
   * @param {String} guid - Shipment guid
   * @param {Array} chartList - Available chart list from the configuration
   * @todo Merge in with getTempHumid
   */
  fetchChimeData ({ commit, state, rootState }, [macId, guid, chartList, parent = '']) {
    /*
     * Populates shipment data with data for all chimes counter and battery level
     * as well as shock and tilt data (if chime has capability)
     *
     * First get battery and counter data from 30/1 messages
     */
    const createDate = moment.utc(state.all[guid].createDate).format() // , 'YYYY-MM-DD hh:mm:ss')
    let where = ` LENGTH(data) >20 AND sourceTime > '${createDate}'`
    if (state.all[guid].decommDate) {
      const decommDate = moment.utc(state.all[guid].decommDate).format()
      where += ` AND sourceTime < '${decommDate}'`
    }
    // console.log('WHERE CLAUSE: ', where)
    if (chartList.includes('acl')) {
      const chimePayload = {deviceId: macId, msgType: 1, pktType: 30, where}
      // console.log('chimePayload: ', chimePayload)
      // call get messages to get 30/1 message
      const chimeData = {}
      getMessagesProvider(rootState, chimePayload).then(response => {
        for (const value of Object.values(response.data)) {
          // console.log('Entry -> ', value)
          // Filter out values if there is no ACL so an empty object won't be added.
          if (value.TotACL === "0") continue
          // Add in temporary check for FF_FF.....Remove when iTag bug fixed.
          if (value.Chip1.substring(6) !== 'FF_FF') {
            const timestamp = moment.utc(value['Device Time (UTC)'], 'ddd MMM DD HH:mm:ss YYYY').format('x')
            chimeData[timestamp] = Object.assign({}, chimeData[timestamp])
            for (let i = 1; i < 9; i++) {
              if (value[`Chip${i}`]) {
                const mac = value[`Chip${i}`].substring(6)
                const batt = parseFloat(value[`Battery${i}`])
                const cnt = parseInt(value[`Timer${i}`])
                // console.log('FOUND CHIP: ', mac, Math.min(batt, cnt))
                // chimeData[timestamp][mac] = Math.min(batt, cnt)
                // chimeData[timestamp]['battery'] = batt
                chimeData[timestamp][mac] = `B:${batt}, C:${cnt}`
              }
            }
          }
          //       let pwr = response[i - 1]['Energy Deliv'] - response[i]['Energy Deliv']
          //       let dt = moment.utc(response[i - 1]['Device Time (UTC)'], 'ddd MMM D HH:mm:ss YYYY').format('x')
          // //       let dt = response[i - 1]['Device Time (UTC)']
          // //       let dateVal = new Date(dt).getTime()
          //       tsData.unshift({
          //         timestamp: parseInt(dt),
          //         power: round(pwr, 2)
          //       })
        }
        /*
        * Add the final chimeData object to state.....
        */
        commit('UPDATE_SHIPMENT', {
          id: guid,
          data: {chimeData}
        })
      }).catch(e => {
        console.error('ERROR in fetchChimeData', e)
      })
    }
    /**
     * @param {Object} response - response from the API
     * @param {String} type - Param type like, humidity, temperature, shock, tilt..
     * @returns {Object} chimeData - With timestamp and series data from chime records
     * getChimeData - Process the chime data for the given parameter type using the API response
     */
    const getChimeData = function (response, type) {
      const chimeData = {}
      const chimeCount = response.data[0] ? parseInt(response.data[0].TotACL) : 0
      // Create temporary array to hold shock and tilt counters, start each at 0
      const tiltCounts = new Array(chimeCount).fill(0)
      const shockCounts = new Array(chimeCount).fill(0)
      for (const value of Object.values(response.data).reverse()) {
        // Filter out values if there is no ACL so an empty object won't be added.
        if (value.TotACL === "0") continue
        const timestamp = moment.utc(value['Device Time (UTC)'], 'ddd MMM DD HH:mm:ss YYYY').format('x')
        chimeData[timestamp] = Object.assign({}, chimeData[timestamp])
        for (let i = 1; i < 9; i++) {
          const indx = Number(value.Index) + i - 1
          // Process the record only if the MAC ID is defined for this record
          if (value[`Chip${i}`]) {
            const mac = value[`Chip${i}`].substring(6)
            if (type === 'shockTilt') {
              /* If either counter increments, there is a new value so set value and
              * update the counter to the current value.  Otherwise it is 0 for this timeslice
              */
              const tilt = parseFloat(value[`TltCnt${i}`]) > tiltCounts[indx] ? parseFloat(value[`TltMag${i}`]) : 0
              const shock = parseFloat(value[`ShckCnt${i}`]) > shockCounts[indx] ? parseFloat(value[`ShckMag${i}`]) : 0
              tiltCounts[indx] = parseFloat(value[`TltCnt${i}`])
              shockCounts[indx] = parseFloat(value[`ShckCnt${i}`])
              chimeData[timestamp][mac] = `S:${shock}, T:${tilt}`
            } else {
              let temperature = parseFloat(value[`Temperature${i}`])
              temperature = rootState.user.temperatureUnit === 'Celsius' ? round((temperature - 32) * 5 / 9, 2) : temperature
              const humidity = round(parseFloat(value[`Humidity${i}`]), 2)
              chimeData[timestamp][mac] = `H:${humidity}, T:${temperature}`
            }
          }
        }
      }
      return chimeData
    }
    if (parent.length === 0 && (chartList.includes('shock') || chartList.includes('tilt'))) {
      /*
      * If chime is shock capable and shipment is shock enabled, add shock and
      * tilt data to the shipment.  This is avaiable in 30/2 messages
      */
      const shockChimePayload = {deviceId: macId, msgType: 2, pktType: 30, where}
      let chimeShockTilt = {}
      getMessagesProvider(rootState, shockChimePayload).then(response => {
        chimeShockTilt = getChimeData(response, 'shockTilt')
        commit('UPDATE_SHIPMENT', {
          id: guid,
          data: {chimeShockTilt}
        })
      }).catch(e => {
        console.error('ERROR in fetchChimeData', e)
      })
    }
    if (chartList.includes('temp') || chartList.includes('humid') || chartList.includes('humidLine') || chartList.includes('tempLine')) {
      /*
      * If the chime has temperature & humidity data, add these to the shipment.
      * This is available in the 30/3 message.
      */
      const tempHumidPayload = {deviceId: macId, msgType: 3, pktType: 30, where}
      getMessagesProvider(rootState, tempHumidPayload).then(response => {
        const chimeTempHumid = getChimeData(response, 'tempHumid')
        commit('UPDATE_SHIPMENT', {
          id: guid,
          data: {chimeTempHumid}
        })
      }).catch(e => {
        console.error('ERROR in fetchChimeData', e)
      })
    }
  },
  // Get the shock and tilt values for the chime from it's parent device using the query
  // And load it in the shipments chimeShockTilt object
  fetchChimeShockAndTiltData ({ commit, state, rootState }, [guid, parent = '']) {
    const chimeShockTilt = state.all[guid].chimeShockTilt ? {...state.all[guid].chimeShockTilt} : {}
    if (parent) {
      const queryPayload = {
        query: 'fetchShipmentShockTiltData',
        params: [parent]
      }
      getQueryProvider(rootState, queryPayload).then(response => {
        const shockTiltData = Object.values(response.data)
        if (shockTiltData.length > 0) {
          shockTiltData.forEach((stData) => {
            const timestamp = stData.timestamp
            const data = shockTiltData.timestamp ? shockTiltData.timestamp : {}
            const chimeData = {}
            chimeData[stData.mac] = stData.events
            chimeShockTilt[timestamp] = Object.assign(data, chimeData)
          })
          commit('UPDATE_SHIPMENT', {
            id: guid,
            data: {chimeShockTilt}
          })
        }
      }).catch(e => {
        console.error('getQueryProvider ERROR:', e)
      })
    }
  },
  fetchMessageTypeData ({ rootState }, payload) {
    // const where = ` AND status = 'A'`
    const chimePayload = {
      deviceId: payload.macId,
      msgType: payload.msgType,
      pktType: payload.pktType,
      where: payload.where || ''
    }
    return new Promise((resolve, reject) => {
      getMessagesProvider(rootState, chimePayload).then(response => {
        resolve(response.data)
      }).catch(e => {
        console.error('ERROR in fetchMessageTypeData', e)
        reject(e)
      })
    })
  },
  fetchShipmentEvents ({ dispatch, state, rootState }, [guid, startDate, endDate, markers]) {
    /* fetchShipmentEvents Action gets all shippingEventLog records for the
        current shipment and processes these records by doing the following:
         - if will return:
      - TBD
    */
    // console.log('call fetchShipmentEvents', guid)
    // const max = 100
    // Note: correction for MySQL UTC correction by adding 5 hours
    const lastMessage = moment.utc(state.all[guid].lastComms).format('X')
    const startTimestamp = startDate // moment.utc(startDate, 'YYYY-MM-DD').format('X')
    const endTimestamp = endDate // parseInt(moment.utc(endDate, 'YYYY-MM-DD').format('X')) + 86400

    const queryPayload2 = {
      query: 'shipmentTimeSeries',
      params: [guid, String(startTimestamp), String(endTimestamp), lastMessage,guid, String(startTimestamp), String(endTimestamp), lastMessage]
    }
    getQueryProvider(rootState, queryPayload2).then(response => {
      dispatch('processShipmentEvents', {guid, response, markers})
    })
  },
  /**
  * Gets Conveyance for a single shipment when that shipment is accessed by the user
  * @param {object} guid  - Guid of shipment which has alerts to add
  */
  fetchConveyanceList ({ commit, state, rootState }, [guid, entryId = 0]) {
    const where = (Number(entryId) > 0) ? `AND entryId = ${entryId}` : ''
    const payload = {
      tableName: 'shippingPickItem',
      where: `guid = "${guid}" ${where} AND enabled < 1 AND itemType in (110,111,112,113) `,
      sort: 'entryId DESC'
    }
    const dynamicData = {
      conveyances: state.all[guid].conveyances || []
    }
    /**
     * Map function to loop and format the data
     * @param {*} rowdata
     * @returns
     */
    const mapFn = function (rowdata) {
      const objItemText = rowdata[1].itemText ? JSON.parse(rowdata[1].itemText) : []
      return objItemText ? {
        companyId: rowdata[1].companyId,
        createDate: rowdata[1].createDate,
        enabled: rowdata[1].enabled,
        entryId: rowdata[1].entryId,
        guid: rowdata[1].guid,
        itemId: rowdata[1].itemId,
        itemText: rowdata[1].itemText,
        itemType: rowdata[1].itemType,
        lastUpdate: rowdata[1].lastUpdate,
        objItem: objItemText,
        mode: objItemText.number || objItemText.carrier,
        route: `${objItemText.origin} -> ${objItemText.destination}`,
        // route: `reload`,
        status: objItemText.status
      } : []
    }
    return new Promise((resolve, reject) => {
      getTableEntriesProvider(rootState, payload).then(response => {
        let conveyancesData = []
        let refreshConveyanceItem = []
        let refreshConveyanceIndex = 0
        if (entryId > 0) {
          Object.values(dynamicData.conveyances).forEach((obj, index) => {
            if (obj.entryId === entryId) {
              refreshConveyanceIndex = index
              refreshConveyanceItem = (Object.entries(response.data).map(mapFn)[0])
            }
          })
          /***
           * Add Refresh Index data
           * splice is used to replace the new data with specific location
          ***/
          if (refreshConveyanceItem) {
            conveyancesData = [...state.all[guid].conveyances]
            conveyancesData.splice(refreshConveyanceIndex, 1, refreshConveyanceItem)
          }
        } else {
          conveyancesData = Object.entries(response.data).map(mapFn)
        }
        commit('UPDATE_SHIPMENT', {
          id: guid,
          data: {
            conveyances: conveyancesData
          }
        })
        resolve(response)
      }).catch(e => {
        console.error('ERROR in fetchConveyanceList', e)
        reject(e)
      })
    })
  },
  /**
   * Gathers counts for all shipments based on config type.  The object returned has key that
   * depends on the config type and is set in the shipmentConfigTypes object.
   * This value will set the query used and thus the field used for the key.  The return value
   * however always has structure of key:'###,###,###' where ### are shipping status entry ids
   */
  loadIds ({ commit, rootState }, {cfgType = 0, loadWarehouseFilter = false} = {}) {
    // get company id
    const companyIdString = String(rootState.company.id)
    /* Get the maximum number of days to look back for shipments to be searched, counted & displayed */
    // const lookbackDays = rootState.company.shipmentLookbackDays
    const lookbackDays = rootState.configuration.shipmentConfigTypes[cfgType].lookback || rootState.configuration.siteOptions.shipmentLookbackDays
    const activeStates = rootState.configuration.shipmentConfigTypes[cfgType].activeStates || '2,3,4'
    /* Query name comes from the shipment type config using the passed config type.
     * See config.json + overrides
     */
    const payload = {
      query: rootState.configuration.shipmentConfigTypes[cfgType].idQuery,
      params: [companyIdString, String(lookbackDays), String(activeStates), String(cfgType)]
    }
    getQueryProvider(rootState, payload).then(response => {
      /* get data into form id:'string,list,of,ids' */
      const idObj = {}
      for (const val of Object.values(response.data)) {
        idObj[val.state] = val.idList
      }
      commit('SET_IDS', idObj)
    }).catch(e => {
      console.error('loadIds error: ', e)
    })
    if (!loadWarehouseFilter) return
    const WHpayload = {
      query: 'assetIdByDest',
      params: [companyIdString, String(lookbackDays), String(activeStates), String(cfgType)]
    }
    getQueryProvider(rootState, WHpayload).then(response => {
      /* get data into form id:'string,list,of,ids' */
      const idObj = {}
      for (const val of Object.values(response.data)) {
        idObj[val.state] = val.idList
      }
      commit('SET_WH_IDS', idObj)
    }).catch(e => {
      console.error('loadIds error: ', e)
    })
  },
  createConveyance ({ commit, state, rootState }, createPayload) {
    const ackTime = moment.utc().format('YYYY-MM-DD HH:mm:ss')
    /**
     * getPayload - Returns the payload object for the given fields
     * @param {*} fields
     * @returns
     */
    const getPayload = function (fields) {
      const payload = {lastUpdate: {type: 'date_time', value: ackTime}}
      for (const [key, val] of Object.entries(createPayload)) {
        if (fields[key]) {
          payload[key] = {type: fields[key], value: val}
        }
      }
      return payload
    }
    /**
     * Map function to loop and format the data
     * @param {*} rowdata
     * @returns
     */
    const mapFn = function (rowdata) {
      const objItemText = rowdata[1].itemText ? JSON.parse(rowdata[1].itemText) : []
      return objItemText ? {
        companyId: rowdata[1].companyId,
        createDate: rowdata[1].createDate,
        enabled: rowdata[1].enabled,
        entryId: rowdata[1].entryId,
        guid: rowdata[1].guid,
        itemId: rowdata[1].itemId,
        itemText: rowdata[1].itemText,
        itemType: rowdata[1].itemType,
        lastUpdate: rowdata[1].lastUpdate,
        objItem: objItemText,
        mode: objItemText.number || objItemText.carrier,
        route: `${objItemText.origin} -> ${objItemText.destination}`,
        status: objItemText.status
      } : []
    }

    const spiFields = {
      itemType: 'integer',
      itemId: 'string',
      itemText: 'string',
      guid: 'string',
      enabled: 'integer'
    }

    const spiPayload = getPayload(spiFields)

    return new Promise((resolve, reject) => {
      Promise.all([
        createTableEntryProvider(rootState, 'shippingPickItem', spiPayload)
      ]).then(responses => {
        // commit('APPLY_SHIPMENT_EDITS', updatePayload)
        const msg = 'Successfully created '
        if (responses[0].data.entryId) {
          const payload = {
            tableName: 'shippingPickItem',
            where: `entryId = "${responses[0].data.entryId}" AND enabled < 1 AND itemType in (110,111,112,113) `,
            sort: 'entryId DESC'
          }
          getTableEntriesProvider(rootState, payload).then(resp => {
            // Merge conveyances data with any from store
            const conveyances = []
            conveyances.unshift(Object.entries(resp.data).map(mapFn)[0])
            const conveyancesData = [...conveyances, ...state.all[createPayload.guid].conveyances]
            // apply all stored conveyances data
            commit('UPDATE_SHIPMENT', {
              id: createPayload.guid,
              data: {
                conveyances: conveyancesData
              }
            })
          }).catch(e => {
            console.error('Create Flight error', e)
          })
        }
        resolve(msg)
      }).catch(e => {
        console.error('Create Flight error', e)
        reject(e)
      })
    })
  },
  /**
   * @param {*} macId - macId of the selected chime feom the list in shipment detail page
   * @param {*} guid - guid of the shipment
   * lookupJobParent - To get the associated asset for the given macId and guid of the shipment.
   */
  lookupJobParent ({ rootState }, [macId, guid]) {
    const payload = {
      tableName: 'shippingPickList',
      where: `jobParentId = "${guid}" AND macId = "${macId}" AND enabled = 0 `,
      sort: 'entryId DESC'
    }
    return new Promise((resolve, reject) => {
      getTableEntriesProvider(rootState, payload).then(response => {
        resolve(response)
      }).catch(e => {
        console.error('lookupJobParent error', e)
        reject(e)
      })
    })
  },
  /**
  * Gets alerts for a single shipment when that shipment is accessed by the user
  * and updates state with the alert values.  Will update again each time the
  * shipment is accessed.<br>
  * Uses {@link setAlert} for processing and addition to the state <br>
  * @param {object} store
  * @param {object} store.commit  - Vuex store commit object
  * @param {object} store.state  - Vuex store state object
  * @param {object} payloadObj  - get a result from query or table
  * @param {Number} cfgType - Shipment type
  */
  fetchShipmentList ({ rootState }, [payloadObj, cfgType]) {
    /**
     * Map function to loop and format the data
     * @param {*} rowdata
     * @returns
     */
    const mapFn = function (x) {
      return { guid: x[1].guid, clientShipmentId: x[1].customData, origin: x[1].origin, dest: x[1].dest, macId: x[1].macId, shortMacId: x[1].macId.substring(12), createDate: x[1].createDate, transitMode: x[1].transitMode, configType: x[1].configType, devices: x[1].devices || '' }
    }
    let provider = []
    let payload = {}
    const maxLen = payloadObj.query.max || 50
    if (payloadObj.query.type === 'query') {
      let queryParams = [payloadObj.value.toString()]
      if (Array.isArray(payloadObj.query.queryParams) && payloadObj.query.queryParams.length > 0) {
        queryParams = [...queryParams, ...payloadObj.query.queryParams]
      }
      queryParams.push(maxLen.toString())
      payload = {
        query: payloadObj.query.queryName,
        params: queryParams
      }
      provider = getQueryProvider(rootState, payload)
      getQueryProvider(rootState, payload)
    } else {
      const where = payloadObj.query.where.replace('{searchValue}', payloadObj.value)
      payload = {
        tableName: 'shippingPickList',
        where: `${where} enabled < 1 AND configType = ${cfgType}`,
        sort: 'createDate DESC',
        fields: ['guid'],
        max: maxLen
      }
      provider = getTableEntriesProvider(rootState, payload)
    }
    return provider.then(response => {
      return Object.entries(response.data).map(mapFn)
      // console.log('Fetch Shipment List: ', test)
    }).catch(e => {
      console.error('ERROR in fetchShipmentList', e)
    })
  },
  /**
   * Get iTags and iChimes data based on shipment guid
   * @param {String} guid - shipment/asset guid
   * @returns {Promise} - return response or error
   */
  fetchDevicesByGuid ({rootState}, guid) {
    const queryPayload = {
      query: 'fetchDevicesByGuid',
      params: [guid]
    }
    return new Promise((resolve, reject) => {
      getQueryProvider(rootState, queryPayload).then(response => {
        resolve(response)
      }).catch(e => {
        console.error('getTagAndChimeByGuid error', e)
        reject(e)
      })
    })
  },
  processShipmentEvents ({commit, state, rootState}, payloadObj) {
    const { guid } = payloadObj
    const deviceType = getShpmntDeviceType(rootState, state.all[guid].configType)
    const locListData = []
    const thListData = []
    const markers = payloadObj.markers
    let latestMessageTime = moment.utc(state.all[guid].lastEventUpdate, 'ddd MMM D HH:mm:ss YYYY')
    // Validate the given lat and lng positions are valid
    const isValidLocation = function (lat, lng) {
      return Math.abs(lat) >= 0.000001 && Math.abs(lng) >= 0.000001
    }
    let lastLat = 0.0
    let lastLon = 0.0
    for (const evnt of Object.values(payloadObj.response.data)) {
      const row = evnt.tsData.split('|')
      const sourceTimeValue = moment.utc(Number(row[2])*1000)
      if (sourceTimeValue.isAfter(latestMessageTime)) {
        // console.log('TRUE')
        latestMessageTime = moment.utc(sourceTimeValue)
      }
      const timestamp = Number(row[2])*1000
      if (row[0] === 'l') {
        const latitude = Number(row[4])
        const longitude = Number(row[5])
        if (!isValidLocation(latitude, longitude) || !markers.includes(row[3])) continue
        const loc = {
          class: 'locdata',
          count: 1,
          datetime: sourceTimeValue,
          entryId: Number(row[1]),
          lastDatetime: false,
          timestamp,
          latitude,
          longitude,
          locationType: row[3],
          locationString: row[6],
          motion: row[7]
        }
        // locListData.push(loc)
        if ((latitude && longitude) && (lastLat !== latitude || lastLon !== longitude)) {
          locListData.unshift(loc)
          lastLat = latitude
          lastLon = longitude
        } else {
          locListData[0].count += 1
          locListData[0].lastDatetime = sourceTimeValue
          // continue
        }
      } else {
        const temp = row[3] ? Number(row[3]) : 0
        const humid = row[4] ? Number(row[4]) : 0
        const batt = row[5] ? Number(row[5]) : 0
        // The temperature unit value is calculated based on the user preference
        const temperature = rootState.user.temperatureUnit === 'Celsius' ? (temp - 32) * 5 / 9 : temp
        const charge = calcDeviceCharge(rootState, {voltage: batt, type: deviceType})
        const th = {
          timestamp,
          datetime: sourceTimeValue,
          entryId: Number(row[1]),
          temperature: round(temperature, 1),
          humidity: round(humid, 1),
          battery: round(batt, 2),
          charge
        }
        thListData.push(th)
      }
    }
    // Returns the unique data
    const getUniqueData = function (arr) {
      return [...new Map(arr.map((m) => [m.entryId, m])).values()]
    }
      // Merge TS data with any from store
    const tsDataUpdate = getUniqueData([...thListData, ...state.all[guid].tsData])
    const locDataUpdate = getUniqueData([...locListData, ...state.all[guid].locData])
    //const tiltShockDataUpdate = getUniqueData([...tiltShockData, ...state.all[guid].tiltShockData])
    // apply all stored TS data

    commit('UPDATE_SHIPMENT', {
      id: guid,
      data: {
        tsData: tsDataUpdate,
        locData: locDataUpdate,
        lastEventUpdate: latestMessageTime
      }
    })

  },
  /**
   * This method first retrieves data for a list of shipments which are newer than cutoff date (N months)
   * and have the supplied configType.  It then processes the list using helper functions
   * and adds each record to state - state value -> shipments.<br>
   * The cutoff date used is the state.configuration.lastRecordOffsets.shipments parameter.<br>
   * As part of the process it will reset the lastRecords value for shipments to the
   * greatest value encountered and store it in state.shipments.lastRecords.  This assignment is done
   * by index position based on the configType.  Thus there could be different update timetamps for
   * various shipment types if a user has permission to access them.<br>
   * Performs update using {@link SET_SHIPMENT} mutation.
   * for processing and addition to the state <br>
   * @param {object} store - Vuex store object (commit, state, rootState)
   */
  updateShipmentList ({ commit, state, rootState }, {bias = 0, cfgType = 0, ids = '', filterStates = '', useCreateDate = false} = {}) {
    // add the module in the initialized modules array in state to reset on logging out
    if (!rootState.initialized.includes('shipments')) {
      commit('setInitializedModule', 'shipments', { root: true })
    }
    /* Build payload and send query to get list of shipments since cutoff date */
    const resultsLimit = String(rootState.configuration.siteOptions.shipmentRecords)
    const offset = rootState.configuration.shipmentConfigTypes[cfgType].offset || {unit: 'months', quantity: 12}
    const statusLimit = String(rootState.configuration.siteOptions.shipmentStatusLimit)
    // console.log('Filter and gateways: ', cfgType, ids, useCreateDate, gateways)
    /* Set compare and lastCreate dates to default values */
    let compareDate = state.lastUpdate[cfgType] || moment().subtract(parseInt(offset.quantity), offset.unit)
    let lastCreateDate = '2999000000'
    /* If useCreateDate is set in arguments, this means this query will use createDate for looking back.
     *   this means that the compareDate value should be set to default.
     *   the createDate will be the value from state (if stored) or the default.
     * This will ensure the query compares using the lastCreateDate....
     */
    if (useCreateDate) {
      compareDate = moment().subtract(parseInt(offset.quantity), offset.unit)
      lastCreateDate = state.lastCreate[cfgType] || '2999000000'
    }
    // Shift compareDate back to capture any recently updated shipments
    const biasedCompareDate = moment(compareDate).subtract(bias, 'minutes')
    /* Query payload based on whether ids is provided.  Set default query to be shipmentList.
     * If ids provided override to use query 'shipmentListById'.
     * If user gets a filtered shipment list 'shipmentListFiltered' will be used
     * Parameters depend on the query utilized.
     */
    // console.debug('compare date: ', moment(compareDate).format('DD-MM-YY HH:mm'), moment(biasedCompareDate).format('DD-MM-YY HH:mm'), typeof bias)
    const queryPayload = {
      query: 'shipmentList',
      params: [moment(biasedCompareDate).format('X'), String(rootState.company.id), String(cfgType), statusLimit, lastCreateDate, resultsLimit]
    }
    if (rootState.user.permissions.includes('filterShipments')) {
      const gateways = `'${Object.keys(rootState.devices.gateways.all).join("','")}'`
      queryPayload.query = 'shipmentListFiltered'
      queryPayload.params = [moment(compareDate).format('X'), String(rootState.company.id), String(cfgType), gateways, statusLimit, lastCreateDate, resultsLimit]
    }
    if (filterStates && filterStates.length > 2) {
      /* Get the maximum number of days to look back for shipments to be used in alert counts */
      const lookbackDays = String(rootState.configuration.siteOptions.shipmentLookbackDays)
      const alertFilters = filterStates.split(',')
      const activeStates = rootState.configuration.shipmentConfigTypes[cfgType].activeStates || '2,3,4'
      let params = [String(rootState.company.id), lookbackDays, String(cfgType), activeStates]
      params = params.concat(alertFilters)
      queryPayload.query = 'shipmentsByActiveAlerts'
      queryPayload.params = params
    }
    if (ids && ids.length > 2) {
      queryPayload.query = 'shipmentListById'
      queryPayload.params = [String(rootState.company.id), ids]
    }
    // if (activation) {
    //   queryPayload.query = 'shipmentListByCreateDate'
    //   queryPayload.params = [moment(compareDate).format('X'), String(rootState.company.id), String(cfgType), filter, gateways, statusLimit, resultsLimit]
    // }
    // console.log('Query Payload: ', queryPayload)
    return new Promise((resolve, reject) => {
      // console.log('------ Shipment List Promise.... ', queryPayload, ids, useCreateDate, state.lastCreate)
      getQueryProvider(rootState, queryPayload).then(response => {
        // console.log('------ Shipment List Response: ', response)
        /* If null response - just return empty object */
        if (!response.data || !response) {
          resolve({})
        } else {
          /* Loop through results and process each one */
          for (const shpmnt of Object.values(response.data)) {
            const isNew = !Object.prototype.hasOwnProperty.call(state.all, shpmnt.guid)
            /* Get basic static shipment data from helper function */
            const shipmentData = buildShipmentBase(rootState, [shpmnt, isNew])
            /* Calculate alert values/icons and add to shipment object */
            const stateArr = buildStateArr(shpmnt.securityState, shpmnt.alertState, shpmnt.onTimeState)
            shipmentData.securityStateStr = stateArr
            const activation = getActivation(rootState, shpmnt)
            shipmentData.activation = activation
            shipmentData.activationAcl = '-'
            /* Use buildDeviceDetails to get dynamic data for tags & chimes where record is new.
             *   i.e. loaded the first time
             *   @todo - only run this logic for repo and for shipments that are saved.
             */
            if (isNew) {
              // console.log('is new....')
              const [ chimes, tags ] = buildDeviceDetails(rootState, shpmnt)
              shipmentData.chimes = chimes
              shipmentData.tags = tags
              shipmentData.tagRepoDisplay = Object.keys(tags).length.toString()
              shipmentData.chimeDisplay = Object.keys(chimes).length.toString()
            }
            /*
             * If this is a new shipment, add to state, otherwise update
             * SET_SHIPMENT will perform both operations, add is simply SET without initial shipment
             */
            // const existShpmntRec = state.all[shpmnt.guid] || {}
            commit('SET_SHIPMENT', shipmentData)
            // console.log('COMPARE1: ', compareDate, moment.utc(shpmnt.lastUpdate, 'ddd MMM D HH:mm:ss YYYY'))
            compareDate = moment.max(compareDate, moment.utc(shpmnt.lastUpdate, 'ddd MMM D HH:mm:ss YYYY'))
            // console.log('COMPARE1: ', shpmnt.guid, shpmnt.createTimestamp, lastCreateDate, parseInt(shpmnt.createTimestamp), parseInt(lastCreateDate))
            lastCreateDate = parseInt(shpmnt.createTimestamp) < parseInt(lastCreateDate) ? shpmnt.createTimestamp : lastCreateDate
            // console.log('COMPARE2: ', shpmnt.createTimestamp, lastCreateDate, useCreateDate)
          }
        }
        /* If  useCreateDate is true or the state value has never been set, set lastCreate in state */
        if (useCreateDate || !state.lastCreate[cfgType]) commit('RESET_LAST_CREATE_TIMESTAMP', [lastCreateDate, cfgType])
        /* If  useCreateDate is false, set state.compareDate to the largest value found. */
        if (!useCreateDate) commit('RESET_LAST_UPDATE_TIME', [compareDate, cfgType])
        resolve(response.data)
      }).catch(e => {
        console.error('updateShipmentList ERROR', e)
        reject(e)
      })
    })
  },
  /**
   * This method first retrieves data for a single shipment based on the guid.  It then processes
   * the shipment using helper functions and adds each record to state - state value -> shipments.all.guid<br>
   * Performs update using {@link SET_SHIPMENT} mutation.
   * for processing and addition to the state <br>
   * @param {object} store - Vuex store object (commit, state, rootState)
   * @param {string} guid - Globally unique identifier of the shipment.
   */
  updateSingleShipment ({ commit, state, rootState }, guid) {
    /* Build payload and send query to get all details for the specific shipment */
    const queryPayload = {
      query: 'shipmentDetail',
      params: [guid]
    }
    return new Promise((resolve, reject) => {
      getQueryProvider(rootState, queryPayload).then(response => {
        const shpmnt = response.data[0]
        const isNew = !Object.prototype.hasOwnProperty.call(state.all, shpmnt.guid)
        const shipmentData = buildShipmentBase(rootState, [shpmnt, isNew])
        /* Use buildDeviceDetails to get dynamic data for tags & chimes */
        const [ chimes, tags ] = buildDeviceDetails(rootState, shpmnt)
        shipmentData.chimes = chimes
        shipmentData.tags = tags
        const dynamicData = buildshipmentDetail(rootState, shpmnt)
        // note
        const fullShipment = Object.assign(shipmentData, dynamicData)
        // const existShpmntRec = state.all[shpmnt.guid] || {}
        commit('SET_SHIPMENT', fullShipment)
        // note
        resolve(fullShipment)
      }).catch(e => {
        console.error('ERROR in updateSingleShipment', e)
        reject(e)
      })
    })
  },
  /**
   * This method update the last stage command run time in the shippingPickList table for a entryId
   * @param {object} store - Vuex store object (commit, state, rootState)
   * @param {string} entryId - Entry Id of the record
   */
  updateLastStageCmdTime ({ rootState }, entryId) {
    const updateTime = moment.utc().format('YYYY-MM-DD HH:mm:ss')
    const splData = {
      id: entryId,
      data: {
        stateTime1: {
          type: 'date_time',
          value: updateTime
        },
        lastUpdate: {
          type: 'date_time',
          value: updateTime
        }
      }
    }
    return new Promise((resolve, reject) => {
      updateTableEntryProvider(rootState, 'shippingPickList', splData).then(response => {
        resolve({...response, updateTime})
      }).catch(e => {
        console.error('ERROR in updateLastStageCmdTime', e)
        reject(e)
      })
    })
  },
  /**
   * Fetch the Heatmaps data from Shipments
   * @param {payloadObj} Object - payload Object for shipmentCountByDay params
   */
  fetchShipmentCountByDay ({ rootState }, payloadObj) {
    const payload = {
      query: 'shipmentCountByDay',
      params: [payloadObj.companyId, payloadObj.stateDate, payloadObj.endDate]
    }
    return new Promise((resolve, reject) => {
      getQueryProvider(rootState, payload).then(response => {
        resolve(response)
      }).catch(e => {
        console.error('ERROR in fetchShipmentCountByDay', e)
        reject(e)
      })
    })
  }
}

const mutations = {
  /**
    * Adds a single shipment to state
    * @param {Object} shipment - Shipment object to be added.
    */
  ADD_SHIPMENT (state, shipment) {
    Vue.set(state.all, shipment.guid, Object.assign({}, shipment))
  },
  /**
  * Applies the edits of a single shipment to state
  */
  APPLY_SHIPMENT_EDITS (state, data) {
    // console.log('Update Shipment: ', data.guid)
    const fieldMap = {
      customData: 'clientShipmentId',
      customData2: 'customer',
      customData3: 'notes',
      itemId: 'billOfLadingType',
      itemText: 'billOfLadingValue',
      itemLink: 'billOfLadingLink',
      stateInt1: 'customInt',
      entryIdBOL: 'entryIdBOL'
    }
    for (const [key, val] of Object.entries(data)) {
      // if (fieldMap.hasOwnProperty(key)) {
      if (Object.prototype.hasOwnProperty.call(fieldMap, key)) {
        Vue.set(state.all[data.guid], fieldMap[key], val)
      } else {
        Vue.set(state.all[data.guid], key, val)
      }
    }
  },
  /**
    * Deletes a single shipment from state
    * @param {String} guid - GUID of shipment to be deleted.
    */
  DELETE_SHIPMENT (state, guid) {
    Vue.delete(state.all, guid)
  },
  /**
   * Sets ids field
   * @param {object} ids - shipment id object with key as shippingState and value is string of shipment state ids
   */
  SET_IDS (state, ids) {
    Vue.set(state, 'ids', ids)
  },
  /**
   * SET_WH_IDS - Set the warehouse filter id list
   * New table to filter the zone/warehouse
   * @param {*} state
   * @param {*} ids
   */
  SET_WH_IDS (state, ids) {
    Vue.set(state, 'whs', ids)
  },
  /**
   * Toggles state for flag that controls visibity of full chime list.
   * If not selected, send string with first Mac Id otherwise, send
   * list of chimes.
   * @param {Object} guid - GUID of affected shipment
   */
  TOGGLE_CHIME_VISIBILITY (state, shipment) {
    // console.log('Toggle Chime Vis....')
    const chms = state.all[shipment.guid].selected ? Object.keys(shipment.chimes).length.toString() : shipment.chimes
    const tags = state.all[shipment.guid].selected ? Object.keys(shipment.tags).length.toString() : shipment.tags
    Vue.set(state.all[shipment.guid], 'chimeDisplay', chms)
    Vue.set(state.all[shipment.guid], 'tagRepoDisplay', tags)
    Vue.set(state.all[shipment.guid], 'selected', !state.all[shipment.guid].selected)
  },
  TOGGLE_CHIME_COLLASPE (state, shipment) {
    const chms = Object.keys(shipment.chimes).length.toString()
    const tags = Object.keys(shipment.tags).length.toString()
    Vue.set(state.all[shipment.guid], 'chimeDisplay', chms)
    Vue.set(state.all[shipment.guid], 'tagRepoDisplay', tags)
    Vue.set(state.all[shipment.guid], 'selected', false)
  },
  TOGGLE_CHIME_EXPAND (state, shipment) {
    Vue.set(state.all[shipment.guid], 'chimeDisplay', shipment.chimes)
    Vue.set(state.all[shipment.guid], 'tagRepoDisplay', shipment.tags)
    Vue.set(state.all[shipment.guid], 'selected', true)
  },
  /**
   * Toggles state for flag that controls visibity of rebuild button.
   * @param {Object} guid - Shipment guid for affected shipment
   */
  TOGGLE_REBUILD (state, guid) {
    Vue.set(state.all[guid], 'allowRebuild', false)
    Vue.set(state.all[guid].activation, 'showRebuild', false)
  },
  /**
   * Toggles state for flag that controls visibity of shock & tilt button.
   * @param {Object} guid - Shipment guid for affected shipment
   */
  TOGGLE_SHOCK_TILT (state, guid) {
    Vue.set(state.all[guid].activation, 'showShockTilt', false)
    Vue.set(state.all[guid], 'shockEnabled', true)
    Vue.set(state.all[guid], 'tiltEnabled', true)
  },
  /**
  * Updates timestamp of lastCreate in state
  * @param {Object} updateTime - String object representing timestampe of last update.
  */
  RESET_LAST_CREATE_TIMESTAMP (state, [updateTime, type]) {
    if (state.lastCreate.constructor !== Object) {
      Vue.set(state, 'lastCreate', {[type]: updateTime})
    } else {
      Vue.set(state.lastCreate, type, updateTime)
    }
  },
  /**
   * Updates timestamp of lastUpdate in state
   * @param {Object} updateTime - MomentJS object representing timestampe of last update.
   */
  RESET_LAST_UPDATE_TIME (state, [updateTime, type]) {
    if (state.lastUpdate.constructor !== Object) {
      Vue.set(state, 'lastUpdate', {[type]: updateTime})
    } else {
      Vue.set(state.lastUpdate, type, updateTime)
    }
    // Vue.set(state.lastUpdate, type, moment.utc(shpLastUpdate))
  },
  /**
  * Sets valuse for a single shipment in state.  If passed in with no
  * @param {Object} shipment - Shipment object to be added.
  */
  SET_SHIPMENT (state, shipment) {
    // console.log('SET SHIPMENT - guid - new - OLD', guid, newShipment, existShpmnt)
    // Vue.set(state.all, guid, Object.assign(existShpmnt, newShipment))
    const upsert = state.all[shipment.guid] || {}
    Vue.set(state.all, shipment.guid, Object.assign(upsert, shipment))
  },
  /**
   * Updates state for existing shipment record on a prop by prop basis
   * @param {Object} payload - Object with id (guid) and data object to be applied
   * to the shipment identified by the guid.
   * @param {String} payload.id - String providing shipment id (guid)
   * @param {Object} payload.data - Data object with multiple entries, each a
   * a name value pair with the name being the shipment property name and value
   * the data to be applied to that prop
   */
  UPDATE_SHIPMENT (state, payload) {
    for (const key in payload.data) {
      // console.log({state: state.all[payload.id], key: key, data: payload.data[key]})
      if (Object.prototype.hasOwnProperty.call(payload.data, key)) Vue.set(state.all[payload.id], key, payload.data[key])
    }
  },
  /**
   * Resets object state to initial values set upon creation.
   */
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
