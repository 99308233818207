<template>
  <div>
    <level-bar>
      {{pagename}}
  </level-bar>
    <div class="columns">
      <div class="column">
        <intelyt-card>
        <template slot="header">Device Details</template>
          <template slot="controls">
            <span class="icon icon-padding" style="padding-right:5px;">
              <base-icon name="repair" @click="markInoperable()" v-tooltip="'Mark Tag as Lost/Inoperable'" class="icon-padding" v-if="isAdmin"/>
              <base-icon name="information" v-if="showOrientation" @click="showDeviceOrientation = !showDeviceOrientation" v-tooltip="'View Device Orientation'" class="icon-padding"/>
            </span>
          </template>
          <div>
            <intelyt-list :items="deviceDetailsList" numColumns=1>
            </intelyt-list>
            <svg-tag :macId="macId" :shkObj="{acceleration: acceleration, RMS: []}" v-show="showDeviceOrientation" ref="shockSvg"></svg-tag>
          </div>
        </intelyt-card>
        <intelyt-card v-if="deviceShipmentList.length > 0">
        <template slot="header">Device History</template>
          <div>
            <intelyt-list :items="deviceShipmentList" numColumns=1>
            </intelyt-list>
          </div>
        </intelyt-card>
        <intelyt-card maxHeight="250px" :scrolling="true">
          <template slot="header">Messages</template>
          <template slot="controls">
            <base-icon name="message_add" @click="openModal('createAlert')" v-tooltip="'Create Message'" v-if="showAlertMessage" />
          </template>
          <div class="tabs is-boxed">
            <ul class="noMarginleft">
              <li v-for="(tab, idx) in getDeviceMessages" :key="idx" :class="{'is-active': (activeTab === idx)}"><a @click="activateTab(idx)">{{tab.name}} <span :class="{ 'text-danger': tab.unAcknowledged }">({{tab.value.length}})</span> </a></li>
            </ul>
          </div>
          <div class="content">
            <div v-for="(tab, idx) in getDeviceMessages" :key="idx" :id="idx" class="content-tab" v-show="activeTab === idx">
              <div class="field-group">
                <div class="field">
                  <template v-for="msg in tab.value">
                    <intelyt-alert :alert=msg :hideId="true" :key="msg.entryId"></intelyt-alert>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </intelyt-card>
      </div>
      <div class="column">
        <base-slider v-on:sliderChange="updateRange" :intervals="sliderInterval" :end="sliderEnd" :units="sliderUnit"></base-slider>
        <intelyt-card v-show="showMap">
          <template slot="header">Latest Location</template>
          <intelyt-map
             :markers="markers"
             :pathList="getPathList"
             :mapWidth="mapWidthCalc"
             :zoomFixed="false"
             :zoom="zoom"
          ></intelyt-map>
          <intelyt-map-legend
            v-if="legendMarkers.length > 0"
            :markers="legendMarkers"
            :activeMarkers="activeMarkers"
          ></intelyt-map-legend>
        </intelyt-card>
      </div>
      <div class="column">
        <intelyt-card :style="chartWidthCalc" v-if="showBatteryChart || showTempHumid">
          <!-- <template slot="header">Data Trends [{{this.slider.value[0] + ' to ' + this.slider.value[1]}}]</template> -->
          <template slot="header">Data Trends</template>
          <base-chart
              v-if="showTempHumid"
              classname="chart-style"
              :showLoadingIcon="showLoading"
              :options="tempHumidChartOptions"
              :parentWidth="parentWidth">
          </base-chart>
          <base-chart
              v-if="showBatteryChart"
              classname="chart-style"
              :showLoadingIcon="showLoading"
              :options="batteryChartOptions"
              :parentWidth="parentWidth">
          </base-chart>
        </intelyt-card>
      </div>
     </div>
    <send-command-modal
      :messageObj="cmdModal.messageObj"
      :isActive="modalIsActive.sendCommand"
      :actionButtonText="'Confirm'"
      :commandArgs="{callbackFn: cmdModal.callback, params: cmdModal.params}"
      v-on:close="closeModal('sendCommand')"
    >
    </send-command-modal>
    <create-alert-modal
      :isActive="modalIsActive.createAlert"
      v-on:close="closeModal('createAlert')"
    >
    </create-alert-modal>
  </div>
</template>

<script>
import BaseSlider from '@/components/BaseSlider'
import CreateAlertModal from '@/modals/CreateAlertModal'
import IntelytMapLegend from '@/components/IntelytMapLegend'
import SvgTag from '@/components/svgTag'
import {mapState, mapActions, mapGetters} from 'vuex'
import {getMapWidth, buildTempAndHumidChartOptions, getMessageObject, getDateFormat} from '@/store/helpers'
import {astShpmntDataFormat, capitalize, relativeDate, shortDate} from '@/store/formatters.js'
import {chartOptionFactory} from '@/store/factories'
import {modalWindowManager} from '@/store/mixins'
import moment from 'moment-timezone'
import {getMessagesProvider} from '@/store/providers'

export default {
  components: {
    BaseSlider,
    CreateAlertModal,
    IntelytMapLegend,
    SvgTag
  },
  computed: {
    batteryChartOptions: function () {
      const deviceType = this.$route.query.type || 'tags'
      if (!this.$store.state.devices[deviceType].all[this.$route.query.id] || !this.$store.state.devices[deviceType].all[this.$route.query.id].properties) {
        return {}
      }
      // Chart title implementation
      // let devType = deviceType
      const devType = capitalize(deviceType.substring(0, deviceType.length - 1))
      // Set variable items for chart
      let title = `${devType} Battery Level`
      let yAxis = [
        {name: 'Charge', formatter: 'percent', ymax: 1, ymin: 0, splitLine: {show: false}},
        {name: 'Voltage', formatter: '', ymax: 4.2, ymin: 0, splitLine: {show: true, lineStyle: {type: 'dotted'}}}
      ]
      let vMapPieces = ['charge']
      let batteryLevelData = this.getTimeSeries(this.$store.state.devices[deviceType].all, this.$route.query.id, false, 'charge')
      let batteryVoltData = this.getTimeSeries(this.$store.state.devices[deviceType].all, this.$route.query.id, false, 'battery')
      const vm = this
      const filterData = function (data) {
        return vm.range.length > 0 ? data.filter(dt => dt[0] >= vm.range[0] && dt[0] <= vm.range[1]) : data
      }
      let series = [
        {
          name: 'Charge',
          data: filterData(batteryLevelData)
        }, {
          name: 'Voltage',
          yAxisIndex: 1,
          data: filterData(batteryVoltData)
        }
      ]
      // console.log('series', series)
      const chartConfig = this.$store.state.configuration.charts
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const options = {vMapPieces, yAxis, colors: chartConfig.colors.lineChart, legend: {bottom: 5}}
      return chartOptionFactory(chartConfig, title, series, options, {}, tmz)
    },
    chartWidthCalc: function () {
      // return round(document.documentElement.clientWidth * 0.7, 0)
      let width = this.$parent.windowWidth * 0.95
      if (this.$parent.windowWidth > 981) {
        width = this.$parent.windowWidth * 0.3
      }
      return 'width: ' + width + 'px;'
    },
    deviceDetailsList: function () {
      const deviceType = this.$route.query.type || 'tags'
      const configs = this.$store.state.configuration.pageOptions.deviceDetail.details || []
      const tagData = {...this.$store.state.devices[deviceType].all[this.$route.query.id]}
      const temperatureUnit = this.$store.state.user.temperatureUnit === 'Celsius' ? ' \xB0C' : ' \xB0F'
      if (!tagData.temperature) tagData.temperature = 0
      tagData.temperature += temperatureUnit
      // console.log('tagData', tagData, configs)
      return astShpmntDataFormat(this.$store.state, configs, tagData)
    },
    deviceShipmentList: function () {
      const deviceType = this.$route.query.type || 'tags'
      if (!this.$store.state.devices[deviceType].all[this.$route.query.id]) return []
      const mapFn = (shp, idx) => {
        const shpData = {
          label: `${shp.customData2} [${shortDate(shp.createDate, `${getDateFormat()} HH:mm`)}] `,
          format: 'device-asset-link',
          colId: `link${idx}`,
          txtFrom: `text${idx}`,
          target: '_self'
        }
        return shpData
      }
      const shipmentData = this.$store.state.devices[deviceType].all[this.$route.query.id].shipments || []
      const configs = Object.values(shipmentData).map(mapFn)
      let tagData = {}
      const shpmntCfgTypes = this.$store.state.configuration.shipmentConfigTypes
      for (const [key, shp] of Object.entries(shipmentData)) {
        // Get the class value based on the config type of the shipment. If config type is asset, then link it to asset detail page, else link it to shipment detail page.
        const className = shpmntCfgTypes[shp.configType] ? shpmntCfgTypes[shp.configType].class : 'Shipment'
        const mode = className === 'Asset' ? 'asset' : 'shipment'
        tagData[`link${key}`] = `/detail?mode=${mode}&id=${shp.guid}`
        tagData[`text${key}`] = shp.shipmentId
      }
      return astShpmntDataFormat(this.$store.state, configs, tagData)
    },
    getDeviceMessages () {
      const vm = this
      // Get the alert data by event type
      const getAlertEvents = function (event) {
        const alerts = vm.$store.getters['alerts/getAlertsByGuid'](vm.$route.query.id, event)
        const haveUnAcknowledged = alerts.filter(alert => alert.ackBy.length === 0).length > 0
        return {data: alerts, unAcknowledged: haveUnAcknowledged}
      }
      let notifications = {}
      const msgData = getAlertEvents('1')
      notifications['message'] = {name: `Messages `, value: msgData.data, unAcknowledged: msgData.unAcknowledged}
      return notifications
    },
    getPathList: function () {
      const deviceType = this.$route.query.type || 'tags'
      let pathList = []
      if (!this.activeMarkers.GPS || !this.activeMarkers.GPS.active || !this.$store.state.devices[deviceType].all[this.$route.query.id] || !this.$store.state.devices[deviceType].all[this.$route.query.id].locData) return []
      let locationData = this.$store.state.devices[deviceType].all[this.$route.query.id].locData
      locationData = locationData.filter(loc => loc.locationType === 'GPS')
      if (this.range.length > 0) locationData = locationData.filter(loc => loc.timestamp >= this.range[0] && loc.timestamp <= this.range[1])
      let points = []
      for (let location of locationData) {
        if (!location.longitude || !location.latitude) {
          continue
        }
        const position = {
          lat: Number.parseFloat(location.latitude),
          lng: Number.parseFloat(location.longitude)
        }
        points.push(position)
      }
      const pointOption = {
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 0.5,
        strokeWeight: 5,
        icons: []
      }
      pathList.push({path: points, options: pointOption, editable: false})
      return pathList
    },
    humidity: function () {
      const deviceType = this.$route.query.type || 'tags'
      if (!this.$store.state.devices[deviceType].all[this.$route.query.id]) return 0
      return this.$store.state.devices[deviceType].all[this.$route.query.id].humidity || 0
    },
    isAdmin: function () {
      return this.$store.state.user.roles.includes('Company Administrator')
    },
    mapWidthCalc: function () {
      return getMapWidth(this.$parent.windowWidth, 0.35)
    },
    markers: function () {
      const deviceType = this.$route.query.type || 'tags'
      let output = []
      if (!this.$store.state.devices[deviceType].all[this.$route.query.id]) return output
      const vm = this
      // Get the maxLocAge date range value from the device detail config
      const maxLocAgeTime = moment.utc().subtract(this.devTypeCfg.maxLocAge, 'days').valueOf()
      const isValidTimeStamp = function (timestamp) {
        // convert the timestamp value
        timestamp = Number(moment(timestamp).format('x'))
        // condition 1: timestamp is greater than or equal to the maxLocAge date
        // from cofiguration
        // condition 2: timestamp is greater than or equal to the start date
        // value from slider
        // condition 3: timestamp is less than or equal to the end date
        // value from slider
        return timestamp >= maxLocAgeTime && timestamp >= vm.range[0] && timestamp <= vm.range[1]
      }
      const activeMarkers = {...this.activeMarkers}
      const thisTag = this.$store.state.devices[deviceType].all[this.$route.query.id]
      // If latestGPS available in activeMarkers and the latestGPS icon is active and it's timestamp value is in between the slider range
      if (thisTag.latestGPS && this.activeMarkers.lastGps && this.activeMarkers.lastGps.active && isValidTimeStamp(thisTag.latestGPS.timestamp)) {
        let gpsTimestamp = thisTag.latestGPS.timestamp
        let gps = {
          id: 1,
          icon: 'lastGps',
          position: thisTag.latestGPS.position,
          infoText: 'Last reported GPS location </br>Time: ' + relativeDate(gpsTimestamp),
          timestamp: gpsTimestamp
        }
        if (gpsTimestamp > 0) {
          activeMarkers.lastGps.hide = false
          output.push(gps)
        }
      }
      // If latestCell available in activeMarkers and the latestCell icon is active and it's timestamp value is in between the slider range
      if (thisTag.latestCell && this.activeMarkers.lastCell && this.activeMarkers.lastCell.active && isValidTimeStamp(thisTag.latestCell.timestamp)) {
        const cellLoc = thisTag.latestCell
        let infoText = 'Last reported Cell location </br>Time: ' + relativeDate(cellLoc.timestamp)
        infoText += '</br>Location: ' + cellLoc.location
        let cell = {
          id: 2,
          icon: 'lastCell',
          position: cellLoc.position,
          infoText: infoText,
          timestamp: cellLoc.timestamp
        }
        if (cellLoc.timestamp > 0) {
          activeMarkers.lastCell.hide = false
          output.push(cell)
        }
      }
      // If no cell record returned, use last stored value:
      // If latestCell available in activeMarkers and the latestCell icon is active and it's timestamp value is in between the slider range
      if (!thisTag.latestCell && this.activeMarkers.lastCell && this.activeMarkers.lastCell.active && thisTag.latitude !== 0 && isValidTimeStamp(thisTag.lastCellTime)) {
        // console.log('THIS TAG: ', thisTag)
        const cellLoc = {
          position: {
            lat: Number.parseFloat(thisTag.latitude),
            lng: Number.parseFloat(thisTag.longitude)
          },
          timestamp: thisTag.lastCellTime
        }
        let infoText = 'Last reported location </br>'
        let cell = {
          id: 2,
          icon: 'lastCell',
          position: cellLoc.position,
          infoText: infoText,
          timestamp: cellLoc.timestamp
        }
        if (cellLoc.timestamp > 0) {
          activeMarkers.lastCell.hide = false
          output.push(cell)
        }
      }
      // If GPS available in activeMarkers and the GPS icon is active and it's timestamp value is in between the slider range
      if (this.activeMarkers.GPS && this.activeMarkers.GPS.active && this.$store.state.devices[deviceType].all[this.$route.query.id].locData) {
        let id = 5
        let locData = this.$store.state.devices[deviceType].all[this.$route.query.id].locData
        // if (this.range.length > 0) locData = locData.filter(loc => loc.timestamp >= this.range[0] && loc.timestamp <= this.range[1])
        locData = locData.filter(loc => loc.position.lat && loc.position.lng && loc.timestamp >= this.range[0] && loc.timestamp <= this.range[1])
        for (const loc of locData) {
          let infoText = 'Time: ' + relativeDate(loc.timestamp)
          let locEntry = {
            id,
            icon: loc.locationType.toLowerCase(),
            position: {lat: parseFloat(loc.latitude), lng: parseFloat(loc.longitude)},
            infoText: infoText,
            timestamp: loc.timestamp
          }
          id += 1
          output.push(locEntry)
        }
        if (locData.length > 0) activeMarkers.GPS.hide = false
      }
      // Update the activeMarker values
      this.setActiveMarkers(activeMarkers)
      return output
    },
    parentWidth: function () {
      return this.$parent.windowWidth
    },
    showAlertMessage: function () {
      const isAdmin = this.$store.state.user.roles.includes('Company Administrator') || false
      const hasPermission = this.$store.state.user.permissions.includes('addDeviceMessages')
      return isAdmin || hasPermission
    },
    showBatteryChart: function () {
      return this.devTypeCfg.charts.includes('battery')
    },
    // Show map when atleast one legend marker is available to display markers in map
    showMap: function () {
      const markers = Object.values(this.activeMarkers).filter(mrk => !mrk.hide)
      return markers.length > 0
    },
    showOrientation: function () {
      const deviceType = this.$route.query.type || 'tags'
      const thisTag = this.$store.state.devices[deviceType].all[this.$route.query.id]
      if (!this.$store.state.devices[deviceType].all[this.$route.query.id] || !thisTag.properties) {
        return false
      }
      const lastMsgTime = thisTag.properties.lastDeviceMsgTime
      const diff = moment().diff(lastMsgTime, 'days')
      return diff < this.$store.state.configuration.siteOptions.deviceOrientationDays && (thisTag.type === 'DEVICE_ITAG' || thisTag.type === 'DEVICE_ICHIME') && this.acceleration.length > 0
    },
    showTempHumid: function () {
      // If the page config don't have tempHumid chart
      // or the device don't have tsData, then return false
      const deviceType = this.$route.query.type || 'tags'
      if (!this.devTypeCfg.charts.includes('tempHumid') || !this.$store.state.devices[deviceType].all[this.$route.query.id]) return false
      const thisTag = this.$store.state.devices[deviceType].all[this.$route.query.id]
      return thisTag.tsData && thisTag.tsData.length
    },
    sliderEnd: function () {
      const deviceType = this.$route.query.type || 'tags'
      if (!this.$store.state.devices[deviceType].all[this.$route.query.id] || !this.$store.state.devices[deviceType].all[this.$route.query.id].properties) {
        return moment.utc().add(1, 'hours').valueOf()
      }
      const thisTag = this.$store.state.devices[deviceType].all[this.$route.query.id]
      let lastMsgTime = thisTag.properties.lastDeviceMsgTime
      return moment(lastMsgTime).utc().valueOf()
    },
    /* sliderInterval: function () {
      const deviceType = this.$route.query.type || 'tags'
      if (!this.$store.state.devices[deviceType].all[this.$route.query.id]) {
        return [20, 10]
      }
      return [this.$store.state.configuration.pageOptions.deviceDetail.slider.range, 10]
    }, */
    temperature: function () {
      const deviceType = this.$route.query.type || 'tags'
      if (!this.$store.state.devices[deviceType].all[this.$route.query.id]) {
        return ''
      }
      const thisTag = this.$store.state.devices[deviceType].all[this.$route.query.id]
      return thisTag.temperature
    },
    version: function () {
      const deviceType = this.$route.query.type || 'tags'
      if (!this.$store.state.devices[deviceType].all[this.$route.query.id]) {
        return ''
      }
      const thisTag = this.$store.state.devices[deviceType].all[this.$route.query.id]
      return thisTag.version
    },
    center: function () {
      const deviceType = this.$route.query.type || 'tags'
      if (!this.$store.state.devices[deviceType].all[this.$route.query.id]) {
        return {
          lat: 0,
          lng: 0
        }
      }
      const thisTag = this.$store.state.devices[deviceType].all[this.$route.query.id]
      return {
        lat: Number.parseFloat(thisTag.latitude),
        lng: Number.parseFloat(thisTag.longitude)
      }
    },
    tempHumidChartOptions: function () {
      const deviceType = this.$route.query.type || 'tags'
      if (!this.$store.state.devices[deviceType].all[this.$route.query.id]) {
        return {}
      }
      const isC = this.$store.state.user.temperatureUnit === 'Celsius'
      const chartConfig = this.$store.state.configuration.charts
      const humidityData = this.humidity ? this.getTimeSeries(this.$store.state.devices[deviceType].all, this.$route.query.id, this.range, 'humidity') : []
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const options = {
        temp: this.getTimeSeries(this.$store.state.devices[deviceType].all, this.$route.query.id, this.range, 'temperature'),
        humid: humidityData,
        isC,
        chartConfig,
        tmz
      }
      return buildTempAndHumidChartOptions(options)
    },
    ...mapState({
      alerts: 'alerts',
      devices: 'devices'
    }),
    ...mapGetters([
      'getTimeSeries'
    ])
  },
  created () {
    const deviceType = this.$route.query.type || 'tags'
    this.fetchDeviceMessage()
    this.fetchDeviceStatus()
    // Fetch the alerts for the current device
    this.$store.dispatch('alerts/fetchAlerts', {guid: this.$route.query.id})
    this.$store.dispatch('devices/fetchDeviceShipments', {deviceId: this.$route.query.id, deviceType: deviceType})
    this.$store.dispatch('devices/initializeChargerAndGatewayLocations')
    const messages = this.$store.state.configuration.pageOptions.deviceDetail.messages || []
    this.$store.dispatch('devices/fetchDeviceTimeSeries', {deviceId: this.$route.query.id, addLocs: true, deviceType: this.$route.query.type, messages})
    // get the messages for the deviceDetail page
    this.$store.dispatch('getPageMessage', 'deviceDetail')
  },
  data () {
    // const thisTag = this.$store.state.tags.all[this.$route.query.id]
    const pageConfig = {...this.$store.state.configuration.pageOptions.deviceDetail}
    const deviceType = this.$route.query.type || 'tags'
    const devTypeCfg = this.$store.state.configuration.deviceDetail[deviceType] || {charts: []}
    const device = this.$store.state.devices.chargeGatewayLocations[this.$route.query.id] || {}
    // console.log('device', device)
    if (Object.keys(device).length > 0) {
      const validLoc = Math.abs(device.latitude) >= 0.000001 && Math.abs(device.longitude) >= 0.000001
      const devType = device.deviceType === '3' ? 'lastIGate' : 'lastCharger'
      if (validLoc && !pageConfig.map.markers.includes(devType)) {
        pageConfig.map.markers.push(devType)
      }
    }
    const activeMarkers = {}
    // Loop through the marker item and set the active and hide properties for activeMarkers
    const pageMarkers = pageConfig.map && pageConfig.map.markers ? pageConfig.map.markers : []
    pageMarkers.forEach((mrk) => {
      activeMarkers[mrk] = {active: true, hide: true}
    })
    const sliderInterval = [pageConfig.slider.range, pageConfig.slider.start] || [30, 3]
    return {
      acceleration: [],
      activeTab: 'message',
      activeMarkers,
      pagename: 'Device Details - ' + this.$route.query.id,
      cmdModal: {},
      deviceName: this.$route.query.id,
      devTypeCfg,
      legendMarkers: pageConfig.map.markers,
      macId: this.$route.query.id,
      modalIsActive: {
        createAlert: false,
        sendCommand: false
      },
      range: [moment.utc().valueOf(), moment.utc().valueOf()],
      // sliderEnd: moment.utc().add(1, pageConfig.slider.unit).valueOf(),
      sliderInterval,
      showLoading: false,
      colors: ['#d67777', '#4f99b4', '#82DFD6', '#0799b4', '#22D3D6'],
      showDeviceOrientation: false,
      // showTempHumid: pageConfig.charts.includes['tempHumid'],
      sliderUnit: pageConfig.slider.scale,
      zoom: 12,
      zoomFixed: true
    }
  },
  methods: {
    // Toggle the Tab on clicking the tab
    activateTab (tabId) {
      if (this.activeTab !== tabId) {
        this.activeTab = tabId
      }
    },
    fetchDeviceMessage () {
      let requestPayload = {
        // deviceId: '00_1B_AD_00_C2_B1_00_A2',
        deviceId: this.$route.query.id,
        pktType: 60,
        msgType: 5,
        max: 2
      }
      getMessagesProvider(this.$store.state, requestPayload).then(response => {
        const data = response.data
        const accel = []
        // if (Object.keys(data).length > 0 && data[0].hasOwnProperty('Accel_X')) {
        if (Object.keys(data).length > 0 && Object.prototype.hasOwnProperty.call(data[0], 'Accel_X')) {
          accel.push(data[0].Accel_X, data[0].Accel_Y, data[0].Accel_Z)
          this.acceleration = accel
        }
      })
    },
    fetchDeviceStatus () {
      const deviceType = this.$route.query.type || 'tags'
      if (!this.$store.state.devices[deviceType].all[this.$route.query.id]) {
        return
      }
      const device = this.$store.state.devices[deviceType].all[this.$route.query.id]
      if (device.type === 'DEVICE_ITAG') this.$store.dispatch('devices/fetchDeviceStatus', {deviceId: this.$route.query.id, deviceType})
    },
    getMarkers () {
      // if (this.range.length > 0) locData = locData.filter(loc => loc.timestamp >= this.range[0] && loc.timestamp <= this.range[1])
      const data = this.range.length > 0 ? this.markers.filter(loc => loc.position.lat && loc.position.lng && loc.timestamp >= this.range[0] && loc.timestamp <= this.range[1]) : []
      // console.log('this.markers', this.markers, this.range, data)
      return data
      // return this.markers
    },
    markInoperable () {
      let deviceType = this.$route.query.type || 'tags'
      const macId = this.macId
      // Capitalize the first letter
      deviceType = deviceType.charAt(0).toUpperCase() + deviceType.slice(1)
      // getMessageObject will return object/Boolean(false)
      const getMessageObj = getMessageObject(this.$store.state, 'deviceDetail', 'markInoperable', {deviceType, macId})
      this.cmdModal.messageObj = getMessageObj
      this.cmdModal.callback = this.runActivityCallback
      this.cmdModal.params = {
        template: this.$store.state.configuration.templateFiles.markInoperable
      }
      this.modalIsActive.sendCommand = true
    },
    runActivityCallback: function (store, args) {
      console.log('---ISSUE CALLBACK ----')
      const guid = this.guid || ''
      const activityPayload = {
        template: args.template,
        mac: this.macId,
        guid: guid,
        runByGUID: false
      }
      const promise = new Promise(function (resolve, reject) {
        store.dispatch('runActivity', activityPayload).then(() => {
          // const retVal = {'status': 200, 'message': args.success}
          resolve({})
        }).catch(e => {
          console.log('ERROR: ', e)
          reject(e)
        })
      })
      return promise
    },
    // Replace the activeMarkers data property with updated marker values
    setActiveMarkers: function (activeMarkers) {
      this.activeMarkers = activeMarkers
    },
    updateRange: function (val) {
      // console.log('Slider: ', val)
      this.range = val
    },
    ...mapActions([
      // 'fetchDeviceTimeSeries',
    ])
  },
  mixins: [modalWindowManager],
  mounted () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.chart-style {
  height: 300px;
  float: none;
  clear: both;
}

.slider-icon {
  border-left: 2px solid rgba(0, 0, 0, .5);
  border-bottom: 2px solid rgba(0, 0, 0, .5);
}

.btn-left {
  background: #FFF;
}
.btn-right {
  background: #FFF;
}

.icon {
  color: #03518F;
  opacity: .75;
}
.icon-padding {
  width: auto;
}

</style>
