<template>
  <div>
    <level-bar>
      {{pagename}}
    </level-bar>
    <!-- METRICS -->
    <div class="box" v-if="headerDetail.length > 0">
        <nav class="level">
          <template v-for="header in headerDetail">
            <div class="level-item has-text-centered" :key="header.name" v-if="header.show">
              <div>
                <p class="heading">{{header.name}}</p>
                <p class="title">{{header.val}}</p>
              </div>
            </div>
          </template>
        </nav>
    </div>
    <div class="columns">
      <div class="column">
        <intelyt-card>
          <template slot="header">Location Details</template>
          <div>
            <intelyt-list :items="locationDetailItems" numColumns=1>
            </intelyt-list>
          </div>
        </intelyt-card>
        <intelyt-card v-if="pageOptions.listComponents.includes('inventorySummary')">
          <template slot="header">Location Inventory Summary</template>
          <base-table
            :data="deviceTableData"
            :columns="deviceTableCols"
            :options="deviceTableOptions"
            :isNarrow="true"
          >
          </base-table>
        </intelyt-card>
        <intelyt-card v-if="pageOptions.listComponents.includes('inventoryDetail')">
          <template slot="header">Inventory Detail</template>
          <base-table
            :data="inventoryTableData"
            :columns="inventoryTableCols"
            :options="inventoryTableOptions"
          >
          </base-table>
        </intelyt-card>
      </div>
      <div class="column" v-if="pageOptions.charts.includes('locationMap')">
        <intelyt-card>
          <template slot="header">Location</template>
          <intelyt-map
             :waypoints="mapWaypoints"
             :markers="markers"
             :mapWidth="mapWidthCalc"
             :pathList="getPolylineList"
             :polygons="polygons"
             :zoom="zoom"
          ></intelyt-map>
          <intelyt-map-legend
            v-if="legendMarkers.length > 0"
            :markers="legendMarkers"
            :activeMarkers="activeMarkers"
          ></intelyt-map-legend>
        </intelyt-card>
      </div>
      <div class="column ">
        <intelyt-card v-if="routeData.length > 0">
           <template slot="header">Location Routes</template>
           <base-table
             :data="routeData"
             :columns="routeColumns"
             :options="listOptions"
           >
           </base-table>
        </intelyt-card>
        <intelyt-card v-if="waypointsList.length > 0">
           <template slot="header">Location Zones</template>
           <base-table
             :data="waypointsList"
             :columns="waypointColumns"
             :options="listOptions"
           >
           </base-table>
        </intelyt-card>
        <intelyt-card :style="chartWidthCalc" v-if="weatherId && pageOptions.charts.includes('tempHumid')">
          <template slot="header">Ambient Weather Conditions</template>
          <base-chart classname='chart-style'
                    :showLoadingIcon='showLoading'
                    :options='tempHumidChartOptions'
                    :parentWidth='parentWidth'>
          </base-chart>
        </intelyt-card>
      </div>
    </div>
    <inventory-detail-modal
      :message="inventoryStatusMessage.message"
      :data="inventoryStatusMessage.data"
      :title="inventoryStatusMessage.title"
      :isActive="modalIsActive.showInventoryDetail"
      :isHtml="inventoryStatusMessage.isHtml"
      v-on:close="closeModal('showInventoryDetail')"
    >
    </inventory-detail-modal>
    <show-message-modal
      :message="deviceStatusMessage.message"
      :title="deviceStatusMessage.title"
      :isHtml="true"
      :isActive="modalIsActive.showMessage"
      v-on:close="closeModal('showMessage')"
    >
    </show-message-modal>
    <edit-geofence-modal
      :mode="mode"
      :selected="selected"
      :geofence="geofence"
      :isActive="modalIsActive.editGeofence"
      v-on:close="closeModal('editGeofence')"
      ref="geofenceModal"
    >
    </edit-geofence-modal>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import {getMapWidth, generateIconSVG, isJSON, centroid, buildTempAndHumidChartOptions} from '@/store/helpers'
// import {chartOptionFactory} from '@/store/factories'
import {centerText, shortDateTime} from '@/store/formatters.js' // shortDate
import EditGeofenceModal from '@/modals/EditGeofenceModal'
import InventoryDetailModal from '@/modals/InventoryDetailModal'
import IntelytMapLegend from '@/components/IntelytMapLegend'
import {modalWindowManager} from '@/store/mixins'
import moment from 'moment-timezone'

export default {
  components: {
    EditGeofenceModal,
    IntelytMapLegend,
    InventoryDetailModal
  },
  computed: {
    chartWidthCalc: function () {
      // return round(document.documentElement.clientWidth * 0.7, 0)
      let width = this.$parent.windowWidth * 0.95
      if (this.$parent.windowWidth > 981) {
        width = this.$parent.windowWidth * 0.3
      }
      return 'width: ' + width + 'px;'
    },
    deviceTableData: function () {
      const loc = this.$store.state.locations.all[this.$route.query.id]
      const chargers = this.$store.getters['devices/chargers/getIdsByLocation'](loc.name)
      const gateways = this.$store.getters['devices/gateways/getIdsByLocation'](loc.name)
      const scanners = this.$store.getters['devices/scanners/getIdsByLocation'](loc.name)
      const chime = this.$store.getters['locations/chimeCount'](loc.name)
      const tag = this.$store.getters['locations/tagCount'](loc.name)
      const beacons = this.$store.getters['devices/beacons/getIdsByLocation'](loc.name)
      const deviceData = [
        {type: 'iTags', count: tag || 0, devType: '1'},
        {type: 'iChimes', count: chime || 0, devType: '2'},
        {type: 'iScans', count: scanners.length || 0, devType: '5'},
        {type: 'iChargers', count: chargers.length || 0, devType: '6'},
        {type: 'iGates', count: gateways.length || 0, devType: '3'},
        {type: 'iBeacons', count: beacons.length || 0, devType: '8'}
      ]
      const devTypeList = this.$store.state.devices.deviceTypes
      return deviceData.filter(dev => devTypeList.includes(dev.devType))
    },
    /* Geofences for this location are any which have the pattern LOC-##-%% where ## is the id for
     * the location and %% is an increment to ensure two names do not match.
     */
    geofenceList: function () {
      const locId = `LOC-${this.$route.query.id}`
      let fences = []
      for (let fence of Object.values(this.$store.state.geofences.all).filter(fnc => { return fnc.name.search(locId) >= 0 })) {
        fence.status = fence.enabled ? 'Enabled' : 'Disabled'
        fence.pointCount = fence.coordinates.length
        fences.push(fence)
      }
      return fences
    },
    inventoryTableData: function () {
      const loc = this.$store.state.locations.all[this.$route.query.id]
      const chgStatusCalc = ts => {
        /* Compare current time to timestamp and return proper format based on difference */
        const timeNow = moment.utc()
        const delay = timeNow.diff(ts, 'minutes')
        console.log('Compare Times: ', delay, timeNow, ts)
        return delay > 35 ? `<span class="tag is-danger">Offline</span>` : `<span class="tag is-success">Online</span>`
      }
      const gtwStatusCalc = ts => {
        /* Compare current time to timestamp and return proper format based on difference */
        const timeNow = moment.utc()
        const delay = timeNow.diff(ts, 'minutes')
        console.log('Compare Times: ', delay, timeNow, ts)
        return delay > 10 ? `<span class="tag is-danger">Offline</span>` : `<span class="tag is-success">Online</span>`
      }
      const counts = this.$store.state.devices.counts
      const chgList = this.$store.getters['devices/chargers/getIdsByLocation'](loc.name) || []
      const gtwList = this.$store.getters['devices/gateways/getIdsByLocation'](loc.name) || []
      const scannersList = this.$store.getters['devices/scanners/getIdsByLocation'](loc.name) || []
      // Initialize Totals
      let chimeTot = 0
      let tagTot = 0
      let inventoryList = []
      // Add charger rows to list
      for (const key in chgList) {
        const mac = chgList[key]
        const chimeCnt = counts[mac] ? counts[mac]['2'] : 0
        const tagCnt = counts[mac] ? counts[mac]['1'] : 0
        chimeTot += parseInt(chimeCnt)
        tagTot += parseInt(tagCnt)
        // lastTime uses browser timezone to convert lastDataTime to UTC timestamp to return to calc function
        // if lastDataTime = '' , use 1 day previous
        const lastDataTime = this.$store.state.devices.chargers.all[mac].lastDataTime
        const lastTime = lastDataTime ? moment.utc(lastDataTime, 'ddd MMM DD HH:mm:ss YYYY').tz(moment.tz.guess()) : moment.utc().subtract(1, 'day')
        const status = chgStatusCalc(lastTime)
        inventoryList.push({type: 'iCharge', mac: mac.substring(12), status: status, tags: tagCnt, chimes: chimeCnt})
      }
      //  Add iGate rows to list
      for (const key in gtwList) {
        const mac = gtwList[key]
        const chimeCnt = counts[mac] ? counts[mac]['2'] : 0
        const tagCnt = counts[mac] ? counts[mac]['1'] : 0
        chimeTot += parseInt(chimeCnt)
        tagTot += parseInt(tagCnt)
        // lastTime uses browser timezone to convert lastDataTime to UTC timestamp to return to calc function
        // if lastDataTime = '' , use 1 day previous
        const lastDataTime = this.$store.state.devices.gateways.all[mac].lastDataTime
        const lastTime = lastDataTime ? moment.utc(lastDataTime, 'ddd MMM DD HH:mm:ss YYYY').tz(moment.tz.guess()) : moment.utc().subtract(1, 'day')
        const status = gtwStatusCalc(lastTime)
        inventoryList.push({type: 'iGate', mac: mac.substring(12), status: status, tags: tagCnt, chimes: chimeCnt})
      }
      // Add iScan row to list
      if (scannersList.length > 0) {
        scannersList.forEach((scanner) => {
          inventoryList.push({type: 'iScan', mac: scanner.substring(12), status: '', chimes: '', tags: ''})
        })
      }
      // Add total row....
      inventoryList.push({type: 'Total', mac: '', status: '', chimes: chimeTot, tags: tagTot})
      return inventoryList
    },
    locationDetailItems () {
      const thisLoc = this.$store.state.locations.all[this.$route.query.id]
      const cityStateZip = `${thisLoc.locCity}, ${thisLoc.locState}  ${thisLoc.locZip}`
      const locDetails = [
        {label: 'Address', value: thisLoc.locAddress1},
        // {label: '', value: this.thisLoc.locAddress2},
        {label: '', value: cityStateZip},
        {label: 'Country', value: thisLoc.locCountry},
        {label: 'Region', value: thisLoc.locRegion},
        {label: 'Code', value: thisLoc.locCode},
        {label: 'Type', value: thisLoc.locType},
        {label: 'Coordinates', value: `${thisLoc.latitude}, ${thisLoc.longitude}`}
      ]
      return locDetails
    },
    // isAdmin: function () {
    //   console.log('--- Is Admiin ---')
    //   return this.$store.state.user.roles.includes('Company Administrator')
    // },
    mapWaypoints: function () {
      // The beacon option is not available in the activeMarkers list or icon is inactive then return empty []
      if (!this.activeMarkers.beacons || !this.activeMarkers.beacons.active) return []
      // mapWaypoints is a list all waypoints which have the locationId that is the same as
      // the current location and have a macId defined for the beacon property.
      const waypoints = Object.values(this.$store.state.waypoints.all).filter(wp => wp.location === parseInt(this.$route.query.id) && wp.beacon)
      const activeMarkers = {...this.activeMarkers}
      // If atleast one beacon found, then show the beacon legend in list
      if (waypoints.length > 0) activeMarkers.beacons.hide = false
      return waypoints
    },
    markers: function () {
      let markerList = []
      let locations = new Set()
      locations.add(this.$route.query.id)
      const allRoutes = this.$store.state.geofences.all
      const allLocations = this.$store.state.locations.all
      let indx = 1
      const activeMarkers = {...this.activeMarkers}
      this.selectedRoutes.forEach((key) => {
        allRoutes[key].associationList.forEach(id => {
          // If the zone not found in the list, then skip the record
          if (!allRoutes[id]) return
          const zone = allRoutes[id]
          const zoneConfig = zone ? this.$store.state.configuration.geozoneTypes[zone.mapConfigTypeId] : {hideMarker: true}
          // Filter empty/null coordinates
          const coords = zone.coordinates ? zone.coordinates.filter(coord => coord && coord !== null) : []
          // If the mapConfigTypeId has the hideMarker setting, the don't add it to marker list
          if (!zone || !coords || coords.length === 0 || zoneConfig.hideMarker || !this.activeMarkers.geofence || !this.activeMarkers.geofence.active) return
          const status = zone.enabled ? 'Active' : 'Deactivated'
          let center = centroid(coords)
          let marker = {
            id: indx,
            icon: 'geofence',
            name: zone.name,
            position: {
              lat: Number.parseFloat(center.lat),
              lng: Number.parseFloat(center.lng)
            },
            infoText: `Name: ${zone.name}</br>Status: ${status}`,
            timestamp: 0
          }
          markerList.push(marker)
          indx = indx + 1
        })
        let notes = isJSON(allRoutes[key].notes) ? JSON.parse(allRoutes[key].notes) : ''
        if (typeof notes === 'object') {
          if (notes.origins.length > 0) locations = new Set([ ...locations, ...notes.origins ])
          if (notes.destinations.length > 0) locations = new Set([ ...locations, ...notes.destinations ])
        }
      })
      // If atleast one geofence found, then show the geofence legend in list
      if (markerList.length > 0) activeMarkers.geofence.hide = false
      // If no location found, then return markerList
      if (locations.size === 0) {
        return []
      }
      for (let locKey of [...locations]) {
        // If locKey is empty string or locKey is not available in allLocations, then skip the reord
        if (locKey.length === 0 || !allLocations[locKey]) continue
        const loc = allLocations[locKey]
        const locType = loc.locType.toLowerCase() || 'location'
        // If the locType is not available in the activeMarkers or marker is not active then skip the record
        if (!this.activeMarkers[locType] || !this.activeMarkers[locType].active) continue
        const online = loc.enabled === '1' ? 'Online' : 'Offline'
        const marker = {
          id: indx,
          icon: locType,
          name: loc.name,
          position: {
            lat: Number.parseFloat(loc.latitude),
            lng: Number.parseFloat(loc.longitude)
          },
          infoText: `Name: ${loc.name}</br>Code: ${loc.locCode || '-'}</br>Type: ${loc.locType || 'Location'}</br>Status: ${online}`,
          timestamp: 0
        }
        indx += 1
        activeMarkers[locType].hide = false
        markerList.push(marker)
      }
      // Update the activeMarkers object
      // this.setActiveMarkers(activeMarkers)
      return markerList
    },
    mapWidthCalc: function () {
      return getMapWidth(this.$parent.windowWidth, 0.4)
    },
    parentWidth: function () {
      return this.$parent.windowWidth
    },
    /**
     * This method will return the polylines/paths availble for the selected routes
     */
    getPolylineList: function () {
      // If the geofence is not available in the activeMarkers or geofence marker is not active then return empty []
      if (!this.activeMarkers.geofence || !this.activeMarkers.geofence.active) return []
      const activeMarkers = {...this.activeMarkers}
      const pointOption = {
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 0.5,
        strokeWeight: 5,
        icons: []
      }
      let pathList = []
      const allZones = {...this.$store.state.geofences.all}
      /**
       * getAssociations - This method is used to get the nested associations for the given route
       * It's a recursive method to iterate the each associations and it's nested associations until
       * it founds no associations
       */
      const getAssociations = (id) => {
        const associations = allZones[id] ? [...allZones[id].associationList] : false
        if (associations) {
          for (const assoc of associations) {
            associations.push(...getAssociations(assoc))
          }
          return associations
        }
        return false
      }
      let nestedAssoc = []
      this.selectedRoutes.forEach((zone) => {
        nestedAssoc.push(zone)
        const getNestedAssoc = getAssociations(zone)
        if (getNestedAssoc.length > 0) {
          nestedAssoc.push(...getNestedAssoc)
        }
      })
      for (let mapId of nestedAssoc) {
        // If the zone not found in the list, then skip the record
        if (!allZones[parseInt(mapId)]) continue
        const zone = allZones[parseInt(mapId)]
        // Filter empty/null coordinates
        const coords = zone.coordinates ? zone.coordinates.filter(coord => coord && coord !== null) : []
        if (!zone || !coords || coords.length === 0 || parseInt(zone.mapConfigTypeId) !== 0) {
          continue
        }
        let points = []
        for (let node of coords) {
          const position = {
            lat: Number.parseFloat(node.lat),
            lng: Number.parseFloat(node.lng)
          }
          points.push(position)
        }
        pathList.push({path: points, options: pointOption, editable: false})
      }
      // If atleast one geofence found, then show the geofence legend in list
      if (pathList.length > 0) activeMarkers.geofence.hide = false
      // Update the activeMarker object
      // this.setActiveMarkers(activeMarkers)
      return pathList
    },
    polygons: function () {
      // If geofence is not available in the activeMarkers or geofence in activeMarkers is not enabled And
      // If layout is not available in the activeMarkers or layout in activeMarkers is not enabled
      // Then return empty list
      if (!this.activeMarkers.geofence && !this.activeMarkers.geofence.active && !this.activeMarkers.layout && !this.activeMarkers.layout.active) return {editable: false, paths: []}
      const allZones = {...this.$store.state.geofences.all}
      const activeMarkers = {...this.activeMarkers}
      let polygonList = []
      let nestedAssoc = []
      // If geofence is available in the activeMarkers and geofence in activeMarkers is enabled then get the list
      if (this.activeMarkers.geofence && this.activeMarkers.geofence.active) {
        /**
         * getAssociations - This method is used to get the nested associations for the given route
         * It's a recursive method to iterate the each associations and it's nested associations until
         * it founds no associations
         */
        const getAssociations = (id) => {
          const associations = allZones[id] ? [...allZones[id].associationList] : false
          if (associations) {
            for (const assoc of associations) {
              associations.push(...getAssociations(assoc))
            }
            return associations
          }
          return false
        }
        this.selectedRoutes.forEach((zone) => {
          nestedAssoc.push(zone)
          const getNestedAssoc = getAssociations(zone)
          if (getNestedAssoc.length > 0) {
            nestedAssoc.push(...getNestedAssoc)
          }
        })
        if (nestedAssoc.length > 0) activeMarkers.geofence.hide = false
      }
      // If the layout legend is available and  marker selected, add the location layout points
      if (this.activeMarkers.layout && this.activeMarkers.layout.active) {
        const thisLocation = this.$store.state.locations.all[this.$route.query.id]
        const notes = isJSON(thisLocation.notes) ? JSON.parse(thisLocation.notes) : {}
        const fenceId = notes && notes.locId ? notes.locId : false
        if (fenceId && allZones[parseInt(fenceId)]) {
          nestedAssoc.push(fenceId)
          activeMarkers.layout.hide = false
        }
      }
      for (let mapId of nestedAssoc) {
        // If the zone not found in the list, then skip the record
        if (!allZones[parseInt(mapId)]) continue
        const zone = allZones[parseInt(mapId)]
        // Filter empty/null coordinates
        const coords = zone.coordinates ? zone.coordinates.filter(coord => coord && coord !== null) : []
        if (!zone || !coords || coords.length === 0 || parseInt(zone.mapConfigTypeId) === 0) {
          continue
        }
        let polygon = []
        for (let node of coords) {
          const position = {
            lat: Number.parseFloat(node.lat),
            lng: Number.parseFloat(node.lng)
          }
          polygon.push(position)
        }
        polygonList.push(polygon)
      }
      return {editable: false, paths: polygonList}
    },
    routeData: function () {
      const locationId = parseInt(this.$route.query.id)
      const filterFn = z => { return z.notes.includes(`"${locationId}"`) }
      return Object.values(this.$store.state.geofences.all).filter(filterFn)
    },
    tempHumidChartOptions: function () {
      const isC = this.$store.state.user.temperatureUnit === 'Celsius'
      const chartConfig = this.$store.state.configuration.charts
      // const humidityExists = this.weatherId && this.$store.state.weather.all[this.weatherId] && this.$store.state.weather.all[this.weatherId][0] && this.$store.state.weather.all[this.weatherId][0].hasOwnProperty('humidity')
      const humidityExists = this.weatherId && this.$store.state.weather.all[this.weatherId] && this.$store.state.weather.all[this.weatherId][0] && Object.prototype.hasOwnProperty.call(this.$store.state.weather.all[this.weatherId][0], 'humidity')
      let humidtyData = humidityExists ? this.$store.getters['weather/ambientReadings'](this.weatherId, 'humidity') : []
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const options = {
        temp: this.$store.getters['weather/ambientReadings'](this.weatherId, 'temperature'),
        humid: humidtyData,
        isC,
        chartConfig,
        tmz
      }
      return buildTempAndHumidChartOptions(options)
    },
    waypointsList: function () {
      const waypoints = Object.values(this.$store.state.waypoints.all)
      const beacons = this.$store.state.devices.beacons.all
      const locationWaypoints = waypoints.filter(wp => wp.location === parseInt(this.$route.query.id) && wp.type === 'Beacon')
      const mappedWaypoints = []
      locationWaypoints.forEach((wp) => {
        // If beacon is found, add it to the mapped waypoints, otherwise ignore (ex. ignore iGates that aren't classified as beacons)
        if (beacons[wp.beacon]) {
          wp.lastBatteryLevel = wp.beacon && wp.beacon !== '' ? beacons[wp.beacon].lastBatteryLevel : '-'
          wp.lastMessageGateway = beacons[wp.beacon].lastMessageGateway
          mappedWaypoints.push(wp)
        }
      })
      return mappedWaypoints
    },
    ...mapGetters({
      // getLocationAmbientWeather: 'getLocationAmbientWeather'
    }),
    ...mapState({})
  },
  created () {
    const stationId = this.$store.state.locations.all[this.$route.query.id].weatherId || false
    if (stationId) {
      this.$store.dispatch('weather/populateAmbientWeather', [stationId, 168])
    }
    this.getRouteData()
    // this.selectedRoutes = Object.keys(this.routeData)
    // this.isCheckedAll = (this.selectedRoutes.length > 0)
  },
  data () {
    const pageOptions = this.$store.state.configuration.pageOptions.locationDetail
    console.log('pageOptions', pageOptions)
    const thisLocation = this.$store.state.locations.all[this.$route.query.id]
    const geofence = this.$store.state.geofences.all[thisLocation.geofenceId] || false
    // Icon for table to show  for router link
    const showInvDetIcon = function (row) {
      // Used to determine if 'Show Inventory Detail' icon should be visible
      return row.mac && row.type !== 'iScan'
    }
    const controlIcons = [
      {icon: 'list', callback: this.showInventory, tooltip: 'Show Inventory Detail', show: showInvDetIcon}
    ]
    const showEdit = () => {
      return this.isAdmin
    }
    const geofenceControlIcons = [
      {icon: 'edit', callback: this.openEditModal, tooltip: 'Edit', show: showEdit},
      {icon: 'trash', callback: this.openEditModal, tooltip: 'Delete', show: showEdit}
    ]
    const routeLink = function (row) {
      // return path and query string for router link
      return {path: 'geofences', query: { id: row.mapId }}
    }
    // Generate status icon with color and size
    const statusIcons = (status) => {
      const icon = {
        name: 'circle_fill',
        color: (status === 'Enabled') ? 'green' : 'red',
        size: 18
      }
      return `<div align="center">${generateIconSVG(this.$store.state, icon)}</div>`
    }
    // List of all columns available for the header
    const allheaderDetail = {
      name: {name: 'Location', val: thisLocation.name, show: true},
      locCode: {name: 'Location Code', val: thisLocation.locCode, show: thisLocation.locCode},
      locType: {name: 'Location Type', val: thisLocation.locType, show: thisLocation.locType},
      iTags: {name: 'iTags', val: this.allTags(), show: true},
      avgCommTime: {name: 'Average Seconds To Commission', val: thisLocation.avgSecToComm || '-', show: true},
      iChimes: {name: 'iChimes', val: this.allChimes(), show: true}
    }
    // List of all columns available for the device table
    const deviceTableCols = [
      {label: 'Device Type', id: 'type', colId: 'type'},
      {label: 'Count', id: 'count', colId: 'count'}
    ]
    // List of all columns available for the inventory detail table
    const inventoryDetail = {
      type: {label: 'Type', id: 'type', colId: 'type'},
      mac: {label: 'ID', id: 'mac', colId: 'mac'},
      status: {label: 'Status', id: 'status', colId: 'status'},
      tags: {label: 'iTag', id: 'tags', colId: 'tag', formatter: centerText},
      chimes: {label: 'iChime', id: 'chimes', colId: 'chime', formatter: centerText},
      macIcon: {label: '', id: 'mac', controls: controlIcons}
    }
    const reduceFn = (acc, cur) => {
      const curType = cur.locType.length > 0 ? cur.locType.toLowerCase() : 'location'
      if (!acc.includes(curType)) acc.push(curType)
      return acc
    }
    const legendMarkers = Object.values(this.$store.state.locations.all).reduce(reduceFn, [])
    legendMarkers.push('beacons')
    legendMarkers.push('layout')
    legendMarkers.push('geofence')
    /* Function to round battery level to 2 decimal places */
    const roundBatteryLevel = function (batt) {
      return Number.parseFloat(batt).toFixed(2)
    }
    const shortMacID = function (macID) {
      return macID.length > 0 ? macID.slice(-11) : '-'
    }
    const activeMarkers = {}
    // Loop through the marker item and set the active and hide properties for activeMarkers
    legendMarkers.forEach((mrk) => {
      activeMarkers[mrk] = {active: true, hide: true}
    })
    return {
      activeMarkers,
      pagename: 'Location Details - ' + thisLocation.name,
      headerDetail: pageOptions.header.map(col => allheaderDetail[col]),
      waypointColumns: [
        {label: 'Name', id: 'name'},
        {label: 'MAC ID', id: 'beacon', formatter: shortMacID},
        {label: 'Battery Level', id: 'lastBatteryLevel', formatter: roundBatteryLevel},
        {label: 'Last Update', id: 'lastMessageGateway', formatter: shortDateTime}
      ],
      code: thisLocation.locType,
      deviceStatusMessage: {
        message: 'Fetching Device Status...',
        title: 'Device Status'
      },
      name: thisLocation.name,
      geofence: geofence,
      geofenceListColumns: [
        {name: 'Name', id: 'name'},
        {name: 'Description', id: 'description'},
        {name: 'Status', id: 'status'},
        {name: 'Points', id: 'pointCount'},
        {name: '', id: 'name', controls: geofenceControlIcons}
      ],
      isAdmin: this.$store.state.user.roles.includes('Company Administrator'),
      key: thisLocation.key,
      legendMarkers: legendMarkers,
      locationId: thisLocation.id,
      locationKey: thisLocation.key,
      locationObj: thisLocation,
      weatherId: thisLocation.weatherId || false,
      // Modal Configuration - Text and activation
      inventoryStatusMessage: {
        message: 'Calculating Inventory...',
        title: 'Inventory',
        isHtml: true,
        data: []
      },
      modalIsActive: {
        showInventoryDetail: false,
        showMessage: false,
        editGeofence: false
      },
      deviceTableCols: deviceTableCols,
      deviceTableOptions: {
        paginate: false
      },
      // Inventory Table Configuration - Columns & Options
      inventoryTableCols: pageOptions.inventoryDetail.map(col => inventoryDetail[col]),
      inventoryTableOptions: {
        paginate: false
      },
      isCheckedAll: false,
      listOptions: {
        paginate: true,
        perPage: 10,
        sortable: false,
        searchable: false
      },
      // Map Configuration
      mode: '',
      options: {
        paginate: true,
        perPage: 5,
        sortable: true
      },
      pageOptions: pageOptions,
      routeColumns: [
        {label: '', id: 'mapId', colFormatter: this.checkAllHTML, formatter: this.controlIcons, colClick: this.selectAllRoutes, eventclick: this.selectRoute},
        {label: 'Name', id: 'name', link: routeLink},
        {label: 'Description', id: 'description'},
        {label: 'Enabled', id: 'status', formatter: statusIcons}
      ],
      // routeData: {},
      selectedRoutes: [],
      selected: '',
      showLoading: false,
      zoom: -1
    }
  },
  methods: {
    allChimes: function () {
      const loc = this.$store.state.locations.all[this.$route.query.id]
      return this.$store.getters['locations/chimeCount'](loc.name)
    },
    allTags: function () {
      const loc = this.$store.state.locations.all[this.$route.query.id]
      return this.$store.getters['locations/tagCount'](loc.name)
    },
    checkAllHTML: function () {
      const isChecked = (this.isCheckedAll === true) ? 'checked="checked"' : ''
      return `<input type="checkbox" ${isChecked}> All`
    },
    controlIcons (mapId) {
      const isChecked = (this.selectedRoutes.includes(mapId)) ? 'checked = "checked"' : ''
      return `<input type="checkbox" ${isChecked} value="${mapId}" />`
    },
    getRouteData: function () {
      // const vm = this
      const locationId = parseInt(this.$route.query.id) || 0
      if (locationId === 0) return false
      //
      const filterFn = z => { return z.notes.includes(`"${locationId}"`) }
      const routes = Object.values(this.$store.state.geofences.all).filter(filterFn)
      let allAssoc = []
      let routeList = []
      routes.forEach((route) => {
        if (!route.initialized) {
          // this['geofences/getRouteDetails']({mapId: zone.mapId})
          allAssoc.push(...route.associationList)
        }
        routeList.push(route.mapId)
      })
      const uniqueAssoc = Array.from(new Set(allAssoc))
      // console.log('initilize all: ', uniqueAssoc, routeList)
      this['geofences/initializeAll']({mapIds: uniqueAssoc, routeIds: routeList, force: true})
      this.selectedRoutes = routeList
      this.isCheckedAll = routeList.length === this.routeData.length
    },
    /**
     * @param {Object} row - Object value of selected row
     * Assigned selected mapId to selectedRoutes
     */
    selectRoute (row) {
      const mapId = parseInt(row.mapId)
      if (!this.selectedRoutes.includes(mapId)) {
        this.selectedRoutes.push(mapId)
      } else {
        this.selectedRoutes = this.selectedRoutes.filter(item => parseInt(item) !== mapId)
      }
      this.isCheckedAll = this.routeData.length === this.selectedRoutes.length
    },
    getDeviceDetails (mac) {
      const fullMacId = `00_1B_AD_00_${mac}`
      this.deviceStatusMessage.title = `Device Status (${mac})\n`
      this.deviceStatusMessage.message = `Fetching status for device with id: ${fullMacId}`
      this.modalIsActive['showMessage'] = true
      this['devices/getStatus'](fullMacId).then(response => {
        const batteryLevel = response.lastBatteryLevel ? parseFloat(response.lastBatteryLevel).toFixed(2) : 0.0
        const resp = `<table>
          <tr><td>Name:</td><td> ${response.name}</td></tr>
          <tr><td>Status:</td> <td>${response.status}</td></tr>
          <tr><td>Last Shipment:</td> <td>${(response.shipmentId && response.shipType) ? response.shipmentId + ' (' + response.shipType + ')' : '-'}</td></tr>
          <tr><td>Last Battery Level:</td> <td>${batteryLevel}</td></tr>
          <tr><td>Parent:</td> <td>${this.inventoryParent(response)}</td></tr>
          <tr><td>Inventory Status:</td> <td>${response.inventoryStatus}</td></tr>
          <tr><td>Timestamp:</td> <td>${response.inventorySource}</td></tr>
        </table>`
        this.deviceStatusMessage.message = resp
        this.mac = ''
      }).catch(e => {
        this.deviceStatusMessage.message = `<p class='text-danger'>${e.detail}</p>`
      })
    },
    inventoryParent (res) {
      if (typeof this.$store.state.chargerGatewayNames[res.inventoryParent] !== 'undefined') {
        const getParent = this.$store.state.chargerGatewayNames[res.inventoryParent]
        return `${getParent.deviceTypeStr} ${res.inventoryParent} (${getParent.location})`
      }
      return res.inventoryParent
    },
    selectAllRoutes () {
      const vm = this
      vm.isCheckedAll = !vm.isCheckedAll
      if (vm.isCheckedAll) {
        this.selectedRoutes = this.routeData.map(rt => rt.mapId)
        return true
      }
      this.selectedRoutes = []
    },
    openAddModal (typ) {
      this.mode = typ === 'group' ? 'addGroup' : 'addFence'
      // Call method to reset values in geofence
      this.$refs.geofenceModal.resetGeofence()
      this.modalIsActive['editGeofence'] = true
    },
    openEditModal (mrk) {
      this.mode = 'edit'
      this.selected = mrk.mapId
      this.geofence = this.$store.state.geofences.all[mrk.mapId]
      // this.description = mrk.description
      this.modalIsActive['editGeofence'] = true
    },
    parseMessage (msg) {
      const inventory = msg.substring(1).slice(0, -1)
      // const chimeRegex = /(C._B1_[2-7][A-F0-9_]{4})/g
      // const tagRegex = /(C._B._1[A-F0-9_]{4})/g
      const chimeRegex = new RegExp(this.$store.state.configuration.deviceConfig.iChimeRegex, 'g')
      const tagRegex = new RegExp(this.$store.state.configuration.deviceConfig.iTagRegex, 'g')
      const chimes = inventory.match(chimeRegex) || []
      const tags = inventory.match(tagRegex) || []
      let data = []
      if (tags.length > 0) {
        data.push({'label': 'iTag', data: tags, callback: this.getDeviceDetails})
      }
      if (chimes.length > 0) {
        data.push({'label': 'iChime', data: chimes, callback: this.getDeviceDetails})
      }
      return data
    },
    showInventory (mac) {
      // const devType = mac.type === 'iCharge' ? '0D_FF' : '0D_00'
      const fullMacId = `00_1B_AD_00_${mac.mac}`
      // Populate temp messages & open modal
      this.inventoryStatusMessage.title = `Inventory - ${mac.mac}`
      this.inventoryStatusMessage.message = `Fetching inventory for: ${fullMacId}`
      this.inventoryStatusMessage.data = []
      this.inventoryStatusMessage.isHtml = true
      // Get inventory data & replace message
      this['devices/getStatus'](fullMacId).then(response => {
        // Get string and remove first and last characters
        this.inventoryStatusMessage.message = ''
        const devicesData = this.parseMessage(response.inventoryList)
        this.inventoryStatusMessage.data = devicesData
        if (devicesData.length === 0) {
          this.inventoryStatusMessage.message = `No inventory data found for the device ${mac.mac}`
          this.inventoryStatusMessage.isHtml = false
        }
        this.mac = ''
        this.modalIsActive['showInventoryDetail'] = true
      }).catch(e => {
        this.inventoryStatusMessage.message = e.response.data.errors
      })
    },
    ...mapActions([
      'devices/getStatus',
      'geofences/getRouteDetails',
      'geofences/initializeAll'
    ])
  },
  mixins: [modalWindowManager],
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.chart-style {
  height: 300px;
  float: none;
  clear: both;
}
.chart-style-tilt-shock {
  height: 100px;
  float: none;
  clear: both;
}
.btn-left {
  background: #FFF;
}
.btn-right {
  background: #FFF;
}
.text-danger {
  color: #dc3545;
}
</style>
