<template>
  <div>
    <level-bar>{{name}}</level-bar>
    <div class="columns">
      <div class="column">
        <intelyt-card>
          <template slot="header">{{itemName}}s</template>
          <template slot="controls">
            <span v-for="filter in getFilters" :key="filter.col">
              <span class="tag is-primary boldtext" v-if="filter.active === true && filter.val.length !== 0">
                {{filter.name}}: {{getFilterValue(filter)}}
                <button class="delete is-small" @click="clearFilter(filter)"></button>
              </span>
            </span>
            <span style="display:flex;align-items:center;">
              <base-icon name="binoculars" @click="openModal('shipmentFind')" v-tooltip="`Find Archived ${itemName}s`" class="icon-padding"/>
              <base-icon name="filter" @click="openModal('filterTable')" v-tooltip="`Filter Current ${itemName}s`" class="icon-padding"/>
            <!-- <span style="display:flex; align-content:center;">
            <label style="min-width:175px;">
              <input style="width:100%;" type="search" id="search-input" class="form-control" placeholder="Search Current Shipments"
                  :value="searchInput"
                  @input="(e) => {this.searchInput = e.target.value}">
            </label>
             <span class="icon-search is-right" style="padding-top:5px;">
                <base-icon name="search" size="18" color="grey"/>
              </span>
          </span> -->
              <base-icon name="download_csv"  @click="downloadCSV()" v-tooltip="'Download CSV'" class="icon-padding"/>
            <!-- <span class="icon">
              <base-icon name="download_xls"  @click="downloadExcel()" v-tooltip="'Download Excel'" />
            </span> -->
              <base-icon name="print"  @click="tablePrint()" v-tooltip="'Print'" class="icon-padding"/>
            </span>
          </template>
          <base-table
            ref="shipmentList"
            :data="getShipments()"
            :columns="columns"
            :filter="filters.getActive()"
            :options="options"
            :isNarrow="isNarrow"
          >
          </base-table>
        </intelyt-card>
      </div>
    </div>
    <filter-table-modal
      :filters="filters.getAll()"
      :isActive="modalIsActive.filterTable"
      :modalTitle="`Filter ${itemName}s`"
      v-on:close="closeModal('filterTable')"
      v-on:reset="filtersReset()"
    >
    </filter-table-modal>
    <shipment-find-modal
      :isActive="modalIsActive.shipmentFind"
      :tabConfig="tabConfig"
      :redirectPath="'shipment-detail'"
      :title="`Find ${itemName}`"
      :redirect="true"
      v-on:close="closeModal('shipmentFind')"
    >
    </shipment-find-modal>
  </div>
</template>

<script>
import BaseTable from '@/components/BaseTable'
import FilterTableModal from '@/modals/FilterTableModal'
import ShipmentFindModal from '@/modals/ShipmentFindModal'
import {mapActions, mapState} from 'vuex'
import {modalWindowManager, tableFilterManager} from '@/store/mixins'
import {getShpmntDeviceType} from '@/store/helpers'
import {capitalize, chimeAcl, chimeCount, shipIcons, shortDayTime, deviceLink, unackAlerts, csvTextFmt, lastCommsFmt} from '@/store/formatters'
import moment from 'moment-timezone'

export default {
  components: {
    BaseTable,
    FilterTableModal,
    ShipmentFindModal
  },
  computed: {
    noopCalc: function () {
      return 'Random String'
    },
    shipList: function () {
      const shp = Object.values(this.$store.state.shipmentz.all)
      // console.log('SHP: ', shp)
      return shp
    },
    ...mapState({
      shipments: 'shipments',
      tags: 'tags',
      shipStates: state => state.stateDefs.shipment,
      user: 'user'
    })
  },
  created () {
    /* If there is a list of ids in the query parameters, include that list in the call when
     * updating the shipment list, otherwise, use defaults */
    if (this.$route.query.ids) {
      const args = {cfgType: 0, ids: this.$route.query.ids}
      this.$store.dispatch('shipments/updateShipmentList', args)
    } else {
      this.$store.dispatch('shipments/updateShipmentList')
    }
    /* Initialize reuqured geozones and routes */
    // this.$store.dispatch('geofences/initializeAll')
    this.$store.dispatch('geofences/initializeRouteList')

    if (Object.values(this.$store.state.locations.all).length === 0) {
      this.$store.dispatch('locations/initializeAll')
    }
    /* Read filter query arguments and load filters */
    this.setFilterbyQuery()

    const pageOptions = this.$store.state.configuration.pageOptions[`${this.mode}List`]
    const query = Object.prototype.hasOwnProperty.call(this.$route.query, 'fltcol')
    // If the page config have default filters, then apply the default filters
    if (!query && pageOptions.defaultFilters && pageOptions.defaultFilters.length > 0) {
      const filters = pageOptions.defaultFilters
      this.$store.dispatch('updateFilterState', [this.filterKey, filters, true])
    }
  },
  data () {
    const mode = this.$route.query.mode || 'shipment'
    // Formatter to get link for shipment id column
    let nameLink = function (row) {
      return {path: 'detail', query: { id: row.guid, mode }}
    }
    // set values for state and config for use below....
    const thisState = this.$store.state
    const itemName = capitalize(mode)
    const pageOptions = thisState.configuration.pageOptions[`${mode}List`]

    // Formatter for link to device details
    const deviceType = getShpmntDeviceType(this.$store.state, pageOptions.configType)
    let getDeviceLink = function (mac) {
      return deviceLink(thisState, mac, deviceType)
    }

    // Get timezone from users configuraiton
    const tmzn = this.$store.state.user.timezone
      ? this.$store.state.user.timezone
      : moment.tz.guess()
    const timezone = moment().tz(tmzn).format('z')

    // Formatter for shipping status string - text based off status number
    const shipStateDefs = this.$store.state.stateDefs.shipping
    const shpStatusStr = function (statusID) {
      return shipStateDefs[statusID]
    }

    // Formatters for Last Comms - requires depart date, so GUID must be passed to formatters
    const vm = this
    const lastComms = function (guid) {
      const shpmnt = vm.$store.state.shipments.all[guid]
      return lastCommsFmt(shpmnt, pageOptions.lastCommsHours)
    }
    const lastCommsExpt = function (guid) {
      const shpmnt = vm.$store.state.shipments.all[guid]
      return shortDayTime(shpmnt.lastComms)
    }

    // Build table columns list
    const allColumns = {
      shipmentId: {label: `${itemName} ID`, id: 'clientShipmentId', link: nameLink},
      origin: {label: 'Origin', id: 'origin'},
      destination: {label: 'Destination', id: 'destination'},
      mode: {label: 'Mode', id: 'mode'},
      notes: {label: 'Notes', id: 'notes'},
      customer: {label: 'Customer', id: 'customer'},
      status: {label: 'Status', id: 'shippingState', formatter: shpStatusStr},
      device: {label: 'iTag', id: 'macId', formatter: getDeviceLink},
      chimes: {label: 'Chimes (Exp/Act)', id: 'acl', formatter: chimeAcl, exportFmt: csvTextFmt},
      chimeCount: {label: 'Chimes', id: 'chimes', formatter: chimeCount, exportFmt: csvTextFmt},
      commission: {label: `Commission (${timezone})`, id: 'commDate', formatter: shortDayTime, exportFmt: shortDayTime},
      depart: {label: `Depart WH (${timezone})`, id: 'departDate', formatter: shortDayTime, exportFmt: shortDayTime},
      lastComms: {label: `Last Comms (${timezone})`, id: 'guid', formatter: lastComms, exportFmt: lastCommsExpt, ignoreSorting: true},
      unack: {label: '', id: 'unackAlert', formatter: unackAlerts},
      alerts: {label: '', id: 'securityStateStr', formatter: shipIcons}
    }
    /* Mapping function to map colum list to use all columns, including label replacement
     * If item is an object, use name to lookup field label for the label
     * Otherwise, use string as lookup and get label from standard all columns object.
     */
    const mapFn = function (col) {
      if (typeof col === 'object') {
        let column = allColumns[col.name]
        column.label = col.label
        return column
      } else {
        return allColumns[col]
      }
    }
    // acl: {label: 'ACL (Exp/Act)', id: 'acl', formatter: chimeAcl},
    const columns = pageOptions.tableColumns.map(mapFn)
    return {
      name: `${itemName}s`,
      // activeFilters: preFilter ? [preFilter] : [],
      columns,
      filterConfig: pageOptions.tableFilters,
      filterKey: 'shipments',
      isNarrow: true,
      itemName,
      modalIsActive: {
        filterTable: false,
        shipmentFind: false
      },
      mode,
      options: pageOptions.tableOptions,
      tabConfig: pageOptions.findShipmentFields,
      timezone
    }
  },
  methods: {
    downloadCSV: function () {
      this.$refs.shipmentList.generateCSV(`${this.mode}List.csv`)
    },
    downloadExcel: function () {
      this.$refs.shipmentList.generateExcel(`${this.mode}List.xlsx`)
    },
    tablePrint: function () {
      this.$refs.shipmentList.generatePrint('listingtable')
    },
    getShipments () {
      // Shipment list is all shipments with type != Repo
      // Process the shipments and add property unackAlert to indicate
      // any shipmetns which have unacknowldeged alerts.
      const unackShp = this.$store.getters['alerts/getUnacknowledgedShipments']()
      const filterFn = (shp) => { return shp.class === 'Shipment' && shp.type !== 'Reposition' && shp.shippingState > 1 }
      const shipments = Object.values(this.shipments.all).filter(filterFn).map(shp => ({...shp, unackAlert: unackShp.has(shp.guid)}))
      return shipments
    },
    toggleChimeVis (shipment) {
      this['shipments/toggleChimeVisibility'](shipment)
    },
    ...mapActions([
      'shipments/toggleChimeVisibility'
    ])
  },
  mixins: [modalWindowManager, tableFilterManager]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.boldtext {
  font-weight: bold;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
