import {
  deviceCountsProvider,
  getDeviceIdsProvider,
  getDeviceStatusProvider,
  getDeviceStatusListProvider,
  getTableEntriesProvider,
  getQueryProvider,
  getMessagesProvider
} from '../providers'

import {calcDeviceCharge, isJSON, round, getMessageTypes, getRoundedAverage} from '@/store/helpers.js'

import Vue from 'vue'

import beacons from './devices/beacons'
import chargers from './devices/chargers'
import chimes from './devices/chimes'
import gateways from './devices/gateways'
import mikeys from './devices/mikeys'
import scanners from './devices/scanners'
import tags from './devices/tags'

import moment from 'moment-timezone'

const deviceTypeMap = {
  tags: {id: '1', typeStr: 'DEVICE_ITAG', mutator: 'tags/SET_TAGS'},
  chimes: {id: '2', typeStr: 'DEVICE_ICHIME', mutator: 'chimes/SET_CHIMES'},
  gateways: {id: '3', typeStr: 'DEVICE_IGATE', mutator: 'gateways/SET_GATEWAYS'},
  mikeys: {id: '4', typeStr: 'DEVICE_MIKEY', mutator: 'mikeys/SET_MIKEYS'},
  scanners: {id: '5', typeStr: 'DEVICE_ISCAN', mutator: 'scanners/SET_SCANNERS'},
  unknown: {id: '6', typeStr: 'DEVICE_UNKNOWN', mutator: ''},
  chargers: {id: '7', typeStr: 'DEVICE_ICHARGE', mutator: 'chargers/SET_CHARGERS'},
  beacons: {id: '8', typeStr: 'DEVICE_IBEACON', mutator: 'beacons/SET_BEACONS'}
}

// initial state
const getDefaultState = () => {
  return {
    all: {},
    counts: {},
    chargeGatewayLocations: {},
    deviceTypes: [],
    summary: {}
  }
}
/**
 * processDeviceQueryData - Construct the input for the devices.all{deviceType}.all list
 * Process the temperature, humidity from the notes section
 * Process the last comms from the last cell, gps and gateway times
 * @param {Array} devices - List of device details from the query response
 * @param {String} deviceType - Device type(tags/chimes/...)
 * @param {Object} rootState - vuex state
 * @returns {Array} devices - List of formatted device data
 */
const processDeviceQueryData = (devices, deviceType, rootState) => {
  const devMapFn = dev => {
    let device = {}
    let deviceStatusMap = {}
    const deviceConfig = rootState.configuration.deviceConfig.deviceStatusMap
    for (const [key, val] of Object.entries(deviceConfig)) {
      deviceStatusMap[val] = key
    }
    device = dev
    // Last gateway timestamp
    const gateWayTimestamp = dev.lastMessageGateway ? moment.utc(dev.lastMessageGateway).valueOf() : 0
    // Last Cell timestamp
    const cellTimestamp = dev.lastMessageCell ? moment.utc(dev.lastMessageCell).valueOf() : 0
    // Find the recent message time using the max timestamp value
    const lastCommsTimestamp = Math.max(gateWayTimestamp, cellTimestamp) // Get the max value from the above 2
    device.battery = parseFloat(dev.lastBatteryLevel).toFixed(2)
    device.lastComms = lastCommsTimestamp > 0 ? moment.utc(lastCommsTimestamp) : ''
    device.statusCode = Number(dev.provisionStatus)
    device.status = deviceStatusMap[dev.provisionStatus] || ''
    device.charge = calcDeviceCharge(rootState, {voltage: device.battery, type: deviceType})
    // If notes have value and it is json, then fill the temperature, humidity... values
    if (dev.notes.length > 10) {
      const notesData = isJSON(dev.notes) ? JSON.parse(dev.notes) : ''
      let temperature = 0
      // Calculate the temperature value based on the user preference
      if (notesData.temperature) temperature = rootState.user.temperatureUnit === 'Celsius' ? (notesData.temperature - 32) * 5 / 9 : notesData.temperature
      device.temperature = round(temperature, 1)
      device.humidity = notesData.humidity
    }
    device.deviceType = deviceType
    return device
  }
  return devices.map(devMapFn)
}
/**
 * processDeviceAPIData - Construct the input for single device
 * Process the temperature, humidity from the notes section
 * Process the last comms from the last cell, gps and gateway times
 * @param {Object} dev - device details from the API response
 * @param {String} deviceType - Device type(tags/chimes/...)
 * @param {Object} rootState - vuex state
 * @returns {Object} device - formatted device data
 */
const processDeviceAPIData = (dev, deviceType, rootState) => {
  const device = {}
  // For the backward API compatibility
  // Old API version uses dev.properties
  // New API version uses dev.propertyMap
  const deviceProps = dev.properties || dev.propertyMap
  // Last gateway timestamp
  const gateWayTimestamp = deviceProps.lastGateTime ? moment.utc(deviceProps.lastGateTime).valueOf() : 0
  // Last Cell timestamp
  const cellTimestamp = deviceProps.lastCellTime ? moment.utc(deviceProps.lastCellTime).valueOf() : 0
  // Last Charge timestamp
  const chargeTimestamp = deviceProps.lastChargeTime ? moment.utc(deviceProps.lastChargeTime).valueOf() : 0
  // Find the recent message time using the max timestamp value
  const lastCommsTimestamp = Math.max(gateWayTimestamp, cellTimestamp, chargeTimestamp) // Get the max value from the above 3
  device.battery = parseFloat(deviceProps.lastBatteryLevel).toFixed(2)
  device.conopString = deviceProps.conopString
  device.displayGroup = dev.group
  device.lastBatteryLevel = deviceProps.lastBatteryLevel
  device.lastCharger = deviceProps.lastCharger
  device.lastCellTime = deviceProps.lastCellTime ? moment.utc(deviceProps.lastCellTime) : ''
  device.lastComms = lastCommsTimestamp > 0 ? moment.utc(lastCommsTimestamp) : ''
  device.lastGateTime = deviceProps.lastGateTime ? moment.utc(deviceProps.lastGateTime) : ''
  device.lastChargeTime = deviceProps.lastChargeTime ? moment.utc(deviceProps.lastChargeTime) : ''
  device.lastGateway = deviceProps.lastGateway
  device.macId = dev.macId
  device.messageObj = dev.messages || {}
  device.messages = dev.messages ? getMessageTypes(dev.messages) : []
  device.name = dev.name
  device.notes = dev.notes
  device.properties = deviceProps
  device.parent = deviceProps.lastKnownParent
  device.type = dev.type
  device.status = dev.status
  device.id = dev.entryId
  device.latitude = deviceProps.lastLatitude
  device.longitude = deviceProps.lastLongitude
  if (Object.prototype.hasOwnProperty.call(dev, 'statusCode')) device.statusCode = dev.statusCode
  device.charge = calcDeviceCharge(rootState, {voltage: device.battery, type: deviceType})
  // If notes have value and it is json, then fill the temperature, humidity... values
  if (dev.notes.length > 10) {
    const notesData = isJSON(dev.notes) ? JSON.parse(dev.notes) : ''
    let temperature = 0
    // Calculate the temperature value based on the user preference
    if (notesData.temperature) temperature = rootState.user.temperatureUnit === 'Celsius' ? (notesData.temperature - 32) * 5 / 9 : notesData.temperature
    device.temperature = round(temperature, 1)
    device.humidity = notesData.humidity
  }
  device.deviceType = deviceType
  return device
}

const state = getDefaultState()
// getters
const getters = {}

// actions
const actions = {
  fetchDeviceStatus ({ commit, state, rootState }, {deviceType = 'tags', deviceId} = {}) {
    const device = {...state[deviceType].all[deviceId]}
    const {maxLocAge, timeSeries} = rootState.configuration.deviceDetail[deviceType]
    // const msgPktTypes = (timeSeries || '60|1').split('|')
    const requestPayload601 = {
      deviceId: deviceId,
      pktType: 60,
      msgType: 1,
      max: 1,
      sort: 'sourceTime DESC',
      where: `sourceTime < DATE_SUB(curdate(), INTERVAL - ${maxLocAge} DAY)`
    }
    const requestLatestCell = Object.assign({}, requestPayload601, {where: `${requestPayload601.where} AND SUBSTRING(data, 35, 2) != "00"`})
    const requestLatestGPS = Object.assign({}, requestPayload601, {where: `${requestPayload601.where} AND SUBSTRING(data, 155, 2) = "05"`})
    Promise.all([
      // getMessagesProvider(rootState, requestPayload601), // Used to get the latitude & longitude
      getMessagesProvider(rootState, requestLatestCell), // Used to get the latest Cell position
      getMessagesProvider(rootState, requestLatestGPS) // Used to get the latest GPS position
    ]).then((responses) => {
      // 60/1 results
      /* if (responses[0].data[0] && responses[0].data[0]['Cell Tower Lat.'] > 0.0000001) {
        device.latitude = responses[0].data[0]['Cell Tower Lat.']
        device.longitude = responses[0].data[0]['Cell Tower Long.']
      } else if (responses[0].data[0] && Number(responses[0].data[0].Latitude) > 0.000001) {
        device.latitude = responses[0].data[0].Latitude
        device.longitude = responses[0].data[0].Longitude
      } */
      // Add GPS Info...
      if (Object.keys(responses[1].data).length > 0) {
        device.latestGPS = {
          position: {
            lat: Number.parseFloat(responses[1].data[0].Latitude),
            lng: Number.parseFloat(responses[1].data[0].Longitude)
          },
          timestamp: moment.utc(responses[1].data[0]['Device Time (UTC)'], 'ddd MMM D HH:mm:ss YYYY')
        }
      }
      // commit results received thus far...
      commit('UPDATE_DEVICE', {id: deviceId, type: deviceType, data: device})
      // if cell information MNC, CID, etc. is found get location from lookup and add to results
      if (Object.keys(responses[0].data).length > 0 && Number.parseInt(responses[0].data[0].MNC) > 0) {
        let where = 'cellId = ' + responses[0].data[0].CID + ' AND locationAreaCode = ' + responses[0].data[0].LAC
        let params = {
          tableName: 'cellLocations',
          where: where,
          sort: 'entryId DESC'
        }
        getTableEntriesProvider(rootState, params).then(response => {
          if (response.data[0]) {
            let cellPayload = {
              latestCell: {
                position: {
                  lat: Number.parseFloat(response.data[0].latitude),
                  lng: Number.parseFloat(response.data[0].longitude)
                },
                timestamp: moment.utc(responses[0].data[0]['Device Time (UTC)'], 'ddd MMM D HH:mm:ss YYYY'),
                location: response.data[0].locationInfo
              }
            }
            commit('UPDATE_DEVICE', {id: deviceId, type: deviceType, data: cellPayload})
          }
        }).catch(e => {
          console.log(e)
        })
      }
      // if 60/6 is the message type for this device, get environmental data and add to results
      // if (device.messages.includes('60/4') || device.messages.includes('60/6')) {
        if (timeSeries === '60|6') {
        // const envRecord = device.messages.includes('60/4') ? 4 : 6
        const requestEnvironmental = Object.assign(requestPayload601, {msgType: 6})
        getMessagesProvider(rootState, requestEnvironmental).then(response => {
          // console.log('604 RESPONSE ---- >', requestEnvironmental, response)
          if (response.data[0]) {
            // Check the user preference for the temperature unit. If temperatureUnit is true, then convert the
            // temperature value to celsius. Else store as it is in the response
            const temperature = rootState.user.temperatureUnit === 'Celsius' ? (response.data[0].Temperature - 32) * 5 / 9 : response.data[0].Temperature
            const updatePayloadEnvironmental = {
              temperature: getRoundedAverage([temperature], 1),
              humidity: getRoundedAverage([response.data[0].Humidity], 1)
            }
            commit('UPDATE_DEVICE', {id: deviceId, type: deviceType, data: updatePayloadEnvironmental})
          }
        }).catch(e => {
          console.error(e)
        })
      }
      // dispatch('fetchDeviceTimeSeries', {deviceId, deviceType, addLocs: true})
    }).catch(e => {
      console.error(e)
    })
    /* // Fetch the device status and update it to the state.
    getDeviceStatusProvider(rootState, deviceId).then(response => {
      device.id = response.data.entryId
      device.messages = getMessageTypes(response.data.messages)
      device.messageObj = response.data.messages || {}
      device.name = response.data.name
      device.status = response.data.status
      device.properties = response.data.properties
      if (device.hasOwnProperty('properties')) {
        device.lastCellTime = device.properties.lastCellTime ? moment.utc(device.properties.lastCellTime) : ''
        device.lastCharger = device.properties.lastCharger
        device.lastChargeTime = device.properties.lastChargeTime ? moment.utc(device.properties.lastChargeTime) : ''
        device.lastComms = device.properties.lastComms ? moment.utc(device.properties.lastComms) : ''
        device.lastGateway = device.properties.lastGateway
        device.lastGateTime = device.properties.lastGateTime ? moment.utc(device.properties.lastGateTime) : ''
      }
      const payload = {
        type: deviceType,
        id: deviceId,
        device: device
      }
      commit('UPDATE_DEVICE', payload)
    }).catch(e => {
      console.log('device > fetchDeviceStatus > getDeviceStatusProvider FAILED', e)
    }) */
  },
  /**
   * fetchDeviceShipments API will get all shipments/assets asscociated with a
   * device based on the 'deviceShipments' query.
   * @param {String} deviceId - Mac ID of the current device
   * @param {String} deviceType - Type of the device like tags/chimes
   */
  fetchDeviceShipments ({ commit, rootState }, {deviceId = '', deviceType = 'tags'} = {}) {
    let queryPayload = {
      query: 'deviceShipments',
      params: [deviceId, String(rootState.company.id)]
    }
    // call get messages to get 48 message, and send to be added
    getQueryProvider(rootState, queryPayload).then(response => {
      commit('UPDATE_DEVICE', {id: deviceId, type: deviceType, data: {shipments: response.data}})
    }).catch(e => {
      console.log(e)
    })
  },
  /**
   * etchDeviceTimeSeries API will fetch latest 60/1 messages to build
   * time series array and store it in the device
   * @param {String} deviceId - Mac ID of the current device
   * @param {String} deviceType - Type of the device like tags/chimes
   * @param {Number} lookback - Number of days to look back
   * @param {Boolean} addLocs - Condition to add the location data or not
   */
  fetchDeviceTimeSeries ({ commit, state, rootState }, {deviceId = '', deviceType = 'tags', addLocs = false} = {}) {
    if (!state[deviceType].all[deviceId]) {
      return
    }
    const {maxLocAge, maxTsRecords, timeSeries} = rootState.configuration.deviceDetail[deviceType]
    const msgPktTypes = (timeSeries || '60|1').split('|')
    // If an explicit message list is NOT provided, use all messages for the device
    // if (messages.length === 0) messages = state[deviceType].all[deviceId].messages
    // Remove above two lines.....
    let tsData = []
    let locData = []
    let requestPayload = {
      deviceId: deviceId,
      pktType: msgPktTypes[0],
      msgType: msgPktTypes[1],
      max: maxTsRecords,
      sort: 'sourceTime DESC',
      where: ` sourceTime >= NOW() - INTERVAL ${maxLocAge} DAY `
    }
    let batteryLabel = 'Battery'
    let temperatureLabel = 'Temperature'
    let humidityLabel = parseInt(msgPktTypes[1]) === 6 ? 'Humidity' : false
    // console.log('Messages: ', deviceId, state.tags[deviceId].messages)
    // if (messages.indexOf('60/6') >= 0) {
    //   requestPayload.msgType = 6
    //   batteryLabel = 'Battery'
    //   humidityLabel = 'Humidity'
    // } else if (messages.indexOf('60/4') >= 0) {
    //   batteryLabel = 'Battery'
    //   requestPayload.msgType = 4
    //   humidityLabel = 'Humidity'
    // } else {
    //   // Use 60/1
    //   temperatureLabel = 'Temp'
    //   batteryLabel = 'Battery'
    // }
    return new Promise((resolve, reject) => {
      getMessagesProvider(rootState, requestPayload).then(response => {
        for (let val of Object.values(response.data)) {
          let payload = {timestamp: Number(moment.utc(val['Device Time (UTC)'], 'ddd MMM D HH:mm:ss YYYY').format('x'))}
          let batt = val[batteryLabel] || val['Tag Battery']
          // Check the user preference for the temperature unit. If temperatureUnit is true, then convert the
          // temperature value to celsius. Else store as it is in the response
          const temperature = rootState.user.temperatureUnit === 'Celsius' ? (val[temperatureLabel] - 32) * 5 / 9 : val[temperatureLabel]
          // console.log('temperature', temperature, val[temperatureLabel])
          payload.temperature = round(temperature, 1)
          if (humidityLabel) {
            payload.humidity = round(val[humidityLabel], 1)
          }
          payload.battery = round(batt, 2)
          payload.charge = calcDeviceCharge(rootState, {voltage: batt, type: deviceType})
          payload.datetime = val['Device Time (UTC)']
          //  If using 60/1 message and locations is true, add location data to time series
          if (requestPayload.msgType === 1 && addLocs) {
            // console.log('Message Data: ', parseInt(val.NumSV), parseFloat(val["Cell Tower Lat."]), val)
            let locPayload = {timestamp: Number(moment.utc(val['Device Time (UTC)'], 'ddd MMM D HH:mm:ss YYYY').format('x'))}
            if (parseInt(val.NumSV) > 0) {
              locPayload.locationType = 'GPS'
              locPayload.latitude = val.Latitude
              locPayload.longitude = val.Longitude
              locData.unshift(locPayload)
            } /* else if (parseFloat(val['Cell Tower Lat.']) !== 0.0) {
              locPayload.locationType = 'Cell'
              locPayload.latitude = val['Cell Tower Lat.']
              locPayload.longitude = val['Cell Tower Long.']
              locData.unshift(locPayload)
            } */
            // console.log('Message Data: ', val)
          }
          tsData.unshift(payload)
        }
        commit('UPDATE_DEVICE', {id: deviceId, type: deviceType, data: { tsData }})
        if (locData.length > 1) {
          commit('UPDATE_DEVICE', {id: deviceId, type: deviceType, data: { locData }})
        }
      }).catch(e => {
        console.log(e)
        reject(e)
      })
    })
  },
  fetchSummaryCounts ({ commit, rootState }) {
    const cmpnyId = String(rootState.company.id)
    const getCountQuery = {
      query: 'deviceInventory',
      params: [cmpnyId, cmpnyId, cmpnyId, cmpnyId, cmpnyId]
    }
    getQueryProvider(rootState, getCountQuery).then(response => {
      // console.log('SET_SUMMARY_COUNTS', response.data)
      commit('SET_SUMMARY_COUNTS', Object.values(response.data))
    }).catch(e => {
      console.log(e)
    })
  },
  /**
   * Gets all device counts (grouped by lastParentGateway) from database and sets in
   * state as object with key as parent mac and values of device type and count.
   * Calls {@link SET_DEVICE_COUNTS} to store state
   * @todo Use differnt method to get counts that gets live inventory from cache instead
   * of database.
   */
  getCounts ({ commit, rootState }) {
    // console.log(`--- Get Device Counts  ---`)
    Promise.all([
      getDeviceIdsProvider(rootState, 'DEVICE_IGATE'),
      getDeviceIdsProvider(rootState, 'DEVICE_ICHARGE')
    ]).then(responses => {
      // get list of all chargers and iGates and concatenate into single list
      const devicePayload = {
        devices: [...responses[0].data, ...responses[1].data],
        recurse: false
      }
      deviceCountsProvider(rootState, devicePayload).then(response => {
        // console.log('NEW COUNTS', response.data)
        commit('SET_DEVICE_COUNTS', response.data)
      }).catch(e => {
        console.log(e)
      })
    }).catch(e => {
      console.log(e)
    })
  },
  /**
   * @param {String} deviceId - macId for the device
   * @param {String} deviceType - device type like tags/chimes/chargers
   * Get the particular device details when device details
   * not available in the state using getDeviceStatusProvider
   */
  getSingleDeviceDetails ({ commit, rootState }, {deviceId = '', deviceType = 'tags'} = {}) {
    return new Promise((resolve, reject) => {
      getDeviceStatusProvider(rootState, deviceId).then(response => {
        const device = response.data
        const deviceStatusCfg = rootState.configuration.deviceConfig.deviceStatusMap
        device.macId = deviceId
        if (Object.prototype.hasOwnProperty.call(deviceStatusCfg, device.status)) device.statusCode = deviceStatusCfg[device.status]
        let deviceData = processDeviceAPIData(device, deviceType, rootState)
        commit(deviceTypeMap[deviceType].mutator, [deviceData])
        resolve()
      }).catch(e => { // Reject when the API failed/permission issue
        console.log('ERROR: ', e)
        reject(e)
      })
    })
  },
  /**
   * Get device status and last shipment details for the device.
   * @param {String} deviceId - Id of the device
   * Get responses using promise.all
   * Returns the merged response data using resolve method
   */
  getStatus ({ rootState }, deviceId) {
    const getQueryPayload = {
      query: 'lastShipment',
      params: [String(rootState.company.id), String(deviceId)]
    }
    return new Promise((resolve, reject) => {
      Promise.all([
        getDeviceStatusProvider(rootState, deviceId),
        getQueryProvider(rootState, getQueryPayload)
      ]).then(responses => {
        // console.log('Responses: ', responses)
        const finalResponse = responses[1] ? {...responses[0].data, ...responses[1].data[0]} : responses[0].data
        resolve(finalResponse)
      }).catch(e => {
        reject(e.response.data.errors[0])
      })
    })
  },
  /**
   * Gets all device status for list of devices and returns data via promise.
   * @param {Array} deviceList - List of devices as JS Array
   */
  getStatusList ({ rootState }, deviceList) {
    return new Promise((resolve, reject) => {
      getDeviceStatusListProvider(rootState, deviceList).then(response => {
        resolve(response.data)
      }).catch(e => {
        reject(e)
      })
    })
  },
  /**
   * Gets all device data from deviceDetails query and id list from deviceIdsProvider.
   * The device details are filtered using the list from the device id provider (which does check
   * using the API) and then applies it for a single device type.  The device type is provided as
   * the argument to the action.  State is updated in the device specific mutators.  The mapping
   * (deviceTypeMap) is used to call the appropriator mutation by type.
   * @todo Finish device type map when mutators avaialble.
   */
  initialize ({ commit, state, rootState }, {deviceType, force = false} = {}) {
    // Execute the function, when the state have no value or forced to initialize again
    if (!force && Object.values(state[deviceType].all).length !== 0) {
      return
    }
    // add the module in the initialized modules array in state to reset on logging out
    if (!rootState.initialized.includes('devices')) {
      commit('setInitializedModule', 'devices', { root: true })
      commit('setInitializedModule', 'devices/beacons', { root: true })
      commit('setInitializedModule', 'devices/chimes', { root: true })
      commit('setInitializedModule', 'devices/gateways', { root: true })
      commit('setInitializedModule', 'devices/chargers', { root: true })
      commit('setInitializedModule', 'devices/mikeys', { root: true })
      commit('setInitializedModule', 'devices/scanners', { root: true })
      commit('setInitializedModule', 'devices/tags', { root: true })
    }
    const getDeviceQuery = {
      query: 'deviceDetails',
      params: [deviceTypeMap[deviceType].id, String(rootState.company.id)]
    }
    /* qetQuery will return all required details needed to specify the device.
     * getDeviceIdsProvider returns a list of devices of the given type that the user has
     * permission to view.
     * These are merged using a filter to provide a list with correct details and permissions.
     */
    return new Promise((resolve, reject) => {
      Promise.all([
        getQueryProvider(rootState, getDeviceQuery),
        getDeviceIdsProvider(rootState, deviceTypeMap[deviceType].typeStr)
      ])
        .then(responses => {
          let resData = responses[0].data
          const filterFn = dev => { return responses[1].data.includes(dev.macId) }
          /* Get object containing devices that user has permissions for and add to state */
          let filteredDevices = Object.values(resData).filter(filterFn)
          filteredDevices = processDeviceQueryData(filteredDevices, deviceType, rootState)
          commit(deviceTypeMap[deviceType].mutator, filteredDevices)
          /* For gateways and chargers only, add the last record date for the object to determine if online or offline */
          if (deviceType === 'gateways' || deviceType === 'chargers') {
            const deviceList = filteredDevices.map(x => x.macId) // Device ids as a list for input into query
            getDeviceStatusListProvider(rootState, deviceList) // getting the last record date for the device
              .then(response => {
                // console.log('getDeviceStatusListProvider response:', response.data)
                const devMapFn = x => {
                  const macId = x.name
                  delete x.name
                  let dev = {}
                  if (deviceType === 'gateways') {
                    dev = {...state.gateways.all[macId], ...x}
                  } else if (deviceType === 'chargers') {
                    dev = {...state.chargers.all[macId], ...x}
                  }
                  return dev
                }
                let data = Object.values(response.data).map(devMapFn)
                // console.log('data', data, deviceType)
                /* updating the state with last record date and time to compare the device is online or offline
                 * in the location list page
                 */
                commit(deviceTypeMap[deviceType].mutator, data)
              })
              .catch(e => {
                console.log(e)
              })
          }
          resolve(responses)
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  },
  /**
    * Gets all the device types from database for the current user company.
  */
  initializeDeviceTypeList ({ commit, state, rootState }, {force = false} = {}) {
    // Execute the function, when the state have no value or forced to initialize again
    if (!force && Object.values(state.deviceTypes).length !== 0) {
      return
    }
    const getDeviceTypesQuery = {
      query: 'getDeviceTypeList',
      params: [String(rootState.company.id)]
    }
    getQueryProvider(rootState, getDeviceTypesQuery).then(response => {
      let devTypes = Object.values(response.data).map(type => type.deviceType)
      commit('SET_DEVICE_TYPES', devTypes)
    }).catch(e => {
      console.log(e)
    })
  },
  /**
    * Gets all the charger and gateway locations from database for the current user company.
  */
  initializeChargerAndGatewayLocations ({ commit, state, rootState }, {force = false} = {}) {
    // Execute the function, when the state have no value or forced to initialize again
    if (!force && Object.values(state.chargeGatewayLocations).length !== 0) {
      return
    }
    const queryDetails = {
      query: 'getChargerAndGatewayLocations',
      params: [String(rootState.company.id)]
    }
    getQueryProvider(rootState, queryDetails).then(response => {
      // let devTypes = Object.values(responses[0].data).map(type => type.deviceType)
      Object.values(response.data).forEach((dev) => {
        commit('SET_CHARGER_GATEWAY_LOCATIONS', dev)
      })
      // commit('SET_DEVICE_TYPES', devTypes)
    }).catch(e => {
      console.log(e)
    })
  },
  /**
    * Gets all device messages from database and returns to caller.
    * @param {object} payload - object that is passed to provider - contains pktType, msgType,
    * max (optional), where and deviceId.
  */
  fetchMessages ({ rootState }, payload) {
    // console.log(`--- Get Device Messages ---`)
    return new Promise((resolve, reject) => {
      // if (!payload.max) payload.max = 1000
      let messages = []
      const getMessages = function () {
        getMessagesProvider(rootState, payload).then(response => {
          // console.log('MESSAGE DATA', response)
          // console.log('Fields: ', Object.keys(response[0]))
          if (response.data && response.data[0]) {
            messages = messages.concat(Object.values(response.data))
            const lastMsgTime = moment(messages[messages.length - 1].sourceTime).format('YYYY-MM-DD HH:MM:SS')
            // If the diff between last meesage time and starting date is > 1, Get the record agai upto the diff becomes 0
            if (moment(lastMsgTime).diff(payload.startingDate, 'days') < 1) {
              resolve(messages)
            } else {
              payload.where = ` serverTime > "${payload.startingDate}" AND serverTime < "${lastMsgTime}"`
              if (Object.prototype.hasOwnProperty.call(payload, 'msgIndex')) {
                payload.where += ` AND CAST(substring(data,21,2) AS SIGNED) = ${payload.msgIndex}`
              }
              getMessages()
            }
          } else {
            resolve(messages)
          }
          // commit('SET_SUMMARY_COUNTS', Object.values(response.data))
        }).catch(e => {
          // console.log('MESSAGE DATA ERROR')
          // console.log(e)
          reject(e)
        })
      }
      getMessages()

      /* getMessagesProvider(rootState, payload).then(response => {
        // console.log('MESSAGE DATA', response)
        // console.log('Fields: ', Object.keys(response[0]))
        resolve(response.data)
        // commit('SET_SUMMARY_COUNTS', Object.values(response.data))
      }).catch(e => {
        // console.log('MESSAGE DATA ERROR')
        // console.log(e)
        reject(e)
      }) */
    })
  },
  /**
   * Get list of devices that are available to be provisioned based on device type,
   * management status (Active - 3) and provision status (None - 0)
   * @param {String} deviceTypeStr - String to indicate the of the device from values
   * availabe in deviceTypeMap
   * Returns list of devices
   */
  loadAvailableList ({commit, rootState}, {deviceType = 'chimes'} = {}) {
    const deviceTypeMap = {
      tags: {id: '1'},
      chimes: {id: '2'},
      mikeys: {id: '4'}
    }
    const devId = deviceTypeMap[deviceType].id
    const queryDetails = {
      query: 'availableDevices',
      params: [String(rootState.company.id), String(devId)]
    }
    getQueryProvider(rootState, queryDetails).then(response => {
      const deviceList = Object.values(response.data).map(dev => dev.macId)
      commit('SET_AVAILABLE_LIST', {type: deviceType, items: deviceList})
    }).catch(e => {
      console.log(e)
    })
  },
  /**
   * Get list of devices to be used based on user permission via api.
   * @param {String} deviceTypeStr - String to indicate the of the device,
   * for example 'DEVICE_IGATE'.  Once complete will load into state under 'ids'
   * Returns list of devices
   */
  loadIdList ({commit, rootState}, deviceTypeStr = 'DEVICE_IGATE') {
    return new Promise((resolve, reject) => {
      getDeviceIdsProvider(rootState, deviceTypeStr).then(response => {
        commit('SET_ID_LIST', response.data)
      }).catch(e => {
        reject(e.response.data.errors[0])
      })
    })
  },
  /**
   * updateAvailableList - When a shipment/asset created/removed will update the available list based on the
   * device type. Update function will work on based on the action ipnput.
   * If a shipment created, the action will be remove and the device will be removed from it's device type
   * available list. When a shipment/asset deleted, the action will be add and the macId will be added
   * to it's device type available list.
   * @param {String} deviceType - chimes/tags based on the configuration
   * @param {String} deviceId - The mac Id of the device
   * @param {String} action - Add/Remove based on the shipment/asset operation
   */
  updateAvailableList ({commit, state}, {deviceType = 'chimes', deviceId = '', action = 'add'} = {}) {
    if (!deviceId) return
    let deviceList = [...state[deviceType].available] || []
    if (action === 'add') {
      deviceList.push(deviceId)
    } else {
      deviceList = deviceList.filter(mac => mac !== deviceId)
    }
    commit('SET_AVAILABLE_LIST', {type: deviceType, items: deviceList})
  }
}

// mutations
const mutations = {
  /*
   * Set list of avaialble devices into state.devices.<type>.available.
   * The type is based on value passed to mutator
   */
  SET_AVAILABLE_LIST (state, available) {
    Vue.set(state[available.type], 'available', available.items)
  },
  SET_CHARGER_GATEWAY_LOCATIONS (state, device) {
    Vue.set(state.chargeGatewayLocations, device.macId, device)
  },
  SET_DEVICE_TYPES (state, deviceTypes) {
    Vue.set(state, 'deviceTypes', deviceTypes)
  },
  /**
   * Sets tag & chime device counts (by status) into state.
   * @param {Array} counts - Array with values of mac (of parent) and type can count
   * of device. It is a list with each row including the number of each device type
   * for each parent.
   */
  SET_SUMMARY_COUNTS (state, counts) {
    // console.log('COUNT DATA: ', counts)
    counts.forEach(cnt => {
      if (!state.summary[cnt.type]) {
        state.summary[cnt.type] = {}
        state.summary[cnt.type][cnt.inventory] = cnt.count
      }
      Vue.set(state.summary[cnt.type], cnt.inventory, cnt.count)
    })
  },
  /**
   * Sets all device counts (grouped by lastParentGateway) into state.
   * @param {Array} counts - Array with values of mac (of parent) and type can count
   * of device. It is a list with each row including the number of each device type
   * for each parent.
   */
  // SET_DEVICE_COUNTS (state, counts) {
  //   console.log('COUNT DATA: ', counts)
  //   for (let cnt of counts) {
  //     if (!state.counts[cnt.inventoryParent]) {
  //       state.counts[cnt.inventoryParent] = {}
  //     }
  //     Vue.set(state.counts[cnt.inventoryParent], cnt.deviceType, cnt.count)
  //   }
  // },
  /**
   * Resets object state to initial values set upon creation.
   */
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  },
  SET_DEVICE_COUNTS (state, counts) {
    // console.log('COUNT DATA: ', counts)
    Vue.set(state, 'counts', counts)
  },
  SET_ID_LIST (state, ids) {
    Vue.set(state, 'ids', ids)
  },
  UPDATE_DEVICE (state, payload) {
    if (!state[payload.type].all[payload.id]) Vue.set(state[payload.type], payload.id, {})
    for (let key in payload.data) {
      if (Object.prototype.hasOwnProperty.call(payload.data, key)) {
        Vue.set(state[payload.type].all[payload.id], key, payload.data[key])
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    beacons,
    chimes,
    chargers,
    gateways,
    mikeys,
    scanners,
    tags
  }
}
