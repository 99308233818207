/*
  This file is used to provide a central repository
  of functions which format data in various ways
*/
import moment from 'moment-timezone'
import {generateIconSVG, calcDeviceCharge, round, getDateFormat} from './helpers'

/* Return complete state object as a single json object. */
const getState = function () {
  const localState = localStorage.getItem('intelyt')
  return JSON.parse(localState)
}

let state = getState()

/**
 * Returns formatted string that represents icon for battery.<br>
 * Returns "-" if input is undefined
 * @param {Number} voltage - Battery voltage
 * @param {String} type - Device Type
 * @returns {String} Formatted string representing icon.
 */
export const batteryIcon = (voltage = '', type = 'chimes', showPercentage = false) => {
  // If the state is null/not available the get the state from localstorage
  state = getState()
  // return path and query string for router link
  if (typeof voltage === 'undefined' || voltage === '') {
    return '-'
  }
  const charge = calcDeviceCharge(state, {voltage, type})
  const chargeVmap = state.configuration.charts.vMapPieces.charge
  let iconConfig = chargeVmap[chargeVmap.length - 1]
  // Important Note: The VMap configuration should be in desc order for the gt value.
  // Loop the each vamp configuration and compare the charge value with config item
  // If the condition mentioned in the config matched, then use it to generate the icon
  for (const index in chargeVmap) {
    if (Object.prototype.hasOwnProperty.call(chargeVmap, index)) {
      const item = chargeVmap[index]
      if (item.gt && charge >= item.gt) {
        iconConfig = item
        break
      }
    }
  }
  let out = generateIconSVG(state, iconConfig)
  if (showPercentage) {
    const percent = charge * 100
    out = `${out} <span style='vertical-align: inherit;'>${percent.toFixed()}%</span>`
  }
  return out
}

/**
 * Returns formatted string that represents voltage and charge % for the battery.<br>
 * @param {Number} voltage - Battery voltage
 * @returns {String} Formatted HTML string representing voltage and charge %.
 */
export const batteryTooltip = (rootState, {voltage = 0, type = 'chimes', lastUpdate = false} = {}) => {
  let lastUpdateTxt = ''
  // If the last update time found, then use it
  if (lastUpdate && moment.isMoment(moment(lastUpdate))) {
    const tmz = rootState.user.timezone
    const dateFmt = `${getDateFormat()} HH:mm z`
    lastUpdateTxt = `<p>Last Update: ${moment.utc(lastUpdate).tz(tmz).format(dateFmt)}</p>`
  }
  if (voltage === 0) {
    return `${lastUpdateTxt}<p>Voltage: 0</p><p>Charge: 0%</p>`
  }
  const charge = round(calcDeviceCharge(rootState, {voltage, type}) * 100, 2)
  // return `<p>Voltage: ${voltage}</p><p>Charge: ${charge}%</p>`
  return `${lastUpdateTxt}<p>Voltage: ${voltage}</p><p>Charge: ${charge}%</p>`
}

/**
 * Returns formatted string with the first letter capitalized.
 * @param {String} str - Input string
 * @returns {String} Formatted string --- first letter capitalized
 */
export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Returns formatted div with centering style to insert into v-html element of table
 * @param {String} str - Input string
 * @returns {String} Formatted string --- in div element with centering style applied.
 */
export const centerText = (str) => {
  return `<div style="text-align:center;">${str}</div>`
}

/**
 * Returns formatted string for ACL of chimes, coding the color as appropriate.
 * If exp = act, no color applied <br>
 * if exp != act, color red
 * @param {String} chimeString - expected and actual separated by /
 * @returns {String} ACL formateted with proper coloring
 */
export const chimeAcl = (chimeString) => {
  // return path and query string for router link
  let style = 'text-align:center;'
  if (chimeString !== '-') {
    // console.log('chimeStr: ', chimeString)
    const regex = /([\d]+)\s+\/\s+([\d]+)/
    const result = chimeString.match(regex)
    if (result[2] && result[1] !== result[2]) {
      style += 'color:#930F0F;font-weight:bold;'
    }
  }
  return `<div style="${style}">${chimeString}</div>`
}

/**
 * Returns formatted string for ACL of chimes, coding the color as appropriate.
 * If exp = act, no color applied <br>
 * if exp != act, color red
 * @param {Object} chimeObj - chimes object from shipment
 * @returns {String} ACL formateted with proper coloring
 */
 export const chimeCount = (chimeObj) => {
  return Object.keys(chimeObj).length
}

/**
 * Returns formatted div with centering style to insert into v-html element of table
 * @param {String} str - Input string
 * @returns {String} Formatted string --- in div element with centering style applied.
 */
export const chimeList = (chimes) => {
  if (!chimes) {
    return ''
  } else if (typeof chimes === 'string' || chimes instanceof String) {
    return `<span>${chimes}</span>`
  }
  let chmLstStr = ''
  Object.values(chimes).forEach((chm, idx) => {
    const style = chm.intact ? '' : 'style="min-width: 70px;color:#930F0F;font-weight:bold;"'
    if (idx > 0) chmLstStr += '<br>'
    chmLstStr += `<span ${style}>${chm.shortMac}`
    console.debug('CHM: ', chm)
    if (chm.note && chm.note.length > 1) {
      const note = chm.note.split(',')
      chmLstStr += ` [${note[note.length - 1]}]`
    }
    chmLstStr += '</span>'
  })
  return chmLstStr
}

/**
 * Returns city and state pair (for USA addresses) or city and country
 * for non-USA addresses
 * @param {String} location - Input string of location information
 * for example: "1701 Airport Blvd, San Jose, CA 95110, USA"
 * @returns {String} Formatted string whith city and state separated
 * by a comman, for example: "San Jose, CA"
 * @todo Make function country specific to show city and state or
 * city and country, depending on what is appropriate.
 * @todo Remove zip code for usa addresses
 */
export const cityState = (location) => {
  if (location.length === 0) {
    return ''
  }
  const locData = location.split(',')
  // If third element exists, return city & state, otherwise only return city.
  if (locData.length > 2) {
    return `${locData[1].trim()}, ${locData[2].trim()}`
  }
  return locData[1] ? locData[1].trim() : ''
}

/**
 * Returns string value for the last 5 characters of a MAC
 * address OR "-" if value not provided.
 * @param {String} mac - String representation of MAC address
 * @returns {String} Last 5 of the MAC
 */
 export const shortMac = (mac, substringChars = -5) => {
  // return path and query string for router link
  return typeof mac !== 'undefined' ? mac.slice(substringChars) : ''
}

/**
 * Returns the mac Id as link or string based on the configuration
 * @param {Object} stateObj - vuex state object
 * @param {String} mac - Device MAC ID
 * @returns {String} HTML link or normal string based on configuration
 */
export const deviceLink = (stateObj, mac, type = 'tags', substringChars = -5) => {
  return stateObj.user.roles.includes('Company Administrator') || stateObj.user.permissions.includes('deviceLink') ? `<a href='/device-detail?id=${mac}&type=${type}'>${shortMac(mac, substringChars)}</a>` : shortMac(mac, substringChars)
}

/**
 * Returns formatted string for humidity with units. If value is < 15 will return
 * string '< 15 %'
 * @param {Number} humid - Humidity value
 * @returns {String} Humidity value as string with units indication (%)
 *
 */
export const formatHumidity = (humid) => {
  if (!humid) {
    return '-'
  } else if (humid < 15) {
    return '<15 %'
  }
  return `${humid} %`
}

/**
 * Returns formatted string for temperature with units.
 * @param {Number} temperature - Temperature value
 * @returns {String} Temperature value as string with temperature unit indication (&deg;F)
 * @todo - deal wtih centigrade temperatures - convert and units
 */
export const formatTemperature = (temperature) => {
  const localStorageState = JSON.parse(localStorage.getItem('intelyt'))
  // If state value not refreshed, then refrest it to present state(Only for formatter.js)
  if (state !== null && localStorageState.user.temperatureUnit !== state.user.temperatureUnit) {
    state = localStorageState
  }
  // return temperature value based on the user preference
  // console.log('formatTemperature state', state, temperature)
  if (temperature) {
    const temperatureUnit = state.user.temperatureUnit === 'Celsius' ? ' \xB0C' : ' \xB0F'
    return temperature + temperatureUnit
  }
  return '-'
}

/**
 * Returns formatted string that represents icon for status of device.<br>
 * Returns "-" if input is undefined
 * @param {String} status - Status string
 * @returns {String} Formatted string representing icon.
 */
export const formatStatus = (status) => {
  // return path and query string for router link
  let iconFrag = 'cancel'
  let iconcolor = 'red'
  if (!status) {
    iconFrag = 'cancel'
    iconcolor = 'red'
  } else if (status === 'PROVISION_NONE') {
    iconFrag = 'circle_stroke'
    iconcolor = 'green'
  } else if (status === 'PROVISION_IN_USE') {
    iconFrag = 'check'
    iconcolor = 'green'
  }

  const icons = {
    name: iconFrag,
    color: iconcolor
  }
  return generateIconSVG(state, icons)
}

/**
 * Returns formatted string that represents icon for last message.<br>
 * Returns "-" if input is undefined
 * @param {Moment Object} updateTime - Time of last update
 * @returns {String} Formatted string representing icon.
 */
export const lastUpdateIcon = (updateTime) => {
  // return path and query string for router link
  if (!updateTime) {
    return '-'
  }
  const timeNow = moment()
  const diff = timeNow.diff(updateTime, 'minutes')
  let iconFrag = 'alert_clock'
  let iconcolor = 'red'
  if (diff < 240) {
    iconFrag = 'clock'
    iconcolor = 'green'
  } else if (diff < 720) {
    iconFrag = 'clock_fast'
    iconcolor = 'yellow'
  } else {
    iconFrag = 'alert_clock'
    iconcolor = 'red'
  }

  const icons = {
    name: iconFrag,
    color: iconcolor
  }
  return generateIconSVG(state, icons)
}

/**
 * Returns formatted string that represents icon for last time asset was updated.<br>
 * Returns "-" if input is undefined
 * @param {Moment Object} updateTime - Time of last update
 * @returns {String} Formatted string representing icon.
 */
export const lastAssetUpdateIcon = (updateTime) => {
  // return path and query string for router link
  if (!updateTime) {
    return '-'
  }
  // If the state is null/not available the get the state from localstorage
  if (!state || state === null) state = getState()
  const timeNow = moment.utc()
  const diff = timeNow.diff(updateTime, 'minutes')
  let iconFrag = 'alert_clock'
  let iconcolor = 'red'
  if (diff < 1440) {
    iconFrag = 'clock'
    iconcolor = 'green'
  } else if (diff < 4320) {
    iconFrag = 'clock_fast'
    iconcolor = 'yellow'
  }

  const icons = {
    name: iconFrag,
    color: iconcolor,
    size: 25
  }
  return generateIconSVG(state, icons)
}

/**
 * Returns string value for finding and formatting humidity
 * readings from statusList.
 * @param {Moment Object} item - StatusList string
 * @returns {String} Format value of humidity rounded to one decimal place.
 */
export const parseHumidity = (item) => {
  let humid = '-'
  const humidReg = /Humidity:(.+?),/i
  if (humidReg.test(item)) {
    const humidData = humidReg.exec(item)
    // humid = humidData[1] ? `${parseFloat(humidData[1]).toFixed(1)} %` : '-'
    humid = humidData[1] ? formatHumidity(parseFloat(humidData[1]).toFixed(1)) : '-'
  }
  return humid
}

/**
 * Returns string value for finding and formatting temperature
 * readings from statusList.
 * @param {Moment Object} item - StatusList string
 * @returns {String} Format value of humidity rounded to one decimal place.
 */
export const parseTemperature = (item, exportTemp = false) => {
  let temp = '-'
  const localStorageState = JSON.parse(localStorage.getItem('intelyt'))
  // If state value not refreshed, then refrest it to present state(Only for formatter.js)
  if (state !== null && localStorageState.user.temperatureUnit !== state.user.temperatureUnit) {
    state = localStorageState
  }
  try {
    const tempReg = /Temperature:(.+?),/i
    if (tempReg.test(item)) {
      const tempData = tempReg.exec(item)
      let temperature = state.user.temperatureUnit === 'Celsius' ? (tempData[1] - 32) * 5 / 9 : tempData[1]
      temperature = parseFloat(temperature).toFixed(1)
      if (exportTemp) {
        return temperature
      }
      // console.log('parseTemperature', tempData[1], formatTemperature(tempData[1]), temperature, state.user.temperatureUnit)
      temp = formatTemperature(temperature)
    }
    return temp
  } catch (err) {
    console.log('parseTemperature err', err)
    return temp
  }
}

/**
 * Returns string value for relative date formatting.
 * @param {Moment Object} dateObj - Status numeric value
 * @returns {String} Format value string
 */
export const relativeDate = (dateObj) => {
  // If state is null or not defined, then load the state from localstorage
  if (typeof state === 'undefined' || state === null) {
    state = localStorage.getItem('intelyt')
    state = JSON.parse(state)
  }
  // Get the calendar date format values from configuration
  const calFmt = state.configuration.calendarDateFmt

  const tmzn = state.user.timezone ? state.user.timezone : moment.tz.guess()
  return moment(dateObj).tz(tmzn).calendar(null, calFmt)
}

export const securityIcon = (secst = true) => {
  // return path and query string for router link
  if (typeof secst === 'undefined' || secst === '') {
    return '-'
  }
  let iconFrag = 'merge'
  let iconcolor = 'green'
  if (!secst) {
    iconFrag = 'split'
    iconcolor = 'red'
  }
  const icons = {
    name: iconFrag,
    color: iconcolor
  }
  return generateIconSVG(state, icons)
}

export const shipIcons = (statestatus = '') => {
  // return path and query string for router link
  if (typeof statestatus === 'undefined' || statestatus === '' || statestatus.length === 0) {
    return '-'
  }
  if (typeof state === 'undefined' || state === null) state = getState()
  const alertEvents = state.configuration.alertEvent
  // Loop through the each item and prepare icon obj and get the icon content
  const mapFn = function (alert) {
    const alertConfig = alertEvents[alert]
    const icn = {name: alertConfig.icon}
    // If icon color config found, use it. Else the default color will be applied
    if (alertConfig.color) icn.color = alertConfig.color
    return `<abbr title="${alertConfig.icon}">${generateIconSVG(state, icn)}</abbr>&nbsp;`
  }
  // Filter the alert events, which is not available in config and alert config doesn't have icon name
  const icons = statestatus.filter(alert => alertEvents[alert] && alertEvents[alert].icon).map(mapFn)
  return icons.join('')
}

/**
 * Returns string value of formatted date and time.
 * @param {Moment Object} date - Input Date
 * @param {Moment Object} frmt - Optional format string
 * @returns {String} Date value in "M-DD-YY" format if default.
 * Format of frmt input otherwise.
 */
 export const shortDate = (date, frmt = 'M-DD-YY') => {
  const timezone = localStorage.getItem('timezone')
  // return path and query string for router link
  const tmzn = timezone ? timezone : moment.tz.guess()
  // const tmzn = moment.tz.guess()
  return !date ? '-' : moment(date).tz(tmzn).format(frmt)
}

/**
 * Returns string value of status based on numerical input.
 * Allows for error if status is not defined.
 * @param {Number} statusValue - Status numeric value
 * @returns {String} Status value string based on configuration
 */
export const shippingStateValue = (statusValue) => {
  // If state is null or not defined, then load the state from localstorage
  if (typeof state === 'undefined' || state === null) {
    state = localStorage.getItem('intelyt')
    state = JSON.parse(state)
  }
  const shippingStateVal = state.configuration.shippingState
  return !statusValue ? '-' : shippingStateVal[statusValue]
}

/**
 * Check each every object format
 * Return title and format value
 * @param {Object} stateObj - vuex state object
 * @param {Object} pageConfig - Heading or details object of page configuration
 * @param {Object} pageData - Value of shipment or asset
 */
export const astShpmntDataFormat = (stateObj, pageConfig, pageData) => {
  const shortDateFmt = getDateFormat('short')
  const lngDateFmt = getDateFormat()
  const dateFormat = {
    'date-short': shortDateFmt,
    'date-full': `${lngDateFmt} HH:mm z`
  }
  const tmzn = stateObj.user.timezone ? stateObj.user.timezone : moment.tz.guess()
  /**
   *
   * @param {Object} data - Given pageConfig Object item
   * @returns {Array} - Returns the final load with formatted output
   */
  // skipCq JS-0044: Skip Cyclomatic complexity more than allowed
  const mapFn = data => {
    let astValue = pageData[data.colId]
    // If max length configuration found for the item, them reduce the char length based on config.
    if (data.maxLen && astValue.length > data.maxLen) {
      astValue = `${astValue.substring(0, data.maxLen)}...`
    }
    switch (data.format) {
      case 'asset-link':
        astValue = `<a href="${data.target}?id=${pageData[data.colId]}" target="_blank">${pageData[data.txtFrom]}</a>`
        break
      case 'blank-link':
        astValue = `<a href="javascript:void(0)" onClick="window.open('${pageData[data.colId]}', '_blank', 'width=800,height=800', true)" target="${data.target}">${pageData[data.txtFrom]}</a>`
        break
      case 'date-full':
      case 'date-short':
        astValue = shortDate(pageData[data.colId], dateFormat[data.format])
        break
      case 'date-ago':
        astValue = moment(pageData[data.colId]).tz(tmzn).fromNow()
        break
      case 'date-to':
        astValue = moment(pageData[data.colId]).to(pageData[data.toDate], true)
        break
      case 'date-now':
        astValue = moment(pageData[data.colId]).toNow(true)
        break
      case 'device-link':
        astValue = stateObj.user.roles.includes('Company Administrator') || stateObj.user.permissions.includes('deviceLink') ? `<a href='/device-detail?id=${pageData[data.colId]}&type=${pageData.deviceType}'>${shortMac(pageData[data.colId])}</a>` : shortMac(pageData[data.colId])
        break
      case 'device-asset-link':
        astValue = `<a href="${pageData[data.colId]}" target="${data.target}">${pageData[data.txtFrom]}</a>`
        break
      case 'hex':
        astValue = parseInt(pageData[data.colId]).toString(16).toUpperCase()
        break
      case 'normal-link':
        astValue = `<a href="${pageData[data.colId]}" target="${data.target}">${pageData[data.txtFrom]}</a>`
        break
      case 'shipment-link': {
        const linkText = pageData[data.txtFrom] ? pageData[data.txtFrom].substring(0, 8) : '-'
        astValue = `<a href="${data.target}?id=${pageData[data.colId]}">${linkText}</a>`
        break
      }
      case 'short-mac':
        astValue = shortMac(pageData[data.colId])
        break
      case 'shipment-page-device-link':
        astValue = stateObj.user.roles.includes('Company Administrator') || stateObj.user.permissions.includes('deviceLink') ? `<a href="/device-detail?id=${pageData.macId}&type=${pageData.deviceType}">${pageData[data.colId]}</a>` : pageData[data.colId]
        break
      default:
        break
    }
    // lastCommsHours validation, if the diff is greater than the configured value
    // then highlight the value in red color
    if (pageData.decommDate === '' && (data.colId === 'lastComms')) {
      const diff = moment().utc().diff(pageData[data.colId], 'hours')
      if (diff > stateObj.configuration.siteOptions.lastCommsHours) astValue = `<span style='color:red;font-weight:bold;'>${astValue}</span>`
    }
    astValue = astValue || '-'
    const output = {'label': data.label, 'value': astValue}
    if (data.maxLen && astValue.length > data.maxLen) {
      output.tooltipTxt = pageData[data.colId]
    }
    return output
  }
  return pageConfig.map(mapFn)
}

/**
 * Returns string value of formatted date and time.
 * @param {Moment Object} date - Input Date
 * @returns {String} Date value in "M-DD-YY hh:mm" format
 */
export const shortDateTime = (date) => {
  return shortDate(date, `${getDateFormat('short')} HH:mm`)
}

/**
 * Returns string value of formatted date (using month name) and time.
 * @param {Moment Object} date - Input Date
 * @returns {String} Date value in "DD-MMM-YY hh:mm" format
 */
export const shortDayTime = (date) => {
  return shortDate(date, `${getDateFormat()} HH:mm`)
}

/**
 * Returns string value of formatted date with configured lastCommsHours validation.
 * If the difference is greater than the configured value, must return in red color
 * @param {Object} shpmnt - Shipment Object
 * @param {Number} lastCommsHours - lastCommsHours from the page configuration
 */
export const lastCommsFmt = (shpmnt, lastCommsHours) => {
  let formattedDate = shortDate(shpmnt.lastComms, `${getDateFormat()} HH:mm`)
  // When the shipment is not decommissioned and the time
  // difference is > than the hours mentioned in the configuration
  // highlight the time in red color
  if (shpmnt.decommDate === '') {
    // const lastCommsHours = state.configuration ? state.configuration.siteOptions.lastCommsHours : 72
    const diff = moment().utc().diff(shpmnt.lastComms, 'hours')
    if (diff > lastCommsHours) formattedDate = `<span style='color:red;font-weight:bold;'>${formattedDate}</span>`
  }
  return formattedDate
}

/**
 * Returns an icon that represents an unacknowledged message.
 * @param {Boolean} mac - Boolean to indicate if unack alerts
 * should be displayed
 * @returns {String} Icon
 */
export const unackAlerts = (unack) => {
  // return path and query string for router link
  if (unack) {
    const icons = {
      name: 'alert_message',
      color: 'red'
    }
    return generateIconSVG(state, icons)
  }
  return '-'
}

/**
 * @param {String} pickItemNotes - Payload String
 * @returns {string} - Returns the provisioner name
 */
export const getProvisionerName = (pickItemNotes) => {
  let provisionerName = '-'
  const pnRegEx = /Provisioner<~>(.+?)</i
  if (pnRegEx.test(pickItemNotes)) {
    const pnData = pnRegEx.exec(pickItemNotes)
    provisionerName = pnData[1] ? pnData[1] : '-'
  }
  return provisionerName
}
/* ==================== Export Functionality ============================ */
/**
 * Get temperature value and return the format
 * @param {String} item - shipment notes
 */
/* export const exportParseTemperature = (item) => {
  let temp = '-'
  const tempReg = /Temperature:(.+?),/i
  if (tempReg.test(item)) {
    const tempData = tempReg.exec(item)
    temp = tempData[1] ? `${parseFloat(tempData[1]).toFixed(1)} F` : '-'
  }
  return temp
} */
/**
 * Convert csv cell format as general format (plain text)
 * @param {Any} value - any type of value
 */
export const csvTextFmt = (value) => {
  return `="${value}"`
}
/**
 * For CSV export replace comma to pipe symbol
 */
export const alertStringFmt = (value) => {
  let out = value
  if (Array.isArray(out)) out = out.join(out)
  return out.replace(',', ' | ')
}
