// const initialized = new Set(['alerts', 'company', 'devices', 'geofences', 'locations', 'tags', 'shipments', 'warehouses', 'weather'])

export const state = {
  // alerts: {},
  auth: {
    status: false,
    username: '',
    // company: '',
    // companyId: null,
    error: ''
  },
  cellLocations: {},
  chartClick: {},
  // chimes: {},
  // company: {},
  // company: {
  //   id: 0,
  //   name: '',
  //   logo: false,
  //   origins: [],
  //   configs: []
  // },
  configuration: {},
  form: {
    new: {},
    edits: {}
  },
  chargerGatewayNames: {},
  fontSVG: {},
  filterList: {},
  // devices: {},
  // deviceIscan: {},
  // landingPage: {
  //   Cocoon: 'yardparts-dashboard',
  //   Demo: '/'
  // },
  // gateways: {},
  // geofences: {},
  /* Initialized modules for setting/resetting state - always reset company */
  initialized: ['company'],
  initShpmRepoPageLoaded: false,
  initShpmActivePageLoaded: false,
  lastRequestedPage: '',
  // locations: {},
  menu: [],
  messages: {},
  // metrics: {},
  // metrics: {
  //   totalShipments: 150,
  //   intactShipments: 140,
  //   onTimeDepartures: 23,
  //   onTimeArrivals: 45,
  //   pendingDepartures: 2
  // },
  lastRecords: {},
  notifications: {},
  server: {},
  shockEvent: {X: [], Y: [], Z: [], RMS: []},
  // shipments: {},
  stateDefs: {
    asset: {
      0: 'Saved',
      1: 'Created',
      2: 'Activated',
      3: 'Monitored',
      4: 'Staged',
      5: 'Shipped',
      6: 'Decommissioned',
      7: 'Unmonitored'
    },
    commission: {
      1: 'Created',
      2: 'Active',
      3: 'Ready',
      4: 'Decommissioned'
    },
    device: {
      0: 'Available',
      2: 'In Use',
      5: 'Reposition'
    },
    onTime: {
      1: 'On Time',
      2: 'Delayed'
    },
    shipping: {
      0: 'Saved',
      1: 'Processing',
      2: 'Commissioned',
      3: 'Departed',
      4: 'Arrived',
      5: 'Decommissioning',
      6: 'Decommissioned',
      7: 'Unmonitored'
    },
    security: {
      1: 'Intact',
      2: 'Separated'
    },
    vehicle: {
      0: 'Saved',
      1: 'Commissioned',
      2: '-',
      3: 'Monitored',
      4: '-',
      5: '-',
      6: 'Decommissioned',
      7: '-'
    }
  },
  // tags: {},
  user: {
    emailAddress: '',
    fullName: '',
    loginName: '',
    notifyList: ''
  },
  // warehouses: {},
  // weather: {},
  yardparts: {}
}
