<template>
  <div>
    <intelyt-card>
      <template slot="header">Devices</template>
      <template slot="controls">
        <div class="field has-addons" style="width:100%;">
          <p class="control is-expanded">
            <span style="display:flex; align-content:center;">
                <label style="padding-right:10px;">
                  <input type="radio" value="iChime" v-model="deviceType" /> iChime
                </label>
                <label style="padding-right:10px;">
                  <input type="radio" value="iTag" v-model="deviceType" /> iTag
                 </label>
                <label style="min-width:150px;">
                <input style="width:100%;" type="search" placeholder="Last 5 Digits of Device ID(18_EA)" v-model="mac">
              </label>
            </span>
          </p>&nbsp;
          <span class="icon" v-show="macIsValid()">
            <base-icon name="search" @click="showDeviceStatus(mac)" v-tooltip="'Filter By Shipment Id'" />
          </span>
        </div>
      </template>
        <nav class="level">
        <div class="level-item has-text-centered" >
          <div>
            <p class="heading">Gateways [Total: {{gateTotal}}]</p>
            <div class="dummyInline" >
              <bar-chart
                  :initialValues="gateCounts"
                  scale="10"
                  v-if="gateCounts.length > 0"
                ></bar-chart>
            </div>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Chimes [Total: {{chmTagTotal('2')}}]</p>
            <div class="dummyInline">
              <bar-chart
                  :initialValues="chimeCounts"
                  scale="10"
                  v-if="chmTagTotal('2') > 0"
                ></bar-chart>
            </div>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Tags [Total: {{chmTagTotal('1')}}]</p>
            <div class="dummyInline">
              <bar-chart
                  :initialValues="tagCounts"
                  scale="10"
                  v-if="chmTagTotal('1') > 0"
                ></bar-chart>
            </div>
          </div>
        </div>
      </nav>
    </intelyt-card>
    <show-message-modal
      :message="deviceStatusMessage.message"
      :title="deviceStatusMessage.title"
      :isActive="modalIsActive.showMessage"
      :isHtml="true"
      v-on:close="closeModal()"
    >
    </show-message-modal>
  </div>
</template>

<script>
import BarChart from '@/components/BarChart'
import { mapActions } from 'vuex'
import moment from 'moment-timezone'

export default {
  components: {
    BarChart
  },
  computed: {
    chimeCounts: function () {
      const mapFn = chm => {
        return {label: chm[1][0], value: chm[1][1]}
      }
      if (!this.$store.state.devices.summary['2']) return []
      const chimeList = Object.entries(this.$store.state.devices.summary['2']) || {}
      const chmVals = Object.entries(chimeList).map(mapFn)
      return chmVals
    },
    gateCounts: function () {
      const allGates = Object.values(this.$store.state.devices.gateways.all) || []
      const offline = allGates.filter(this.offlineFltr).length || 0
      let gateVals = []
      if (allGates.length > 0) {
        gateVals = [
          {label: 'Online', value: allGates.length - offline, color: 'green'},
          {label: 'Offline', value: offline, color: 'red'}
        ]
      }
      return gateVals
    },
    gateTotal: function () {
      return Object.values(this.$store.state.devices.gateways.all).length
    },
    tagCounts: function () {
      const mapFn = chm => {
        return {label: chm[1][0], value: chm[1][1]}
      }
      if (this.$store.state.devices.summary['1']) {
        const tagList = Object.entries(this.$store.state.devices.summary['1']) || {}
        const tagVals = Object.entries(tagList).map(mapFn)
        return tagVals
      } else {
        return []
      }
    }
  },
  created () {
    // initilize gateways to show online/offline status
    this.$store.dispatch('devices/initialize', {deviceType: 'gateways'})
    // fetch device counts for tags, chimes and gateways for bar charts
    this.$store.dispatch('devices/fetchSummaryCounts')
  },
  data () {
    return {
      deviceType: 'iChime',
      deviceStatusMessage: {
        message: 'Default Message',
        title: 'Default Title'
      },
      mac: '',
      modalIsActive: {
        showMessage: false
      }
    }
  },
  methods: {
    macIsValid: function () {
      this.mac = this.mac.trim()
      return this.mac.length === 4 || this.mac.length === 5
    },
    chmTagTotal (type) {
      return this.$store.state.devices.summary[type] ? Object.values(this.$store.state.devices.summary[type])
        .reduce((tot, c) => { return tot + parseInt(c) }, 0) : 0
    },
    closeModal () {
      this.modalIsActive['showMessage'] = false
      this.deviceStatusMessage.message = ''
      this.mac = ''
    },
    inventoryParent (res) {
      if (typeof this.$store.state.chargerGatewayNames[res.inventoryParent] !== 'undefined') {
        const getParent = this.$store.state.chargerGatewayNames[res.inventoryParent]
        return `${getParent.deviceTypeStr} ${res.inventoryParent} (${getParent.location})`
      }
      return res.inventoryParent
    },
    offlineFltr (x) {
      const delay = moment().diff(x.lastMessageGateway, 'minutes')
      return isNaN(delay) ? true : delay > 360
    },
    showDeviceStatus (mac) {
      const devicePrefix = this.$store.state.configuration.deviceConfig[`${this.deviceType}Prefix`]
      mac = mac.includes('_') && mac.length === 5 ? mac : `${mac.substring(0, 2)}_${mac.substring(2, 4)}`
      mac = mac.replace('__', '_').toUpperCase()
      if (mac.length !== 5) {
        this.deviceStatusMessage.title = `Device Status (${devicePrefix}_${mac})\n`
        this.deviceStatusMessage.message = `Given mac is not in the correct format: ${mac}`
        this.modalIsActive['showMessage'] = true
        return false
      }
      const fullMacId = `00_1B_AD_00_${devicePrefix}_${mac}`
      this.deviceStatusMessage.title = `Device Status (${devicePrefix}_${mac})\n`
      this.deviceStatusMessage.message = `Fetching status for device with id: ${fullMacId}`
      this.modalIsActive['showMessage'] = true
      this['devices/getStatus'](fullMacId).then(response => {
        const batteryLevel = response.lastBatteryLevel ? parseFloat(response.lastBatteryLevel).toFixed(2) : 0.0
        const resp = `<table>
          <tr><td>Name:</td><td> ${response.name}</td></tr>
          <tr><td>Status:</td> <td>${response.status}</td></tr>
          <tr><td>Last Shipment:</td> <td>${(response.shipmentId && response.shipType) ? response.shipmentId + ' (' + response.shipType + ')' : '-'}</td></tr>
          <tr><td>Last Battery Level:</td> <td>${batteryLevel}</td></tr>
          <tr><td>Parent:</td> <td>${this.inventoryParent(response)}</td></tr>
          <tr><td>Inventory Status:</td> <td>${response.inventoryStatus}</td></tr>
          <tr><td>Last Update:</td> <td>${response.inventorySource}</td></tr>
        </table>`
        this.deviceStatusMessage.message = resp
        this.mac = ''
      }).catch(e => {
        this.deviceStatusMessage.message = `<p class='text-danger'>${e.detail}</p>`
      })
    },
    ...mapActions([
      'devices/getStatus'
    ])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .text-danger {
    color: #dc3545;
  }
</style>
