<template class="field">
  <div class="field" v-show="!hidden">
    <!-- <div class="field" v-show="!hidden"> -->
    <label class="label">{{label}}  <base-icon name="information" size="15" v-if="tooltip && tooltip.length > 0" v-tooltip="tooltip" /></label>
      <ValidationProvider :name="label" :rules="formRules" v-slot="{ errors }">
        <div class="select">
          <select v-model="selectedValue" :name="label">
            <option value="">-- Select {{label}} --</option>
            <template v-for="(opt, idx) in options">
              <option :key="`${idx}-${opt.value}`" :value="opt.value || opt.name">{{opt.name}}</option>
            </template>
          </select>
        </div>
        <span class="help is-danger">{{ errors[0] }}</span>
      </ValidationProvider>
  </div>
</template>

<script>
import { getQueryProvider } from '../../store/providers'
import { getDynamicQueryParams } from '../../store/helpers'
export default {
  name: 'SimpleSelect',
  computed: {
    options () {
      return this.selectOptions
    }
  },
  data () {
    return {
      selectedValue: '',
      selectOptions: [],
      hidden: false
    }
  },
  methods: {
    checkHidden () {
      // check if default value exists and options.length === 1
      if (!this.default && this.options && this.options.length === 1) {
        /* If only one choice, select it by default and hide field. */
        this.selectedValue = this.options[0].value
        this.hidden = true
      }
    },
    getOptionsFromQuery (source) {
      // Get the field options from the query
      const defaults = this.defaults
      const sourceParams = source.params ? source.params : []
      const params = sourceParams.length > 0 ? getDynamicQueryParams(sourceParams, defaults) : []
      const payload = {
        query: source.query,
        params,
      }
      // Get the query results and return the option list
      return getQueryProvider(this.$store.state, payload)
    }
  },
  props: {
    default: {
      type: [Number, String],
      default: ''
    },
    defaults: {
      type: Object,
      default: () => {}
    },
    formRules: Object,
    mode: String,
    label: String,
    fieldConfig: {
      type: Object,
      default: () => {}
    },
    reset: Boolean,
    field: String,
    tooltip: {
      type: String,
      default: ''
    }
  },
  watch: {
    async reset (val) {
      if (val) {
        /* Setup the fields on reset.....
         * If reset is true, then set the value as blank
         * If the default value is passed, then set the value
         * if the list has only one entry use it and hide the select box.
         */
        // Get the field option from source config
        const source = this.fieldConfig.source
        let sourceOptions = []
        // If the source is string type, then get the options from the state
        if (source && typeof source === 'string') {
          sourceOptions = this.$store.state
          source.split('.').forEach((stateObj) => {
            sourceOptions = sourceOptions[stateObj]
          })
          // If the source is an object, the get the options from the configs source
        } else if (typeof source === 'object') {
          // If the source type is query, then call the query to get the options
          if (source.type === 'query') {
            sourceOptions = await this.getOptionsFromQuery(source)
            sourceOptions = Object.values(sourceOptions.data)
          }
        }
        this.selectOptions = sourceOptions
        // If the default value is available, then apply it
        // skipcq:JS-W1043 - Skip redundant literal in a logical expression
        this.selectedValue = this.default || ''
        this.checkHidden()
      }
    },
    selectedValue: {
      handler (val) {
        /* Watch selectedValue and update the new shipment object if it changes */
        if (this.field) this.$store.commit('SET_FORM_FIELD', {key: this.field, value: val, mode: this.mode})
      },
      deep: true
    }
  }
}
</script>
