<template>
  <div>
    <div class="field">
      <label class="label">{{label}} <base-icon name="information" size="15" v-if="tooltip && tooltip.length > 0" v-tooltip="tooltip" /></label>
      <div class="control">
        <ValidationProvider :name="label" :rules="formRules"  v-slot="{ errors }">
          <input class="input" :disabled="disabled" :class="{'input': true, 'is-danger': errors[0] }" type="text" v-model="textVal" name="id">
          <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextBox',
  data () {
    return {
      textVal: ''
    }
  },
  props: {
    default: String,
    disabled: {
      type: Boolean,
      default: false
    },
    mode: String,
    label: String,
    field: String,
    reset: Boolean,
    formRules: Object,
    tooltip: {
      type: String,
      default: ''
    }
  },
  watch: {
    reset (val) {
      if (val) {
        this.textVal = ''
        // If reset is true, then set the value as blank
        // If the default value is passed, then set the value
        // skipcq:JS-W1043 - Skip redundant literal in a logical expression
        this.textVal = this.default || ''
      }
    },
    'textVal': {
      handler (val) {
        if (this.field) this.$store.commit('SET_FORM_FIELD', {key: this.field, value: val, mode: this.mode})
      },
      deep: true
    }
  }
}
</script>
