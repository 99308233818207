<template>
  <intelyt-modal
    :isActive="isActive"
    :title="title"
    :width=1000
    v-on:close="close()"
  >
    <section class="modal-card-body">
      <div class="content">
        <div v-if="reportSearch === true">Generating Report...</div>
        <div v-else>
          <span v-if="dataAvailable">
            <table class="table">
              <thead>
                <tr>
                  <th v-for="column in columns" :key="column">{{column}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in reportData" :key="index">
                  <td v-for="(entry, indx) in Object.values(row)" :key="indx">{{entry}}</td>
                </tr>
              </tbody>
            </table>
          </span>
          <span v-else>{{noDataMessage}}</span>
        </div>
      </div>
    </section>
    <template slot="footer">
      <a class="button is-primary" @click="download()" v-if="dataAvailable">Download</a>
      <a class="button" @click="close()">Close</a>
    </template>
  </intelyt-modal>
</template>

<script>
import IntelytModal from '@/components/IntelytModal'
import { mapActions } from 'vuex'
import { exportAsCSV } from '@/store/helpers'

export default {
  components: {
    IntelytModal
  },
  computed: {
    columns: function () {
      return this.reportData[0] ? Object.keys(this.reportData[0]) : []
    },
    dataAvailable: function () {
      return Object.keys(this.reportData).length > 0
    }
  },
  created () {},
  data () {
    return {
      title: 'Report Results',
      noDataMessage: 'No data available for selected report.',
      options: {}
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    download_csv (csvFileName) {
      let csvData = []
      Object.values(this.reportData).forEach(function (row, index) {
        csvData[index] = Object.values(row).map(string => { return !string || string === null ? '' : string.replace(/"/g, '""').replace(/,/g, ';').replace(/\n{1,2}/g, ' | ') }).join(',')
      })
      // console.log('report result modal', csvData)
      exportAsCSV(this.columns, csvData, csvFileName)
    },
    download () {
      this.download_csv(this.fileName)
      // console.log('Download')
      this.$emit('close')
    },
    // getColumns () {
    //   // console.log('report data', this.reportData[0])
    //   return Object.keys(this.reportData[0]).map(ky => { return {name: ky, id: ky} })
    // },
    ...mapActions([])
  },
  props: ['isActive', 'reportData', 'fileName', 'reportSearch']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
