import {
  createTableEntryProvider,
  getTableEntriesProvider,
  getQueryProvider,
  updateTableEntryProvider
} from '../providers'
// import {round} from '@/store/helpers.js'

import {isJSON} from '@/store/helpers.js'

import Vue from 'vue'
import moment from 'moment-timezone'

/**
 * Sort the alerts using the time valueS
 * @param {String} field - Field value to sort the alert
 * @returns
 */
const sortAlerts = (field) => (start, end) => {
  return moment(end[field]).diff(start[field], 'seconds')
}

/**
 * Set the default alert object
 * @returns
 */
const getDefaultState = () => {
  return {
    all: {},
    counts: {},
    ids: {},
    lastUpdate: moment().subtract(3, 'months')
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  // Prepare the alert code object from the alert event configurations
  getAlertCodes: (state, getters, rootState) => () => {
    const alertEvents = rootState.configuration.alertEvent
    const alertCodes = {}
    for (const [event, alertObj] of Object.entries(alertEvents)) {
      // if (alertObj.hasOwnProperty('alertCode')) {
      if (Object.prototype.hasOwnProperty.call(alertObj, 'alertCode')) {
        alertCodes[alertObj.alertCode] = event
      }
    }
    // console.log('alertCodes', alertCodes)
    return alertCodes
  },
  getAlertsByDate: (state) => (range, macId = null) => {
    /* let lower = moment(range[0], 'M-DD-YY').format('x')
    let upper = moment(range[1], 'M-DD-YY').endOf('day').format('x') */
    const lower = range[0]
    const upper = range[1]
    const filteredAlerts = Object.values(state.all).filter((alert) => {
      const timeValid = alert.time >= lower && alert.time <= upper
      let macValid = true
      if (macId) {
        macValid = alert.macId === macId
      }
      // console.log('Alert Data: ', alert.macId, macId, macValid)
      return macValid && timeValid
      // return macValid
    })
    return filteredAlerts.reverse()
  },
  getAlertsByGuid: (state) => (guid, level = ['0', '1', '2']) => {
    /**
     * Filter the alerts using the guid and level passed in argument
     * @param {Object} alrt - Contains the alert details
     * @returns
     */
    const fltr = (alrt) => {
      return alrt.guid === guid && level.includes(alrt.level)
    }
    // let alerts = Object.values(state.alerts).filter(fltr)
    const alerts = Object.values(state.all).filter(fltr)
    alerts.sort(sortAlerts('createDate'))
    return alerts
  },
  /**
   * Returns an array of data containing all alert objects of a single type for a single shipment
   * @returns {list} List of alerts with appropriate formatting.
   */
  getAlertEvents: (state) => (guid, event = 221) => {
    const filteredAlerts = Object.values(state.all).filter(x => x.guid === guid && parseInt(x.event) === event)
    // return empty list for any events that aren't shock...nothing else supported yet.....
    if (event !== 221) return []
    // const shockTilt = this.$store.state.shipments.all[this.$route.query.id].tiltShockData || []
    // function reducer (acc, cur) {
    //   // do nothing if not a shock event (i.e. shockType is 'OK')
    //   if (cur.shockType.length < 4) return acc
    //   if (!acc[cur.shockCount]) {
    //     acc[cur.shockCount] = cur
    //   }
    //   if (cur.shockType === 'SHOCKED') acc[cur.shockCount].raw = cur.shockMagnitude
    //   if (cur.shockType === 'SHOCKEDF') acc[cur.shockCount].filtered = cur.shockMagnitude
    //   return acc
    // }
    function mapFn (alert) {
      const raw = alert.stateInt1 || 0
      const filtered = alert.stateInt3 || 0
      const position = {lat: parseFloat(alert.latitude), lng: parseFloat(alert.longitude)}
      const shockEvent = {
        acceleration: alert.acceleration,
        timestamp: Number(moment.utc(alert.alertTime1).format('x')),
        datetime: alert.time,
        enabled: parseInt(alert.enabled),
        entryId: alert.entryId,
        latitude: alert.latitude,
        longitude: alert.longitude,
        shockMagnitude: Math.max(raw, filtered),
        raw,
        filtered,
        position,
        shockCount: alert.stateInt2
      }
      return shockEvent
    }
    // const shockRecords = shockTilt.reduce(reducer, {})
    const shockRecords = filteredAlerts.map(mapFn)
    // console.log('reducer: ', shockRecords, shockTilt)
    return shockRecords
  },
  /**
   * Returns an array of data containing all alerts of a single type for a single shipment
   * @returns {list} List of alerts with timestamp and value
   */
  getAlertTimeseries: (state) => (guid, event = 221) => {
    const filteredAlerts = Object.values(state.all).filter(x => x.guid === guid && parseInt(x.event) === event)
    return filteredAlerts.map(d => [Number(moment.utc(d.alertTime1).format('x')), d.stateInt1, parseInt(d.entryId)])
  },
  getUnacknowledgedAlerts: (state) => () => {
    const alerts = Object.values(state.all).filter(alrt => !alrt.ackTime)
    alerts.sort(sortAlerts('lastUpdate'))
    return alerts
  },
  /**
   * Creates a set containing the guids for all shipments that have
   * an unacknowledged alert.
   * @returns {Set} Set of guids with shipments that have unack alerts
   */
  getUnacknowledgedShipments: (state) => () => {
    const alerts = Object.values(state.all)
      .filter(alrt => !alrt.ackTime)
      .map(alrt => alrt.guid)
    const shipSet = new Set(alerts)
    return shipSet
  }
}

// actions
const actions = {
  /**
   * Creates an alert from scratch using an insert into shippingAlertTable via
   * {@link createTableEntryProvider} and state update with {@link setAlert}.
   * @param {object} state - Vuex state
   * @param {object} alert - alert object that includes all required parameters
   * to create new alert.
   * @returns {Promise} Promise object represents the return data value from the
   * create table entry function
   */
  createAlert ({commit, rootState}, alert) {
    const tableName = 'shippingAlertLog'
    alert.ackTime = moment.utc().format('YYYY-MM-DD HH:mm:ss')
    alert.alertTime1 = moment.utc().format('YYYY-MM-DD HH:mm:ss')
    alert.lastUpdate = moment.utc().format('YYYY-MM-DD HH:mm:ss')
    alert.createDate = moment.utc().format('YYYY-MM-DD HH:mm:ss')
    const payload = {
      ackBy: { type: 'string', value: alert.ackBy },
      ackTime: { type: 'date_time', value: alert.ackTime },
      alertEvent: {
        type: 'integer',
        value: alert.alertEvent
      },
      alertLevel: {
        type: 'integer',
        value: alert.alertLevel
      },
      alertSource: {
        type: 'string',
        value: alert.alertSource
      },
      alertTime1: {
        type: 'date_time',
        value: alert.alertTime1
      },
      companyId: {
        type: 'integer',
        value: alert.companyId
      },
      createdBy: {
        type: 'string',
        value: alert.createdBy
      },
      data: {
        type: 'string',
        value: alert.data
      },
      enabled: {
        type: 'integer',
        value: alert.enabled
      },
      guid: {
        type: 'string',
        value: alert.guid
      },
      lastUpdate: {
        type: 'date_time',
        value: alert.lastUpdate
      },
      latitude: {
        type: 'double',
        value: alert.latitude
      },
      longitude: {
        type: 'double',
        value: alert.longitude
      },
      message: {
        type: 'string',
        value: alert.message
      },
      subject: {
        type: 'string',
        value: alert.subject
      }
    }
    return new Promise((resolve, reject) => {
      createTableEntryProvider(rootState, tableName, payload)
        .then(response => {
          alert.entryId = response.data.entryId
          commit('SET_ALERT', alert)
          //         if (alert.sendEmail) {
          //           window.location.assign(`mailto:?subject=${alert.subject}&body=${alert.message}`)
          //         }
          resolve(response.data)
        })
        .catch(e => {
          // skipcq: JS-0002.  Allow console.error
          console.error('createAlert ERROR: ', e)
          reject(e)
        })
    })
  },
  /**
   * Gets alerts for a single shipment when that shipment is accessed by the user
   * and updates state with the alert values.  Will update again each time the
   * shipment is accessed.<br>
   * Uses {@link setAlert} for processing and addition to the state <br>
   * @param {object} store
   * @param {object} store.commit  - Vuex store commit object
   * @param {object} store.state  - Vuex store state object
   * @param {object} guid  - Guid of shipment which has alerts to add
   */
  fetchAlerts ({commit, rootState}, {guid = '', macId = '', dateLimit = false} = {}) {
    // add the module in the initialized modules array in state to reset on logging out
    // macId
    if (!rootState.initialized.includes('alerts')) {
      commit('setInitializedModule', 'alerts', { root: true })
    }
    // console.log('--- call fetchAlerts --- ', guid)
    let whereParams = ''
    if (guid !== '') {
      whereParams = `guid = "${guid}"`
    }
    if (macId !== '') {
      whereParams = `macId = "${macId}"`
    }
    const cutoffDate = dateLimit ? ` AND alertTime1 > "${dateLimit}"` : ''
    whereParams = `${whereParams} ${cutoffDate} AND enabled < 2 `
    const payload = {
      tableName: 'shippingAlertLog',
      where: whereParams,
      sort: 'entryId DESC'
    }
    getTableEntriesProvider(rootState, payload)
      .then(response => {
        for (const key in Object.values(response.data)) {
          if (Object.prototype.hasOwnProperty.call(response.data, key)) {
            commit('SET_ALERT', response.data[key])
          }
        }
      })
      .catch(e => {
        // skipcq: JS-0002.  Allow console.error
        console.error('fetchAlerts ERROR: ', e)
      })
  },
  /**
   * Gathers all of the unacknowledged alerts greater than lastRecords date and
   * and adds alert objects to the Vuex store.<br>
   * Retrives information using {@link getTableEntriesProvider}.<br>
   * As part of the process it will reset the lastRecords value for alerts to the
   * greatest value encountered.<br>
   * Query will filter out previosly updated alrets by using createDate and using
   * and resetting the lastRecords value
   * Performs update using {@link setAlert} mutation.
   * @param {object} store
   * @param {object} store.commit  - Vuex store commit object
   * @param {object} store.state  - Vuex store state object
   * @returns {Promise} Promise object
   */
  initialize ({commit, state, rootState}, {force = false} = {}) {
    // Execute the function, when the state have no value or forced to initialize again
    if (!force && Object.values(state.all).length !== 0) {
      return
    }
    // add the module in the initialized modules array in state to reset on logging out
    if (!rootState.initialized.includes('alerts')) {
      commit('setInitializedModule', 'alerts', { root: true })
    }
    // console.log('--- initialize Alerts ---', state.lastUpdate)
    // let compareDate = moment().subtract(9, 'months').format('YYYY-MM-DD')
    let compareDate = state.lastUpdate
    const compDateStr = moment(compareDate).format('YYYY-MM-DD HH:mm:ss')
    const whereClause = `createDate > '${compDateStr}' AND companyId = ${rootState.company.id} AND ackTime IS NULL AND enabled < 2`
    const payload = {
      max: 10,
      tableName: 'shippingAlertLog',
      where: whereClause,
      fields: ['createDate', 'guid', 'origin', 'dest', 'macId'],
      sort: 'entryId DESC'
    }
    getTableEntriesProvider(rootState, payload)
      .then(response => {
        // console.log('ALERTSS: ', response)
        for (const key in Object.values(response.data)) {
          if (Object.prototype.hasOwnProperty.call(response.data, key)) {
            commit('SET_ALERT', response.data[key])
            compareDate = moment.max(compareDate, moment.utc(response.data[key].createDate, 'ddd MMM D HH:mm:ss YYYY'))
          }
        }
        // Reset value for lastRecords alert date & time based off max value found
        commit('SET_LAST_UPDATE', compareDate)
      })
      .catch(e => {
        // skipcq: JS-0002.  Allow console.error
        console.error('alerts initialize ERROR: ', e)
      })
  },
  /**
   * Gathers counts for all alerts (total and unacknowledged) and
   * adds alert objects to the Vuex store.<br>
   * Retrives information using {@link gettQueryProvider}.<br>
   */
  loadCounts ({ commit, rootState }) {
    // console.log('--- Alerts - loadCounts COUNTS ---')
    // get company id
    const companyIdString = String(rootState.company.id)
    const shippingStates = rootState.configuration.siteOptions.activeShipmentStates
    const queryPayloadActive = {
      query: 'activeAlerts',
      params: [companyIdString, shippingStates]
    }
    const queryPayloadUnack = {
      query: 'unacknowledgedAlerts',
      params: [companyIdString, shippingStates]
    }
    Promise.all([
      getQueryProvider(rootState, queryPayloadActive),
      getQueryProvider(rootState, queryPayloadUnack)
    ]).then((responses) => {
      // console.log('Alert Resposnes: ', responses)
      const counts = {}
      const transformActive = entry => {
        // get number from end of string 'alert###' if none, set to total
        const key = entry[0].match(/alert([0-9]+)/) || ['', 'total']
        counts[key[1]] = {tot: parseInt(entry[1]), unack: 0}
      }
      const transformUnack = entry => {
        // get number from end of string 'alert###' if none, set to total
        const key = parseInt(entry.alertEvent)
        if (key >= 200) {
          if (counts[key]) {
            counts[key].unack = parseInt(entry.count)
          } else {
            counts[key] = {tot: 0, unack: parseInt(entry.count)}
          }
        }
      }
      Object.entries(responses[0].data[0]).forEach(transformActive)
      Object.values(responses[1].data).forEach(transformUnack)
      // console.log('Transform ----: ', counts)
      commit('SET_COUNTS', counts)
    }).catch(e => {
      // skipcq: JS-0002.  Allow console.error
      console.error('loadCounts error: ', e)
    })
  },
  /**
   * Gathers counts for all alerts (total and unacknowledged) and
   * adds alert objects to the Vuex store.<br>
   * Retrives information using {@link gettQueryProvider}.<br>
   */
  loadIds ({ commit, rootState, getters }, {cfgType = 0} = {}) {
    // console.log('--- Alerts - load alert Ids ---')
    const alertCodes = getters.getAlertCodes()
    /* get company id */
    const companyIdString = String(rootState.company.id)
    /* Get the maximum number of days to look back for shipments to be used in alert counts */
    const lookbackDays = String(rootState.configuration.siteOptions.shipmentLookbackDays)
    // const shippingStates = rootState.configuration.siteOptions.activeShipmentStates
    const queryList = ['alertCountOnTime', 'alertCountSecurity', 'alertCountShipment']
    /**
     * Prepare the promise list to load the alert list
     * @param {String} qry - Query string to load
     * @returns
     */
    const promiseMapFn = qry => {
      const payload = {
        query: qry,
        params: [companyIdString, lookbackDays, String(cfgType)]
      }
      return getQueryProvider(rootState, payload)
    }
    const promiseList = queryList.map(promiseMapFn)
    // queryPayloadSecurity = {
    //   query: 'alertCountSecurity',
    //   params: [companyIdString]
    // }
    // const queryPayloadUnack = {
    //   query: 'unacknowledgedAlerts',
    //   params: [companyIdString]
    // }
    Promise.all(promiseList).then((responses) => {
      /* const alertCodes = {
        sec1: '201',
        sec2: '218',
        sec4: '208',
        sec8: '204',
        sec16: '222',
        sec32: '',
        sec64: '',
        sec128: '',
        ots1: '3',
        ots2: '206',
        ots4: '207',
        ots8: '22',
        ots16: '209',
        ots32: '',
        ots64: '',
        ots128: '',
        as1: '211',
        as2: '216',
        as4: '205',
        as8: '17',
        as16: '18',
        as32: '221',
        as64: '220',
        as128: '223'
      } */
      const reduceFn = (acc, cur) => {
        const mapFn = val => {
          // console.log('Ids: key-val', val)
          if (val[1].length > 0) return `${val[0]}:${val[1]}`
          return ''
        }
        const newEntries = Object.entries(cur[1]).map(mapFn).filter(x => x.length > 0)
        // console.log('Ids: ', newEntries)
        return acc.concat(newEntries)
      }
      // // collaspsedReponse has all entries flattened into key:value pairs with empty values removed
      // const collapsedResponse = Object.entries(responses[0].data).reduce(reduceFn, [])
      // console.log('Ids: ', collapsedResponse)
      // combine values into object
      const alertObj = {}
      for (const response of responses) {
        // console.log('Ids: response', response)
        // if response is empty, do not process further...
        if (!response) continue
        // collaspsedReponse has all entries flattened into key:value pairs with empty values removed
        const collapsedResponse = Object.entries(response.data).reduce(reduceFn, [])
        for (const item of collapsedResponse) {
          const [key, val] = item.split(':')
          const code = alertCodes[key]
          if (alertObj[code]) {
            alertObj[code] += `,${val}`
          } else {
            alertObj[code] = val
          }
        }
      }
      // console.log('Ids: result', alertObj)
      commit('SET_IDS', {cfgType, data: alertObj})
    }).catch(e => {
      // skipcq: JS-0002.  Allow console.error
      console.error('loadIds error: ', e)
    })
  },
  /**
   * Updates a single alert
   * Performs update using {@link updateTableEntryProvider} provider.
   * to {@link APPLY_ALERT_EDITS} for processing and addition to the state <br>
   * @param {object} updatePayload  - Vuex store commit object
   * @todo Pull messge field and apply updates just before re-sending to database.
   * This will ensure all of the latest updates were included and not rely on old state.
   * @todo Handle errors/ success better (message portal?)
   */
  updateAlert ({commit, rootState}, updatePayload) {
    const tableName = 'shippingAlertLog'
    const updateDate = moment.utc().format('DD-MMM-YY HH:mm')
    let message = updatePayload.existMessage
    // add new message text if provided
    if (updatePayload.newMessage) {
      const hdr = `----- ${rootState.user.fullName} - ${updateDate} -----`
      message = `${hdr}\n${updatePayload.newMessage}\n-----\n${message}`
    }
    let updateObj = {
      entryId: {
        type: 'integer',
        value: parseInt(updatePayload.entryId)
      },
      lastUpdate: {
        type: 'date_time',
        value: moment.utc().format('YYYY-MM-DD HH:mm:ss')
      },
      message: {
        type: 'string',
        value: message
      }
    }
    const ackObj = {
      ackBy: {
        type: 'string',
        value: rootState.user.fullName
      },
      ackTime: {
        type: 'date_time',
        value: moment.utc().format('YYYY-MM-DD HH:mm:ss')
      }
    }
    if (updatePayload.acknowledge) {
      updateObj = Object.assign(updateObj, ackObj)
    }
    const updateData = {
      id: updatePayload.entryId,
      data: updateObj
    }
    // send update for shippingPickList
    return new Promise((resolve, reject) => {
      updateTableEntryProvider(rootState, tableName, updateData)
        .then(() => {
          commit('APPLY_ALERT_EDITS', updateObj)
          //         if (updateObj.sendEmail) {
          //           window.location.assign(`mailto:?subject=${updatePayload.subject}&body=${updatePayload.newMessage}`)
          //         }
          const msg = `Successfully updated alert: ${updatePayload.entryId}`
          resolve(msg)
        })
        .catch(e => {
          // skipcq: JS-0002.  Allow console.error
          console.error('updateAlert ERROR: ', e)
          reject(e)
        })
    })
  }
}

// mutations
const mutations = {
  /**
   * Updates state values for single alert object
   * @param {object} state - Vuex state
   * @param {object} data - data payload
   * @param {object} data.id - Id of alert to be updated
   * @param {datetime} data.ackTime - Timestamp of update
   * @param {string} data.ackBy - Full name of user making update
   * @param {datetime} data.lastUpdate - Timestamp of update
   */
  //   ACKNOWLEDGE_ALERT (state, data) {
  //     // console.log('ack Alert: ', state.alerts[data.id])
  //     Vue.set(state.alerts[data.id], 'ackTime', data.data.ackTime.value)
  //     Vue.set(state.alerts[data.id], 'ackBy', data.data.ackBy.value)
  //     Vue.set(state.alerts[data.id], 'lastUpdate', data.data.lastUpdate.value)
  //   },
  APPLY_ALERT_EDITS (state, data) {
    const id = data.entryId.value
    Vue.set(state.all[id], 'lastUpdate', data.lastUpdate.value)
    Vue.set(state.all[id], 'message', data.message.value)
    if (data.ackBy) {
      Vue.set(state.all[id], 'ackBy', data.ackBy.value)
      Vue.set(state.all[id], 'ackTime', data.ackTime.value)
    }
  },
  /**
   * Resets object state to initial values set upon creation.
   */
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  },
  /**
   * Adds a new alert object to state
   * @param {object} state - Vuex state
   * @param {object} alert - Alert data payload
   */
  SET_ALERT (state, alert) {
    const dateFields = ['ackTime', 'alertTime1', 'alertTime2', 'alertTime3', 'createDate', 'lastUpdate']
    // validate all the date fields, and convert it to the standard date format string 'YYYY-MM-DD HH:mm:ss'
    dateFields.forEach((alrtKey) => {
      // convert the given date field to moment object to validate
      const formatDate = moment.utc(alert[alrtKey], 'ddd MMM D HH:mm:ss YYYY')
      if (formatDate.isValid()) {
        // if it is valid, then convert it to the standard date format string 'YYYY-MM-DD HH:mm:ss'
        alert[alrtKey] = moment.utc(alert[alrtKey], 'ddd MMM D HH:mm:ss YYYY').utc().format('YYYY-MM-DD HH:mm:ss')
      }
    })
    let acceleration = [0, -1, 0]
    if (isJSON(alert.data) && JSON.parse(alert.data).acceleration) acceleration = JSON.parse(alert.data).acceleration
    // Add update obj to set any fields that are named differently or unavailable in
    // alert object from action
    const ackTime = alert.ackTime ? moment.utc(alert.ackTime, 'YYYY-MM-DD HH:mm:ss') : false
    const clearDate = alert.alertTime3 ? moment.utc(alert.alertTime3, 'YYYY-MM-DD HH:mm:ss') : false
    const updateObj = {
      acceleration,
      id: alert.entryId,
      acknowledged: alert.ackBy.length > 0,
      event: alert.alertEvent,
      level: alert.alertLevel,
      time: alert.alertTime1 ? moment.utc(alert.alertTime1, 'YYYY-MM-DD HH:mm:ss') : false,
      ackTime,
      clearDate,
      createDate: moment.utc(alert.createDate, 'YYYY-MM-DD HH:mm:ss'),
      lastUpdate: moment.utc(alert.lastUpdate, 'YYYY-MM-DD HH:mm:ss'),
      source: alert.alertSource
    }
    // Destructure to remove unwanted fields
    delete alert.alertEvent
    delete alert.alertLevel
    delete alert.alertSource
    delete alert.data
    // create new object to add to state...
    const alertObj = Object.assign(alert, updateObj)
    // console.log('Set Alert: ', alert, remaining, alertObj)
    Vue.set(state.all, alert.entryId, alertObj)
  },
  /**
   * Sets counts field
   * @param {object} counts - alert counts object in form of id: {tot: ##, unack: ##}
   */
  SET_COUNTS (state, counts) {
    Vue.set(state, 'counts', counts)
  },
  /**
   * Sets ids field
   * @param {String} payload.cfgType - config type of the shipments
   * @param {object} payload.data - alert id object with key as alert type  and value is string of shipment state ids
   */
  SET_IDS (state, payload) {
    Vue.set(state.ids, payload.cfgType, payload.data)
  },
  /**
   * Sets last update field
   * @param {object} lastUpdate - date and time of last update.
   */
  SET_LAST_UPDATE (state, lastUpdate) {
    Vue.set(state, 'lastUpdate', lastUpdate)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
