<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <intelyt-modal
      :isActive="isActive"
      :title="title"
      v-on:close="close()"
    >
      <!-- SHOW MAIN MESSAGE CONTENTS -->
      <div style="min-height: 100px;" v-html="replaceCRLF"></div>
      <!-- SHOW EDITING/ACK CONTROLS -->
      <div v-show="mode === 'edit'">
        <div>
          <hr class="dark">
          <form v-bind:id="`ack-${obj.id}`" >
            <div class="content">
              <span class="field" v-show="showMsgBox">Note</span>
              <div class="field" v-show="showMsgBox">
                <ValidationProvider name="Message" :rules="{required: false, max: 150, regex: /^(\w|\s|-|\.|\?|\,)+$/}"  v-slot="{ errors }">
                  <textarea class="textarea" name="newMessage" placeholder="Enter note..." :class="{'input': true, 'is-danger': errors[0] }" v-model="newMessage"></textarea>
                  <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div>
                <label class= "checkbox" >
                  <input type="checkbox" name="ackCheckValue" value="T" checked disabled>Acknowledge
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- SHOW TIMES AND USERS -->
      <hr class="dark">
      <div>
        <tr>
          <td class="tbl-val">Created:</td>
          <td class="tbl-val">{{formatDate(obj.createDate)}} - {{obj.createdBy}}</td>
        </tr>
        <tr v-if="obj.ackTime">
          <td class="tbl-val">Acknowledged:</td>
          <td class="tbl-val">{{formatDate(obj.ackTime)}} - {{obj.ackBy}}</td>
        </tr>
        <tr v-if="obj.clearDate">
          <td class="tbl-val">Cleared:</td>
          <td class="tbl-val">{{formatDate(obj.clearDate)}}</td>
        </tr>
      </div>
      <template slot="footer">
      <a class="button is-primary" @click="sendUpdate()" v-show="showMsgBox || showAckCheck" :disabled="invalid">Submit</a>
        <a class="button" @click="showMsg()" v-show="!showMsgBox && !showAckCheck">Add Note</a>
        <a class="button" @click="showAck()" v-show="showAckBtn">Acknowledge</a>
        <a class="button" @click="resetFields()" v-show="showMsgBox">Reset</a>
        <a class="button" @click="close()">Close</a>
      </template>
    </intelyt-modal>
  </ValidationObserver>
</template>

<script>

import IntelytModal from '@/components/IntelytModal'
import moment from 'moment-timezone'

export default {
  components: {
    IntelytModal
  },
  computed: {
    replaceCRLF: function () {
      // const regexp = /<\/[A-Za-z]+>/
      const regex1 = /[\n]/g /* replace CR from SQL */
      const regex2 = /\\n/g /* replace literal string \n */
      return this.body.replace(regex1, '<br/>').replace(regex2, '<br/>')
    },
    sendAck: function () {
      /* Send acknowledgement if there is an upddate and one has not previously been
       * sent (i.e. ackBy is empty
       */
      return !this.obj.ackBy
    },
    showAckBtn: function () {
      /* Allow acknowledgement if (a) it is not already acknowldeged and
       * (b) the ack check is not active (i.e. neither ack button or message
       * button has not been pressed)
       */
      const user = this.$store.state.user
      // Check the user is admin or have permission to acknowledge the alerts
      const hasPermission = user.roles.includes('Company Administrator') || user.permissions.includes('acknowledgeAlerts')
      return hasPermission && !this.obj.ackBy && !this.showAckCheck
    }
  },
  data () {
    return {
      ackCheckValue: true,
      mode: 'view',
      isVisible: false,
      newMessage: '',
      showAckCheck: false,
      sendEmail: false,
      showEmailCheck: false,
      showMsgBox: false
    }
  },
  methods: {
    close () {
      this.resetFields()
      this.$emit('close')
    },
    formatDate (dt) {
      return dt && dt !== '' ? moment(dt).tz(this.$store.state.user.timezone).calendar() : ''
    },
    resetFields () {
      this.mode = 'view'
      this.newMessage = ''
      this.showAckCheck = false
      this.showEmailCheck = false
      this.showMsgBox = false
      this.$refs.observer.reset()
    },
    sendUpdate () {
      let updatePayload = {
        acknowledge: this.sendAck,
        existMessage: this.body,
        newMessage: this.newMessage,
        subject: this.obj.subject,
        sendEmail: this.sendEmail,
        entryId: this.obj.id
      }
      this.$refs.observer.validate().then(result => {
        if (result) {
          console.log('UPDATE PAYLOAD: ', updatePayload)
          this.$store.dispatch('alerts/updateAlert', updatePayload).then(response => {
            console.log('RESPONSE', response)
            this.close()
          }).catch(e => {
            console.log('ERROR: ', e)
            this.message = e
          })
        }
      })
    },
    showAck () {
      this.mode = 'edit'
      this.showAckCheck = true
      this.showEmailCheck = true
    },
    showMsg () {
      this.mode = 'edit'
      this.showAckCheck = !this.obj.ackBy
      this.showEmailCheck = true
      this.showMsgBox = true
    }
  },
  props: ['title', 'body', 'isActive', 'obj']
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  hr.dark {
    border-top: 1px solid #AAA;
  }

</style>
